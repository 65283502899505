import { ANTI_SPAM_TIME } from 'constants/autopilot';
import { validateSchedules } from './validateSchedules';

export default state => {
  const { form } = state;
  const nextForm = { ...form };

  let validate = {
    nameValid: false,
    sourceTypeValid: false,
    socialPageValid: false,
    albumNameValid: false,
    customTimeValid: false,
    formValid: false,
    error: []
  };

  /*Name validate*/
  validate.nameValid = form.name.trim().length > 0 && form.name.length < 150;
  if (!validate.nameValid) {
    const errorName = !form.name.trim().length
      ? 'Fill the autopilot name'
      : `Maximum name length are 150 now is ${form.name.length}`;
    validate.error.push(errorName);
  }

  /*SourceType validate*/
  validate.sourceTypeValid = form.sourceType && form.sourceType.length;
  if (!validate.sourceTypeValid) {
    validate.error.push('Fill of the Source Type');
  }

  /*Social page validate*/
  const fullSocialPage = form.socialPages.reduce(
    (full, item) => (full += item.socialObjects.length),
    0
  );
  validate.socialPageValid = fullSocialPage > 0 ? true : false;
  if (!validate.socialPageValid) {
    validate.error.push('Choose social page');
  }

  /*albumName validate*/
  if (form.typePost === 'PRODUCT_ALBUM') {
    // const isEmptyAlbumName = [
    //   ...form.templates.map((item, index) => (item.albumNameTemplate.length < 1 ? index + 1 : 0))
    // ].filter(item => item !== 0);
    // const isLongAlbumName = [
    //   ...form.templates.map((item, index) => (item.albumNameTemplate.length > 150 ? index + 1 : 0))
    // ].filter(item => item !== 0);

    // validate.albumNameValid = !form.socialPages.some(
    //   item => item.socialType === 'PINTEREST' && item.socialObjects.length > 0
    // )
    //   ? true
    //   : isEmptyAlbumName.length || isLongAlbumName.length
    //   ? false
    //   : true;
    validate.albumNameValid = true;
    if (!validate.albumNameValid) {
      // const errorAlbumName = isEmptyAlbumName.length ? `Fill of the album name (template(s) #${isEmptyAlbumName.join()})`
      //   : `Maximum name length are 150 (template(s) #${isLongAlbumName.join()})`;
      // validate.error.push(errorAlbumName);
    }
  } else {
    validate.albumNameValid = true;
  }

  /*customTime validate*/
  if (form.mode === 'CUSTOM_TIME') {
    validate.customTimeValid = !form.schedules.every(item => item.dayOfWeek.length < 1);

    if (!validate.customTimeValid) {
      validate.error.push('Fill of the days');
    } else {
      const { nextSchedules, isValid } = validateSchedules(form.schedules);

      nextForm.schedules = nextSchedules;

      if (!isValid) {
        validate.customTimeValid = false;
        validate.error.push(`The time difference must be at least ${ANTI_SPAM_TIME} minutes`);
      }
    }
  } else {
    validate.customTimeValid = true;
  }

  validate.formValid =
    validate.nameValid &&
    validate.sourceTypeValid &&
    validate.socialPageValid &&
    validate.albumNameValid &&
    validate.customTimeValid;

  return {
    ...state,
    form: {
      ...state.form,
      ...nextForm,
      validate: { ...validate }
    }
  };
};
