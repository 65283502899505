import validatePassword, { MAX_LENGTH_PASSWORD, MIN_LENGTH_PASSWORD } from 'util/validatePassword';

export function validate(state, fieldName, value) {
    const fieldValidationErrors = state.formErrors;
    let {
      currentPasswordValid,
      newPasswordValid,
      confirmPasswordValid,
      confirmPassword,
      newPassword
    } = state;

    switch (fieldName) {
        case 'currentPassword':
            if (value.length === 0) {
                currentPasswordValid = false;
                fieldValidationErrors.currentPassword = '';
            } else if (value.length < MIN_LENGTH_PASSWORD) {
                currentPasswordValid = false;
                fieldValidationErrors.currentPassword = 'Current password is invalid';
            } else {
                currentPasswordValid = true;
                fieldValidationErrors.currentPassword = '';
            }
            break;
        case 'newPassword':
            if (value.length === 0) {
                newPasswordValid = false;
                fieldValidationErrors.newPassword = '';
            } else if (!validatePassword(value)) {
                newPasswordValid = false;
                fieldValidationErrors.newPassword = value.length >= MAX_LENGTH_PASSWORD
                  ? `Password must contain maximum ${MAX_LENGTH_PASSWORD} characters`
                  : `Password must contain minimum ${MIN_LENGTH_PASSWORD} characters`;
            } else if (confirmPassword !== value) {
                newPasswordValid = false;
                fieldValidationErrors.newPassword = 'Confirm password is not equal to New password';
            } else {
                newPasswordValid = true;
                confirmPasswordValid = true;
                fieldValidationErrors.newPassword = '';
                fieldValidationErrors.confirmPassword = '';
            }
            break;
        case 'confirmPassword':
            if (value.length === 0) {
                confirmPasswordValid = false;
                fieldValidationErrors.confirmPassword = '';
            } else if (!validatePassword(value)) {
                confirmPasswordValid = false;
                fieldValidationErrors.confirmPassword = value.length >= MAX_LENGTH_PASSWORD
                  ? `Password must contain maximum ${MAX_LENGTH_PASSWORD} characters`
                  : `Password must contain minimum ${MIN_LENGTH_PASSWORD} characters`;
            } else if (newPassword !== value) {
                confirmPasswordValid = false;
                fieldValidationErrors.confirmPassword = 'Confirm password is not equal to New password';
            }  else {
                confirmPasswordValid = true;
                newPasswordValid = true;
                fieldValidationErrors.newPassword = '';
                fieldValidationErrors.confirmPassword = '';
            }
            break;
        default:
            break;
    }

    const validationData = {
        formErrors: fieldValidationErrors,
        currentPasswordValid,
        confirmPasswordValid,
        newPasswordValid
    };

    return {
        ...validationData,
        formValid: validateForm(validationData)
    };
}

function validateForm(validationData) {
    return validationData.currentPasswordValid && validationData.newPasswordValid && validationData.confirmPasswordValid;
}
