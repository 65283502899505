import {
  SCHEDULE_DEMO,
  SCHEDULE_DEMO_SUCCESS,
  SCHEDULE_DEMO_FAILURE,
  HANDLE_SCHEDULE_DEMO_FIELD,
  HANDLE_SCHEDULE_DEMO_SELECT,
  CLEAR_SCHEDULE_DEMO_ERROR_STATE,
} from '../../actions/scheduleDemo';
import { handleField, handleSelect } from './handle';

const initialState = {
  name: '',
  website: '',
  email: '',
  phone: '',
  platform: '',
  message: '',

  nameValid: false,
  emailValid: false,
  formValid: false,

  formErrors: {
    name: '',
    email: '',
  },
  loading: false,
  requestError: {
    show: false,
    text: '',
    reqStatus: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HANDLE_SCHEDULE_DEMO_FIELD:
      return handleField(state, action);
    case HANDLE_SCHEDULE_DEMO_SELECT:
      return handleSelect(state, action);

    case SCHEDULE_DEMO:
      return Object.assign({}, state, {
        loading: true,
        requestError: {
          show: false,
          text: '',
        },
      });

    case SCHEDULE_DEMO_SUCCESS:
      return Object.assign({}, initialState);

    case SCHEDULE_DEMO_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        requestError: {
          show: true,
          text: action.error,
          reqStatus: action.reqStatus,
        },
      });

    case CLEAR_SCHEDULE_DEMO_ERROR_STATE:
      return state.requestError.reqStatus === 400
        ? Object.assign({}, initialState)
        : Object.assign({}, state, {
            loading: false,
            requestError: {
              show: false,
              text: '',
            },
          });

    default:
      return state;
  }
};
