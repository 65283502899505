import React from 'react';
import { store } from 'store/';

// Hack for the issue https://github.com/elrumordelaluz/reactour/issues/177
let preventerRecursion = {
  stores: false,
  social: false,
};

export const tourSettingsSteps = (history) => {

  return [
    {
      selector: '[data-tour-id="storeList"]',
      content: <>
        <h3>Connect or Delete Stores </h3>
        <p>
          Connect, update, or delete your stores here.
        </p>
      </>,
      action: () => {
        if (preventerRecursion.stores) {
          preventerRecursion.stores = false;
          return;
        }
        preventerRecursion.stores = true;
        history.push('stores');
      }
    },
    {
      selector: '[data-tour-id="socialList"]',
      content: <>
        {store.getState().accountsList.payload.length ? (
          <p>
            Activate, deactivate, or even delete pages you need.
          </p>
        ) : (
          <>
            <h3>Connect Social Media</h3>
            <p>
              Add and delete your social media accounts here.
            </p>
          </>
        )}
      </>,
      action: () => {
        if (preventerRecursion.social) {
          preventerRecursion.social = false;
          return;
        }

        preventerRecursion.social = true;
        history.push('social-media');
      }
    },
    {
      selector: '',
      content: <>
        <h3>That’s about it!</h3>
        <p>
          Now you know how to connect  your
          Social Media pages and store.
        </p>
      </>,
    },
  ];
}
