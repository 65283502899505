import { videoAPI } from 'dal/api.js';

export const SET_VIDEO_LIST = 'SET_VIDEO_LIST';
export const SET_CHANNELS = 'SET_CHANNELS';
export const SET_FILTER_OPTIONS_FOR_VIDEOS = 'SET_FILTER_OPTIONS_FOR_VIDEOS';

export const SET_LOADED_ON_SCROLL_VIDEOS = 'SET_LOADED_ON_SCROLL_VIDEOS';
export const SET_LOAD_MORE_VIDEOS = 'SET_LOAD_MORE_VIDEOS';

export const SET_SEARCH_VIDEOS = 'SET_SEARCH_VIDEOS';

export const SET_VIDEO_LOADING = 'SET_VIDEO_LOADING';
export const SET_VIDEO_ERROR = 'SET_VIDEO_ERROR';
export const CLEAR_VIDEO_ERROR = 'CLEAR_VIDEO_ERROR';

export const ADD_VIDEO = 'ADD_VIDEO';
export const DELETE_VIDEO = 'DELETE_VIDEO';

export const DELETE_VIDEOS_ACCOUNT = 'DELETE_VIDEOS_ACCOUNT';

export const SET_SHOW_LIMIT_TO_VIDEOS = 'SET_SHOW_LIMIT_TO_VIDEOS';

export const TOGGLE_CHECK_FILTER_OPTION_FOR_VIDEOS = 'TOGGLE_CHECK_FILTER_OPTION_FOR_VIDEOS';
export const SET_CHECK_FILTER_OPTION_ALL = 'SET_CHECK_FILTER_OPTION_ALL';

export const REORDER_VIDEO = 'REORDER_VIDEO';

export const ADD_SYNC_VIDEO_ACCOUNT = 'ADD_SYNC_VIDEO_ACCOUNT';
export const DELETE_SYNC_VIDEO_ACCOUNT = 'DELETE_SYNC_VIDEO_ACCOUNT';


export function setError(error) {
  return {
    type: SET_VIDEO_ERROR,
    payload: null
  };
}

export function clearError() {
  return {
    type: CLEAR_VIDEO_ERROR
  };
}

export function reorderVideoAction(oldId, newId) {
  return {
    type: REORDER_VIDEO,
    oldId,
    newId
  };
}

export function changeSearchVideo(text) {
  return {
    type: SET_SEARCH_VIDEOS,
    payload: text
  };
}

export function addSyncAccount(id) {
  return {
    type: ADD_SYNC_VIDEO_ACCOUNT,
    id
  };
}

export function deleteSyncAccount(id) {
  return dispatch => {
    dispatch({ type: DELETE_SYNC_VIDEO_ACCOUNT,id });
    dispatch(getChannelsAction());
  }
}

export function deleteVideo(id) {
  return {
    type: DELETE_VIDEO,
    id
  };
}

export function deleteVideosAccount(accountId) {
  return {
    type: DELETE_VIDEOS_ACCOUNT,
    accountId
  };
}
export function addVideo(videoObject) {
  return {
    type: ADD_VIDEO,
    video: videoObject
  };
}

export function setShowLimitForVideos(limit) {
  return {
    type: SET_SHOW_LIMIT_TO_VIDEOS,
    limit
  };
}

export function getVideosAction(size, offset) {
  return (dispatch, getState) => {

    const state = getState();

    if (!size) {
      size = state.videoList.showLimit;
    }

    const query = state.videoList.searchQuery;
    const type = query && query.length ? 'search' : 'getList'

    dispatch({ type: SET_VIDEO_LOADING, loading: true });

    videoAPI[type](size, offset, query)
      .then(res => {

        if (offset) {
          dispatch({ type: SET_LOAD_MORE_VIDEOS, payload: res.data });
        } else {
          dispatch({ type: SET_VIDEO_LIST, payload: res.data });
        }

        return Promise.resolve(res);
      })
      .catch(err => {
        dispatch({ type: SET_VIDEO_LOADING, loading: false, error: err.message });

        return Promise.reject();
      });
  };
}


export function loadMoreVideos({showLimit, payload, searchQuery}) {
  return dispatch => {
    dispatch({ type: SET_LOADED_ON_SCROLL_VIDEOS, loading: true });

    const typeQuery = !searchQuery.length ? 'getList' : 'search';

    return videoAPI[typeQuery](showLimit, payload.length, searchQuery)
      .then(res => {
        dispatch({ type: SET_LOAD_MORE_VIDEOS, payload: res.data });

        return Promise.resolve(res);
      })
      .catch(err => {
        dispatch({ type: SET_LOADED_ON_SCROLL_VIDEOS, loading: false, error: err.message });

        return Promise.reject();
      });
  };
}

export function getPlayListsAction(size, offset) {
  return dispatch => {

    dispatch({ type: SET_VIDEO_LOADING, loading: true });

    videoAPI.getFilterOptions(size, offset)
      .then(res => res.data)
      .then(res => {

        dispatch({ type: SET_FILTER_OPTIONS_FOR_VIDEOS, payload: res });

      })
      .catch(err => {
        dispatch({ type: SET_VIDEO_LOADING, loading: false, error: err.message });
      });
  };
}

export function getChannelsAction(size, offset) {
  return dispatch => {

    dispatch({ type: SET_VIDEO_LOADING, loading: true });

    videoAPI.getChannels(size, offset)
      .then(res => {

        dispatch({ type: SET_CHANNELS, payload: res });

      })
      .catch(err => {
        dispatch({ type: SET_VIDEO_LOADING, loading: false, error: err.message });
      });
  };
}

export function toggleFilterOption(playlist, section='publications') {
  return dispatch => {

    dispatch({ type: SET_VIDEO_LOADING, loading: true });

    videoAPI.setFilterOptions(playlist, section)
      .then(res => {

        dispatch({
          type: TOGGLE_CHECK_FILTER_OPTION_FOR_VIDEOS,
          id: playlist.id,
          selected: !playlist.selectedInPublications
        });
        dispatch(getVideosAction());

      }).catch( err => {
      dispatch({ type: SET_VIDEO_LOADING, loading: false, error: err.message });
    })
  };
}

export function setFilterAll(select, section='publications') { //select - select/unselect
  return dispatch => {

    dispatch({ type: SET_VIDEO_LOADING, loading: true });

    videoAPI.setFilterAll(select, section)
      .then(res => {

        dispatch({
          type: SET_CHECK_FILTER_OPTION_ALL,
          select: select === 'select',
        });
        dispatch(getVideosAction());

      }).catch( err => {
      dispatch({ type: SET_VIDEO_LOADING, loading: false, error: err.message });
    })
  };
}

