import isEqual from 'date-fns/isEqual';
import format from 'date-fns/format';
import set from 'date-fns/set';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export function spyFeedSet(state, action) {
  const hasMore = (state.feedPagination.page + 1) * state.feedPagination.size < action.payload.feedTotalCount; // prettier-ignore
  const nextState = {
    ...state,
    hasMore,
    isLoading: false,
    feedPagination: {
      ...state.feedPagination,
      page: hasMore ? state.feedPagination.page + 1 : state.feedPagination.page
    }
  };
  const feed = nextState.feed;

  // Push new posts to feed
  action.payload.feed.forEach(post => {
    const postCreatedAt = post.creationDate;
    const createdToday = isEqual(
      set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }),
      set(new Date(postCreatedAt), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    );
    const createdYesterday = differenceInCalendarDays(new Date(), new Date(postCreatedAt)) === 1;

    if (createdToday) {
      feed.today = feed.today ? [...feed.today, post] : [post];
    } else if (createdYesterday) {
      feed.yesterday = feed.yesterday ? [...feed.yesterday, post] : [post];
    } else {
      const formatedDate = format(new Date(postCreatedAt), 'd LLLL y');

      feed[formatedDate] = feed[formatedDate] ? [...feed[formatedDate], post] : [post];
    }
  });

  return nextState;
}
