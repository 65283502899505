import sortBy from 'lodash/sortBy';

export default function addProducts(state, action) {
  let copyPayload = state.payload.slice();
  const isNewProduct = !copyPayload.some(product => product.id === action.payload.id);

  if (isNewProduct) copyPayload.push(action.payload);

  return {
    ...state,
    loading: false,
    error: null,
    payload: sortBy(copyPayload, ['id'])
  };
}