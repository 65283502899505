import { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import differenceInDays from 'date-fns/differenceInDays';
import parseISO from 'date-fns/parseISO';
import { showModal, hideModal, modalTypes } from 'store/actions/modal/modal';
import { loadState, saveState } from 'util/localStorage';
import { useActions } from 'hooks';
import { FREE_PLAN_EXTINCTION_DATE } from 'constants/freePlanExtinctionDate';

export function useAppModalsCronJob() {
  const actions = useActions({ showModal, hideModal });
  const profile = useSelector(state => state.profile);

  useEffect(() => {
    const { id, isLoading, availablePromoTariff, freeCompanyWithUpgradeOption } = profile || {};

    if (isLoading) return;

    const UFUPSU_KEY = `${id}_FREE_PLAN_EXTINCTION`;
    const AVAILABLE_PROMO_KEY = `${id}_AVAILABLE_PROMO_SHOWED_AT`;

    // Show frer plan extinction modal for users with FREE tariff
    const now = new Date();
    if (freeCompanyWithUpgradeOption && moment(now).isBefore(FREE_PLAN_EXTINCTION_DATE)
      && (!loadState(UFUPSU_KEY) || differenceInDays(now, parseISO(loadState(UFUPSU_KEY))) > 0)) {
      const onClose = saveState(UFUPSU_KEY, now);

      actions.showModal(modalTypes.FREE_PLAN_EXTINCTION, {
        onClose,
        actionLabel: 'Get Starter'
      });
    }

    // Show promo modal
    if (
      availablePromoTariff &&
      (!loadState(AVAILABLE_PROMO_KEY) ||
        differenceInDays(now, parseISO(loadState(AVAILABLE_PROMO_KEY))) > 0)
    ) {
      actions.showModal(modalTypes.PROMO, {
        onClose: saveState(AVAILABLE_PROMO_KEY, now)
      });
    }
  }, [profile]);
}
