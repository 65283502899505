export function togglePageInAnalyticsSuccess(state, action) {
  const newPayload = state.activePayload.map(page => {
    if (page.id === action.id) {
      return {
        ...page,
        selectedInAnalytics: !action.nowSelectedStatus,
      };
    } else {
      return page;
    }
  });

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null,
  });
}

export function activateAllPagesInAnalyticsSuccess(state, action) {
  const newPayload = state.activePayload.map(page => {
    if (page.socialNetworkType) {
      return {
        ...page,
        selectedInAnalytics: true,
      };
    } else {
      return page;
    }
  });

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null,
    togglePagesLoading: false,
  });
}

export function deactivateAllPagesInAnalyticsSuccess(state, action) {
  const newPayload = state.activePayload.map(page => {
    if (page.socialNetworkType) {
      return {
        ...page,
        selectedInAnalytics: false,
      };
    } else {
      return page;
    }
  });

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null,
    togglePagesLoading: false,
  });
}
