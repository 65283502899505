import { SET_ACTIVE_SOURCE_TAB, CHANGE_IS_SHORT_LINK } from 'store/actions/settings/settings';
import { DEFAULT_SELECT_SOURCE } from '../../../constants/sources';

const initialState = {
  sourceTab: DEFAULT_SELECT_SOURCE,
  isShortLink: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_SOURCE_TAB:
      return {
        ...state,
        sourceTab: action.sourceName
      };

    case CHANGE_IS_SHORT_LINK:
      return {
        ...state,
        isShortLink: action.isShortLink
      };

    default:
      return state;
  }
}
