export default function chooseSocialType(state, action) {
  const { socialType } = action;

  return {
    ...state,
    socialType,
    socialPage: {},
    dataForSocialPagesSelect: {
      placeholder: 'Social pages',
      value: {},
      defaultOptions: []
    }
  };
}