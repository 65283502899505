import { useQuery } from 'react-query';

const cache = {};

export function useQueryWithCache(getChacheKey, queryFn, options, staticKey) {

  const query = useQuery(
    staticKey ? staticKey : getChacheKey(),
    context => {
      const key = getChacheKey();

      if (cache[key]) return cache[key];

      return queryFn(context).then(res => (cache[key] = res));
    },
    options
  );

  return query;
}
