export function setUrlShortenerAccount(state, action) {
  const nextState = { ...state };
  const newAccount = action.payload.account;
  const foundInd = nextState.payload.findIndex(account => account.id === newAccount.id);

  if (foundInd !== undefined) {
    nextState.payload.splice(foundInd, 1, newAccount);
  } else {
    nextState.payload = [...nextState.payload, newAccount];
  }

  return nextState;
}
