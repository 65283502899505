import axios from 'axios';
import { store } from '../../index';

//Constants
import { STORE_TYPES } from 'constants/stores';
import { VIDEO_ACCOUNT_TYPES } from 'constants/accounts';

//Utils
import { getSourceBrandFromItemAndType } from 'util/prepareOptionsForSourceTypeSelect';
import { routerWS } from 'util/routerWS';

// Selectors

import {
  getMoadlStoreByType
} from 'util/selectors/productsSelector';


//Action type
export const PREPARE_POST_MODAL_EDIT = 'PREPARE_POST_MODAL_EDIT';
export const PREPARE_POST_MODAL_NEW = 'PREPARE_POST_MODAL_NEW';
export const POST_SET_SOCIAL_PAGES = 'POST_SET_SOCIAL_PAGES';
export const CHOOSE_SOCIAL_TYPE = 'CHOOSE_SOCIAL_TYPE';
export const CHOOSE_SOCIAL_PAGE = 'CHOOSE_SOCIAL_PAGE';
export const CHOOSE_SOURCE_TYPE = 'CHOOSE_SOURCE_TYPE';
export const CHOOSE_SOURCE_ITEM = 'CHOOSE_SOURCE_ITEM';
export const SET_EXTRA_PUBLISHING_PARAMS = 'SET_EXTRA_PUBLISHING_PARAMS';
export const CHANGE_DESC = 'CHANGE_DESC';
export const CHANGE_DESC_HTML = 'CHANGE_DESC_HTML';
export const TEXT_FIELD = 'TEXT_FIELD';
export const PUT_IMAGES = 'PUT_IMAGES';
export const UPLOAD_IMAGES = 'UPLOAD_IMAGES';
export const REORDER_IMAGES = 'REORDER_IMAGES';
export const REMOVE_IMAGES_FROM_SOURCE_ITEM = 'REMOVE_IMAGES_FROM_SOURCE_ITEM';
export const CHOOSE_DATE = 'CHOOSE_DATE';
export const CHANGE_LINK = 'CHANGE_LINK';
export const LOADING = 'LOADING';
export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const RESET_DATA = 'RESET_DATA';
export const TOGGLE_SHORT_CONTENT_LINKS = 'TOGGLE_SHORT_CONTENT_LINKS';
export const MEDIA_TOGGLE_SHOW_LOADER = 'MEDIA_TOGGLE_SHOW_LOADER';
export const RESET_SHORT_CONTENT_LINKS = 'RESET_SHORT_CONTENT_LINKS';
export const SELECT_SHORTENER_DOMAIN_POSTMODAL = 'SELECT_SHORTENER_DOMAIN_POSTMODAL';
export const UPLOAD_POST_MEDIA = 'UPLOAD_POST_MEDIA';
export const UPDATE_POST_MEDIA = 'UPDATE_POST_MEDIA';
export const UPDATE_POST_MEDIAS = 'UPDATE_POST_MEDIAS';
export const DELETE_POST_MEDIA = 'DELETE_POST_MEDIA';
export const SET_POST_MEDIAS = 'SET_POST_MEDIAS';

export function preparePostModalNewAction(
  sourceItem = {},
  sourceType = 'NONE',
  dateForPost,
  processedDataForModal = {}
) {
  return (dispatch, getState) => {
    const { storesList, accountsList, settings } = getState();

    //set source brand for new post
    let sourceBrand = sourceItem.id
      ? getSourceBrandFromItemAndType(sourceItem, sourceType)
      : 'NONE'; //MAGENTO, SHOPIFY, WOOCOMMERCE

    // Set default source, get SourceBrand
    if (sourceType === 'NONE') {
      if (STORE_TYPES.includes(settings.sourceTab) &&
        storesList.payload.some(
          store => store.type === settings.sourceTab && store.status === 'ACTIVE'
        )
      ) {
        dispatch(chooseSourceTypeAction('PRODUCT', settings.sourceTab));
        sourceBrand = settings.sourceTab;
      } else if (settings.sourceTab === 'VIDEO' &&
        accountsList.payload.some(account => VIDEO_ACCOUNT_TYPES.includes(account.type))
      ) {
        dispatch(chooseSourceTypeAction('VIDEO', settings.sourceTab));
        sourceBrand = settings.sourceTab;
      } else if (['MEDIA_IMAGES', 'MEDIA_VIDEOS'].includes(settings.sourceTab)) {
        // TODO
        dispatch(chooseSourceTypeAction(settings.sourceTab, settings.sourceTab));
        sourceBrand = settings.sourceTab;
      }
    }

    dispatch({
      type: PREPARE_POST_MODAL_NEW,
      typeModal: 'NEW_POST',
      storesList: storesList.payload,
      accountsList,
      sourceItem,
      sourceType,
      sourceBrand,
      dateForPost,
      processedDataForModal
    });
  };
}

export function preparePostModalEditAction(data) {
  const state = store.getState();
  const socialTypes = state.accountsList.socialTypes;
  const accountPayload = state.accountsList.payload;
  const storesList = state.storesList.payload;

  return {
    type: PREPARE_POST_MODAL_EDIT,
    data,
    typeModal: 'EDIT_POST',
    socialTypes,
    accountPayload,
    storesList
  };
}

export function setSocialPages(socialPages) {
  return {
    type: POST_SET_SOCIAL_PAGES,
    socialPages
  };
}

export function chooseSocialTypeAction(socialType) {
  return {
    type: CHOOSE_SOCIAL_TYPE,
    socialType
  };
}

export function chooseSocialPageAction(option) {
  return {
    type: CHOOSE_SOCIAL_PAGE,
    option
  };
}

export function chooseSourceTypeAction(value, sourceBrand) {
  const state = store.getState();

  return dispatch => {
    if (value !== 'NONE') {
      let categoryApi;
      let extraParams = '';

      if (value === 'PRODUCT') {
        categoryApi = 'productsV2';
        let store = state.storesList.payload.filter(store => store.type === sourceBrand)[0] || null;
        extraParams = store ? `&shopId=${store.id}` : '';
      } else if (value === 'VIDEO') {
        categoryApi = 'videos';
      } else if (value === 'MEDIA_IMAGES') {
        categoryApi = 'content/all';
        extraParams = '&type=IMAGE';
      } else if (value === 'MEDIA_VIDEOS') {
        categoryApi = 'content/all';
        extraParams = '&type=VIDEO';
      }
      axios
        .get(`/api/${categoryApi}?size=20&pageNumber=0&offset=0${extraParams}`)
        .then(res => {
          dispatch({
            type: CHOOSE_SOURCE_TYPE,
            sourceType: value,
            sourceBrand: sourceBrand,
            sourceItems: res.data
          });
        })
        .catch(error => console.error(error));
    } else {
      dispatch({
        type: CHOOSE_SOURCE_TYPE,
        sourceType: 'NONE',
        sourceBrand: '',
        sourceItems: []
      });
    }
  };
}

export function chooseSourceItemAction(option, withImg = false) {
  return {
    type: CHOOSE_SOURCE_ITEM,
    option,
    withImg
  };
}

export function setExtraPublishingParamsAction(socialType, param, option) {
  return {
    type: SET_EXTRA_PUBLISHING_PARAMS,
    socialType,
    param,
    option
  };
}

export function changeDescAction(value, htmlValue) {
  return {
    type: CHANGE_DESC,
    value,
    htmlValue
  };
}

export function textFieldAction(name, value) {
  return {
    type: TEXT_FIELD,
    name,
    value
  };
}

export function putImagesAction(images) {
  return {
    type: PUT_IMAGES,
    images
  };
}

export function uploadPostMediaAction(file, mediaKey) {
  return async dispatch => {
    const formData = new FormData();

    formData.set('file', file);

    try {
      const res = await axios.post('/api/content/upload', formData);
      const uploadedFile = res.data;

      dispatch({
        type: UPLOAD_POST_MEDIA,
        payload: { file: uploadedFile, mediaKey }
      });

      routerWS(`/topic/file/${uploadedFile.id}/uploading`, data => {
        const { totalBytes, bytesTransferred, content } = data;
        const nextProps = {
          uploadingFilePercent: data.status === 'UPLOADED' ? undefined : ((bytesTransferred * 100) / totalBytes).toFixed() // prettier-ignore
        };

        if (mediaKey === 'images' && content) {
          nextProps.status = content.status;
          nextProps.url = content.externalUrl;
          nextProps.small = content.thumbnails.small;
          nextProps.medium = content.thumbnails.medium;
          nextProps.large = content.thumbnails.large;
          nextProps.mobileLarge = content.thumbnails.mobileLarge;
          nextProps.largeTile = content.thumbnails.largeTile;
        }

        if (mediaKey === 'videos' && content) {
          nextProps.status = content.status;
          nextProps.url = content.externalUrl;
          nextProps.thumbnailUrl = content?.thumbnails?.large?.url || null;
        }

        dispatch(updatePostMediaAction(uploadedFile.id, mediaKey, nextProps));
      });
    } catch (error) {
      console.error(error);
      dispatch(showErrorMessageAction(error.response?.data?.detail || error.toString()));
    }
  };
}

export function updatePostMediaAction(id, mediaKey, newProps) {
  return {
    type: UPDATE_POST_MEDIA,
    payload: { id, mediaKey, newProps }
  };
}

export function updatePostMediasAction(mediaKey, newProps) {
  return {
    type: UPDATE_POST_MEDIAS,
    payload: { mediaKey, newProps }
  };
}

export function setPostMediasAction(mediaKey, files) {
  return {
    type: SET_POST_MEDIAS,
    payload: { mediaKey, files }
  };
}

export function deletePostMediaAction({ id, source }, mediaKey) {
  return async dispatch => {
    dispatch(updatePostMediaAction(id, mediaKey, { isDeleting: true }));

    try {
      if (source === 'CONTENT_CLOUD') {
        await axios.delete(`/api/content/${id}`);
      }

      dispatch({
        type: DELETE_POST_MEDIA,
        payload: { id, mediaKey }
      });
    } catch (error) {
      console.error(error);

      dispatch(updatePostMediaAction(id, mediaKey, { isDeleting: false }));
      dispatch(showErrorMessageAction(error.response?.data?.message || error.toString()));
    }
  };
}

export function uploadImagesAction(images) {
  return {
    type: UPLOAD_IMAGES,
    images
  };
}

export function reorderImagesAction(sourceIndex, destinationIndex) {
  return {
    type: REORDER_IMAGES,
    sourceIndex,
    destinationIndex
  };
}

export function removeImagesFromSourceItemAction(images) {
  return {
    type: REMOVE_IMAGES_FROM_SOURCE_ITEM,
    images
  };
}

export function chooseDateAction(chooseDate) {
  return {
    type: CHOOSE_DATE,
    chooseDate
  };
}

export function changeLinkAction(val) {
  return {
    type: CHANGE_LINK,
    val
  };
}

export function loadingAction(boolValue) {
  return {
    type: LOADING,
    boolValue
  };
}

export function showErrorMessageAction(errorMessage) {
  return {
    type: SHOW_ERROR,
    errorMessage
  };
}

export function hideErrorMessageAction() {
  return {
    type: HIDE_ERROR
  };
}

export function resetDataPostModalAction() {
  return {
    type: RESET_DATA
  };
}

export function toggleShortContentLinks() {
  return {
    type: TOGGLE_SHORT_CONTENT_LINKS
  };
}

export function selectUrlShortenerDomainAction(id) {
  return {
    type: SELECT_SHORTENER_DOMAIN_POSTMODAL,
    payload: { id }
  };
}
