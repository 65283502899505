import { createSelector } from 'reselect';

const getStores = state => state.storesList.payload;
const getSourceTabName = state => state.settings.sourceTab;

export const getSourcesTabs = createSelector(
  [getStores],
  storesList => {
    let activeStoresTypeList = Array.isArray(storesList)
      ? [
          ...new Set(
            storesList
              .filter(
                store =>
                  store.status === 'ACTIVE' ||
                  store.status === 'LOCKED' ||
                  store.status === 'PENDING'
              )
              .map(store => store.type)
          )
        ]
      : [];

    if (!activeStoresTypeList.length) {
      activeStoresTypeList = ['DEFAULT_PRODUCTS']; //default tab with connect-button
    }

    return [
      ...activeStoresTypeList.map(storeType => ({
        name: storeType,
        type: 'PRODUCTS',
        generalType: 'PRODUCT'
      })),
      { name: 'VIDEO', type: 'VIDEOS', generalType: 'VIDEO' },
      { name: 'MEDIA_VIDEOS', type: 'MEDIA_VIDEOS', generalType: 'MEDIA_VIDEOS' },
      { name: 'MEDIA_IMAGES', type: 'MEDIA_IMAGES', generalType: 'MEDIA_IMAGES' }
    ];
  }
);

export const getActiveSourceTab = createSelector(
  [getSourcesTabs, getSourceTabName],
  (sources, sourceTabName) => {
    const findedSource = sources.find(source => source.name === sourceTabName);
    return findedSource ? findedSource : null;
  }
);
