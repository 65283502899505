export default function toggleCheckFilterOption(state, action) {
  const newFilterOptons = state.filterOptions.map(option => {
    if (option.id === action.id) {
      return {
        ...option,
        selectedInPublications: !action.selectedInPublications
      };
    }
    return option;
  });

  return { ...state, filterOptions: newFilterOptons };
}