export default function setExtraPublishingParams(state, action) {
    const { socialType, param, option } = action;

    if (!socialType || !param || !option) {
        return {
            ...state
        }
    }
    const map = {
        [param]: option
    }

    return {
        ...state,
        extraPublishingParams: {
            ...state.extraPublishingParams,
            [socialType]: map
        }
    }
}
