export function toggleStatusUrlShortener(state, action, type) {
  const {
    payload: { accountId, domainId, status }
  } = action;
  const nextPayload = [...state.payload];
  const accountInd = nextPayload.findIndex(account => account.id === accountId);

  if (type === 'account') {
    nextPayload[accountInd].status = status;
    nextPayload[accountInd].domains.forEach((_, ind) => {
      nextPayload[accountInd].domains[ind].status = status;
    });
  }

  if (type === 'domain') {
    const domainInd = nextPayload[accountInd].domains.findIndex(domain => domain.id === domainId); // prettier-ignore

    nextPayload[accountInd].domains[domainInd].status = status;
  }

  return { ...state, payload: nextPayload };
}
