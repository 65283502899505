import { SET_COUNTER_DATA, SET_COUNTER_ERROR, CLEAR_COUNTER_ERROR } from 'store/actions/counters';

const initialState = {
  publishedPosts: {
    error: undefined,
    data: undefined
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTER_DATA:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          data: {
            ...state[action.payload.key].data,
            ...action.payload.data
          }
        }
      };

    case SET_COUNTER_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          error: action.payload.error
        }
      };

    case CLEAR_COUNTER_ERROR:
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          error: undefined
        }
      };

    default:
      return state;
  }
};
