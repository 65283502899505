export const typePostFromApi = (type) => {
  switch (type) {

    case 'PRODUCT_LINK':
    case 'PRODUCT_ALBUM':
      return type;

    case 'VIDEO':
      return 'PRODUCT_LINK';

    default:
      return 'PRODUCT_ALBUM';

  }
};

export const typePostToApi = (typePost, sourceType) => {
  switch (sourceType) {

    case 'PRODUCT':
      return typePost;

    case 'VIDEO':
      return 'VIDEO';

    case 'MEDIA_IMAGES':
      return 'PHOTO';

    case 'MEDIA_VIDEOS':
      return 'VIDEO';

    default:
      return typePost;

  }
};
