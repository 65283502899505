import axios from 'axios';

export const SET_COUNTER_DATA = 'SET_COUNTER_DATA';
export const SET_COUNTER_ERROR = 'SET_COUNTER_ERROR';
export const CLEAR_COUNTER_ERROR = 'CLEAR_COUNTER_ERROR';

export function getPublishedPostsCounterAction() {
  return async dispatch => {
    try {
      const res = await axios.get('/api/posts/published/counter');

      dispatch(setCounterDataAction('publishedPosts', res.data));
    } catch (error) {
      dispatch(
        setCounterErrorAction('publishedPosts', error.response?.data?.message || error.message)
      );
    }
  };
}

export function setCounterDataAction(key, data) {
  return {
    type: SET_COUNTER_DATA,
    payload: { key, data }
  };
}

export function setCounterErrorAction(key, error) {
  return {
    type: SET_COUNTER_ERROR,
    payload: { key, error }
  };
}

export function clearCounterErrorAction(key) {
  return {
    type: CLEAR_COUNTER_ERROR,
    payload: { key }
  };
}
