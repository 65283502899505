import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './button.css';
import Loader from 'components/common/loader/loader';

export default class Button extends Component {
  static propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
    title: PropTypes.string
  };

  render() {
    const data = this._generateButtonProps(this.props);

    return this.getButton(data);
  }

  getButton = props => {
    let btnClass = classNames({
      btn: true,
      'btn--main-small': props.mainSmall,
      'btn--small': props.small,
      'btn--wide': props.wide,
      'btn--hover-accent': props.hoverAccent,

      'btn--success': props.success,
      'btn--danger': props.danger,
      'btn--disabled': props.disabled,
      'btn--transparent': props.transparent,
      'btn--gray': props.gray,
      'btn--transparentBorder': props.transparentBorder,
      'btn--transparentWithoutBorder': props.transparentWithoutBorder,
      'btn--black': props.black,
      'btn--black-border': props.blackBorder,
      'btn--black-transparent': props.blackTransparent,
      'btn--blackWithWhiteBorder': props.blackWithWhiteBorder,
      'btn--yellow': props.yellow,
      'btn--accent': props.accent,
      'btn--lightGreen': props.lightGreen,
      'btn--loading': props.loading,
      'btn--blue': props.blue,
      'btn--darkBlue': props.darkBlue,
      'btn--add': props.add,

      'btn--social': props.social,
      'btn--crazy': props.crazy,
      'btn--social-youtube': props.youtube,
      'btn--social-pinterest': props.pinterest,
      'btn--social-facebook': props.facebook,
      'btn--social-facebook-ig': props.fbAndIg,
      'btn--social-twitter': props.twitter,
      'btn--social-linkedin': props.linkedin,
      'btn--social-instagram': props.instagram,

      'btn--store': props.store,
      'btn--store-shopify': props.shopify,
      'btn--store-magento': props.magento,
      'btn--store-woocommerce': props.woocommerce,
      'btn--store-bigcommerce': props.bigcommerce,
      'btn--store-wix': props.wix,
      'btn--store-wish': props.wish,
      'btn--whiteFill': props.whiteFill,

      'btn--bitly': props.bitly,

      'btn--locked': props.locked,
      'btn--lock': props.lock,
      'btn--red-border': props.redBorder,
      'btn--narrow': props.narrow,

      [props.className]: !!props.className
    });
    const dataAttributes = Object.keys(this.props).reduce((acc, item) => {
      if (/^data-/.test(item)) {
        acc[item] = this.props[item];
      }
      return acc;
    }, {});

    return (
      <button
        {...dataAttributes}
        className={btnClass}
        onClick={props.onClick}
        type={props.type}
        disabled={props.disabled || props.loading}
        data-social={this._setDataSocial(props)}
        title={props.title}
      >
        {props.social || props.store || props.add ? <i /> : null}
        {props.loading ? (
          <Loader
            img={{ width: 35, height: 35 }}
            wrapper={{ height: 55, opacity: 0.7 }}
            className="onollo__loader--btn"
          />
        ) : null}
        <span>{props.label}</span>
      </button>
    );
  };

  _setDataSocial = props => {
    if (!props.social) return null;

    if (props.facebook) {
      return 'FACEBOOK';
    }
    if (props.twitter) {
      return 'TWITTER';
    }
    if (props.linkedin) {
      return 'LINKEDIN';
    }
    if (props.instagram) {
      return 'INSTAGRAM';
    }
    if (props.pinterest) {
      return 'PINTEREST';
    }
  };

  _generateButtonProps = data => {
    return {
      label: data.label ? data.label : '',
      onClick: data.onClick ? data.onClick : null,
      type: data.type ? data.type : 'button',
      title: data.title ? data.title : '',
      social: !!data.social,
      add: !!data.add,
      store: !!data.store,
      theme: data.theme ? data.theme : null,
      className: data.className ? data.className : '',
      youtube: !!data.youtube,
      pinterest: !!data.pinterest,
      facebook: !!data.facebook,
      fbAndIg: !!data.fbAndIg,
      twitter: !!data.twitter,
      linkedin: !!data.linkedin,
      instagram: !!data.instagram,
      shopify: !!data.shopify,
      magento: !!data.magento,
      woocommerce: !!data.woocommerce,
      bigcommerce: !!data.bigcommerce,
      wix: !!data.wix,
      wish: !!data.wish,
      success: !!data.success,
      danger: !!data.danger,
      disabled: !!data.disabled,
      transparent: !!data.transparent,
      gray: !!data.gray,
      black: !!data.black,
      blackBorder: !!data.blackBorder,
      blackTransparent: !!data.blackTransparent,
      blackWithWhiteBorder: !!data.blackWithWhiteBorder,
      lightGreen: !!data.lightGreen,
      transparentBorder: !!data.transparentBorder,
      transparentWithoutBorder: !!data.transparentWithoutBorder,
      accent: !!data.accent,
      crazy: !!data.crazy,
      loading: !!data.loading,
      mainSmall: !!data.mainSmall,
      small: !!data.small,
      wide: !!data.wide,
      hoverAccent: !!data.hoverAccent,
      whiteFill: !!data.whiteFill,
      bitly: !!data.bitly,
      yellow: !!data.yellow,
      locked: !!data.locked,
      lock: !!data.lock,
      blue: !!data.blue,
      darkBlue: !!data.darkBlue,
      redBorder: !!data.redBorder,
      narrow: !!data.narrow
    };
  };
}
