import {
  SPY_FEED_GET_PENDING,
  SPY_FEED_GET_SUCCESS,
  SPY_FEED_GET_FAIL,
  SPY_FEED_SET_SOCIAL_TYPE,
  SPY_FEED_SET_COMPETITOR,
  SPY_FEED_RESET_ERROR,
  SPY_FEED_RESET_STATE
} from 'store/actions/spyFeed/spyFeed';
import { spyFeedSet } from './helpers/spyFeedSet';
import { DEFAULT_COMPETITOR } from 'pages/spyFeed/components/FilterByCompetitor/FilterByCompetitor';
import isMobile from 'util/isMobile';

const feedPagination = {
  page: 0,
  size: isMobile.any() ? 10 : 30
};

const initialState = {
  feed: {},
  feedPagination,
  hasMore: true,
  socialType: 'FACEBOOK',
  competitor: DEFAULT_COMPETITOR,
  isLoading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SPY_FEED_GET_PENDING:
      return { ...state, isLoading: true };

    case SPY_FEED_GET_SUCCESS:
      return spyFeedSet(state, action);

    case SPY_FEED_GET_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case SPY_FEED_SET_SOCIAL_TYPE:
      return { ...state, feed: [], feedPagination, socialType: action.payload };

    case SPY_FEED_SET_COMPETITOR:
      return { ...state, feed: [], feedPagination, competitor: action.payload };

    case SPY_FEED_RESET_ERROR:
      return { ...state, error: null };

    case SPY_FEED_RESET_STATE:
      return { ...initialState };

    default:
      return state;
  }
};
