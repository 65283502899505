import React from 'react';
import './withErrorHandling.css';

export default class WithErrorHandling extends React.Component {
  render() {
    const { showError, errorMessage, children, clearErrorHandler } = this.props;

    return (
      <section>
        {showError && (
          <div className="error-message">
            <pre>
              {typeof errorMessage === 'string' ?  errorMessage : 'Error'}
            </pre>
            {clearErrorHandler ? (
              <button className="error-message__clear-button" onClick={clearErrorHandler} />
            ) : null}
          </div>
        )}
        {children}
      </section>
    );
  }
}
