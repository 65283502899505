import reorder from 'util/reorder';

export function putToCalendar(state, action) {
  const { destination, post, index } = action;
  let oldDay = state.payload.filter(day => day.day === destination)[0];
  oldDay.postsList.splice(oldDay.postsList.length, 0, {...post, startRemove: false});

  const newPayload = state.payload.map(day => {
    if (day.day === destination) {
      return {
        ...day,
        postsList: reorder(oldDay.postsList, oldDay.postsList.length - 1, index),
      };
    } else {
      return day;
    }
  });

  return Object.assign({}, state, {
    loading: action.loading,
    error: action.error,
    payload: newPayload,
  });
}

function _setPostToDay(dayPostCalendar, post) {
  return dayPostCalendar.map(postDay => postDay.id === post.id ? post : postDay);
}

export function setPostToDay(payload, day, post) {
  return payload.map(dayCalendar => dayCalendar.day === day ? _setPostToDay(dayCalendar.postsList ? dayCalendar.postsList: [], post) : dayCalendar);
}
