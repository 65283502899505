export default (state, initialState) => {

  const { form } = state;

  let validate = {
    ...initialState.form.validate,
    error: []
  };

  /*Name validate*/
  validate.nameValid = (form.name.trim().length > 0 && form.name.length < 150) ? true : false;
  if (!validate.nameValid) {
    const errorName = !form.name.trim().length ? 'Fill the template name' : `Maximum name length are 150 now is ${form.name.length}`;
    validate.error.push(errorName);
  }

  /*SourceType validate*/
  validate.sourceTypeValid = form.sourceType && form.sourceType.length;
  if (!validate.sourceTypeValid) {
    validate.error.push('Fill of the Source Type');
  }

  /*albumName validate*/
  if (form.typePost === 'PRODUCT_ALBUM') {
    const albumName = form.template.albumNameTemplate;

    validate.albumNameValid = !form.socialPages.some(item => item.socialType === 'PINTEREST') ? true : (albumName.trim().length > 0 && albumName.length < 150) ? true : false;

    if (!validate.albumNameValid) {
      const errorAlbumName = !albumName.trim().length ? 'Fill of the title' : `Maximum title length are 150, now is ${albumName.length}`;
      validate.error.push(errorAlbumName);
    }
  } else {
    validate.albumNameValid = true;
  }

  /*Form validation*/
  validate.formValid = validate.nameValid
    && validate.sourceTypeValid
    && validate.albumNameValid;

  return {
    ...state,
    form: {
      ...state.form,
      validate
    }
  };
}
