import React, { Component } from 'react';
import classNames from 'classnames';
import loaderImg from 'assets/images/onollo-loader.svg';
import './loader.css';

export default class Loader extends Component {
  getWrapperHeight = () => {
    const { wrapper } = this.props;

    if (wrapper && wrapper.isAllScreenHeight) {
      return '100vh';
    } else if (wrapper && wrapper.height) {
      return wrapper.height;
    } else {
      return '100%';
    }
  };

  render() {
    const { wrapper, img, className } = this.props;

    const classes = classNames({
      onollo__loader: true,
      [className]: !!className
    });

    return (
      <div
        className={classes}
        style={{
          height: this.getWrapperHeight(),
          opacity: wrapper && wrapper.opacity ? wrapper.opacity : 1
        }}
      >
        <img src={loaderImg} alt="loader" width={img.width} height={img.height} />
      </div>
    );
  }
}

Loader.defaultProps = {
  isAllScreenHeight: false
};
