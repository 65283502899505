export default (state, action) => {
  const value = _getValue(action, state.socialType);

  return {
    ...state,
    [action.name]: value
  };
}


function _getValue({ name, value }, socialType) {
  const valueLength = value.trim().length;

  switch(name) {
    case 'albumName':
      if (valueLength > 65) {
        return value.trim().slice(0, 65);
      }
      break;
    
    default: break;
  }

  return value;
}