import axios from 'axios';
import utcToLocal from 'util/utcToLocal';
import { DATE_TIME_FORMAT } from 'constants/date';
import { CALENDAR_DAY_POSTS_LIMIT } from 'constants/limits';
import { datesToBlackListFromObjectAction } from '../smartSchedule/smartSchedule';

import {
  DND_STATUS_DISABLED_OFF,
  DND_STATUS_LOADING_OFF,
  DND_STATUS_LOADING_ON
} from '../../actions/dndStatus';

export const INIT_CALENDAR_PENDING = 'INIT_CALENDAR_PENDING';
export const INIT_CALENDAR_SUCCESS = 'INIT_CALENDAR_SUCCESS';
export const INIT_CALENDAR_FAILURE = 'INIT_CALENDAR_FAILURE';

export const SCHEDULE_POST = 'SCHEDULE_POST';
export const SCHEDULE_POST_SUCCESS = 'SCHEDULE_POST_SUCCESS';
export const SCHEDULE_POST_FAILURE = 'SCHEDULE_POST_FAILURE';

export const PUBLISH_POST_NOW = 'PUBLISH_POST_NOW';
export const PUBLISH_POST_NOW_SUCCESS = 'PUBLISH_POST_NOW_SUCCESS';
export const PUBLISH_POST_NOW_FAILURE = 'PUBLISH_POST_NOW_FAILURE';

export const PUT_TO_CALENDAR = 'PUT_TO_CALENDAR';
export const PUT_TO_CALENDAR_SUCCESS = 'PUT_TO_CALENDAR_SUCCESS';
export const PUT_TO_CALENDAR_FAILURE = 'PUT_TO_CALENDAR_FAILURE';

export const REMOVE_POST = 'REMOVE_POST';
export const REMOVE_POST_SUCCESS = 'REMOVE_POST_SUCCESS';
export const REMOVE_POST_FAILURE = 'REMOVE_POST_FAILURE';
export const PREPARE_TO_REMOVE_POST = 'PREPARE_TO_REMOVE_POST';
export const CANCEL_PREPARE_TO_REMOVE_POST = 'CANCEL_PREPARE_TO_REMOVE_POST';

export const REMOVE_POST_FROM_CALENDAR = 'REMOVE_POST_FROM_CALENDAR';
export const REMOVE_POST_FROM_CALENDAR_SUCCESS = 'REMOVE_POST_FROM_CALENDAR_SUCCESS';
export const REMOVE_POST_FROM_CALENDAR_FAILURE = 'REMOVE_POST_FROM_CALENDAR_FAILURE';

export const FILL_CALENDAR_NEW_POSTS = 'FILL_CALENDAR_NEW_POSTS';
export const FILL_CALENDAR_NEW_POSTS_FAILURE = 'FILL_CALENDAR_NEW_POSTS_FAILURE';

export const CLEAR_CALENDAR_ERROR_STATE = 'CLEAR_CALENDAR_ERROR_STATE';
export const FILL_CALENDAR_WITH_POSTS_FROM_AUTOPILOTS = 'FILL_CALENDAR_WITH_POSTS_FROM_AUTOPILOTS ';

export const DELETE_POSTS_FROM_CALENDAR = 'DELETE_POSTS_FROM_CALENDAR';

export const LOAD_MORE_POSTS_TO_DAY_PENDING = 'LOAD_MORE_POSTS_TO_DAY_PENDING';
export const LOAD_MORE_POSTS_TO_DAY_SUCCESS = 'LOAD_MORE_POSTS_TO_DAY_SUCCESS';
export const LOAD_MORE_POSTS_TO_DAY_FAIL = 'LOAD_MORE_POSTS_TO_DAY_FAIL';

export const CLEAR_ERROR_IN_CALENDAR_DAY = 'CLEAR_ERROR_IN_CALENDAR_DAY';

export function initCalendarAction(_startDate) {
  return async (dispatch, getState) => {
    dispatch({ type: INIT_CALENDAR_PENDING });

    try {
      const startDate = _startDate || getState().calendar.startDate;
      const res = await axios.get(`/api/posts/calendar`, {
        params: {
          startDate,
          days: 7,
          limitPerDay: CALENDAR_DAY_POSTS_LIMIT,
          timezoneOffset: new Date().getTimezoneOffset()
        }
      });

      dispatch({
        type: INIT_CALENDAR_SUCCESS,
        payload: { startDate, calendarData: res.data }
      });

      dispatch(datesToBlackListFromObjectAction(_postFromCalendarToBlackListFormat(res)));
    } catch (error) {
      dispatch({
        type: INIT_CALENDAR_FAILURE,
        payload: { error }
      });
    }
  };
}

export function removePostFromCalendarAction(listId, postId) {
  return dispatch => {
    dispatch({ type: REMOVE_POST_FROM_CALENDAR, listId, postId });

    try {
      dispatch({ type: REMOVE_POST_FROM_CALENDAR_SUCCESS });
      return Promise.resolve({ status: 'OK' });
    } catch (error) {
      dispatch({ type: REMOVE_POST_FROM_CALENDAR_FAILURE, error });
    }
  };
}

export function putToCalendarAction(destination, time, post, index, triggerPicker = true) {
  return dispatch => {
    post['status'] = 'SCHEDULE';
    post['calendarDate'] = `${destination}T${time}`;
    post['publicationDate'] = null;
    post['autoTriggerDataPicker'] = triggerPicker;

    if (!triggerPicker) {
      post['scheduledPublicationDate'] = `${destination}T${time}`;
    }

    dispatch({
      type: PUT_TO_CALENDAR,
      destination,
      post,
      index
    });

    try {
      dispatch({ type: PUT_TO_CALENDAR_SUCCESS });
      return Promise.resolve({ status: 'OK' });
    } catch (error) {
      dispatch({ type: PUT_TO_CALENDAR_FAILURE, error });
    }
  };
}

export function schedulePostAction(
  schedulingMoment,
  isSameDay,
  sourceDayLoadedInSystem,
  post,
  postNow
) {
  return dispatch => {
    dispatch({ type: SCHEDULE_POST });
    dispatch({ type: DND_STATUS_DISABLED_OFF });
    dispatch({ type: DND_STATUS_LOADING_ON });

    const schedulingFullData = schedulingMoment.format(DATE_TIME_FORMAT);
    const sourceDay = post.calendarDate.substr(0, 10);
    const destinationDay = schedulingFullData.substr(0, 10);

    const schedulingMomentUTC = schedulingMoment.utc().format(DATE_TIME_FORMAT);

    delete post.autoTriggerDataPicker;
    delete post.autopilotSchedule;
    delete post.autopilot;
    post.scheduledPublicationDate = schedulingFullData;
    post.calendarDate = schedulingFullData;

    if (postNow) {
      post.status = 'PUBLISH';
    }

    if (!isSameDay) {
      dispatch(removePostFromCalendarAction(sourceDay, post.id)).then(res => {
        if (res.status === 'OK' && sourceDayLoadedInSystem) {
          dispatch(putToCalendarAction(destinationDay, post.calendarDate.substr(11), post, 0));
        }
      });
    }

    return axios
      .put(`/api/posts/${post.id}/scheduled?scheduled-date=${schedulingMomentUTC}`, {})
      .then(res => {
        dispatch({ type: SCHEDULE_POST_SUCCESS, post: { ...post } });
        dispatch({ type: DND_STATUS_DISABLED_OFF });
        dispatch({ type: DND_STATUS_LOADING_OFF });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: SCHEDULE_POST_FAILURE, error });
        dispatch({ type: DND_STATUS_DISABLED_OFF });
        dispatch({ type: DND_STATUS_LOADING_OFF });
        return Promise.reject(error);
      });
  };
}

export function prepareToRemovePost(id, sourceDay) {
  return dispatch => {
    dispatch({
      type: PREPARE_TO_REMOVE_POST,
      id,
      sourceDay
    });
  };
}
export function cancelPrepareToRemovePost(id, sourceDay) {
  return dispatch => {
    dispatch({
      type: CANCEL_PREPARE_TO_REMOVE_POST,
      id,
      sourceDay
    });
  };
}

export function removePostAction(id, sourceDay, deleteFromSocial) {
  return dispatch => {
    dispatch({
      type: REMOVE_POST
    });

    return axios
      .delete(`/api/posts/${id}?deleteFromSocial=${deleteFromSocial || false}`)
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: REMOVE_POST_SUCCESS,
            id,
            sourceDay
          });
          return Promise.resolve({ status: 'OK', data: res.data });
        } else {
          dispatch(removePostFailure(res));
        }
      })
      .catch(error => {
        dispatch(removePostFailure(error));
        return Promise.resolve(error);
      });
  };
}

function removePostFailure(error) {
  return {
    type: REMOVE_POST_FAILURE,
    error
  };
}

export function publishNowAction(post, schedulingMoment) {
  return dispatch => {
    dispatch({ type: PUBLISH_POST_NOW });

    const schedulingFullData = schedulingMoment.format(DATE_TIME_FORMAT);

    delete post.autoTriggerDataPicker;
    post.scheduledPublicationDate = schedulingFullData;
    post.calendarDate = schedulingFullData;

    delete post.autoTriggerDataPicker;
    post.scheduledPublicationDate = schedulingFullData;
    post.calendarDate = schedulingFullData;
    post.status = 'PUBLISH';

    return axios
      .put(`/api/posts/${post.id}/publish`, {})
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: PUBLISH_POST_NOW_SUCCESS });
          return Promise.resolve(res);
        } else {
          post.status = 'ERROR';
          dispatch({ type: PUBLISH_POST_NOW_FAILURE, error: res });
          return Promise.reject(res);
        }
      })
      .catch(error => {
        post.status = 'ERROR';
        dispatch({ type: PUBLISH_POST_NOW_FAILURE, error });
        return Promise.reject(error);
      });
  };
}

export function fillCalendarNewPosts(posts) {
  return {
    type: FILL_CALENDAR_NEW_POSTS,
    posts
  };
}

export function fillCalendarNewPostsFailure(error) {
  return {
    type: FILL_CALENDAR_NEW_POSTS_FAILURE,
    error
  };
}

export function clearCalendarErrorState() {
  return dispatch => {
    dispatch({ type: CLEAR_CALENDAR_ERROR_STATE });
  };
}

export function deletePostsFromCalendarAction(options) {
  return {
    type: DELETE_POSTS_FROM_CALENDAR,
    payload: { options }
  };
}

export function loadMorePostsToDay(date, offset, size) {
  return async dispatch => {
    dispatch({
      type: LOAD_MORE_POSTS_TO_DAY_PENDING,
      payload: { date }
    });

    try {
      const res = await axios.get(`/api/posts/calendar/daily`, {
        params: {
          offset,
          size,
          day: date,
          timezoneOffset: new Date().getTimezoneOffset()
        }
      });

      dispatch({
        type: LOAD_MORE_POSTS_TO_DAY_SUCCESS,
        payload: {
          date,
          posts: res.data.map(post => _formatPost(post)),
          count: +res.headers?.['x-total-count'] || 0
        }
      });
    } catch (error) {
      dispatch({
        type: LOAD_MORE_POSTS_TO_DAY_FAIL,
        payload: {
          date,
          error: error.response?.data?.message || error.message
        }
      });
    }
  };
}

export function clearErrorInCalendarDay(date) {
  return {
    type: CLEAR_ERROR_IN_CALENDAR_DAY,
    payload: { date }
  };
}

function _formatPost(post) {
  return {
    ...post,
    publicationDate: utcToLocal(post.publicationDate),
    scheduledPublicationDate: utcToLocal(post.scheduledPublicationDate),
    calendarDate: utcToLocal(post.calendarDate)
  };
}

function _postFromCalendarToBlackListFormat(res) {
  const posts = res.data.reduce((acc, item) => {
    acc = [...acc, ...item.posts];
    return acc;
  }, []);
  const postsList = Array.isArray(posts) ? posts.filter(post => post.status === 'SCHEDULE') : [];

  return postsList.reduce((resultObject, post) => {
    const itemName = `${post.socialAccount.id}${
      post.socialObject ? post.socialObject.id : post.socialAccount.id
    }`;
    if (resultObject[itemName]) {
      resultObject[itemName].push(utcToLocal(post.calendarDate));
    } else {
      resultObject[itemName] = [utcToLocal(post.calendarDate)];
    }

    return resultObject;
  }, {});
}
