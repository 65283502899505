import React, { memo } from 'react';
import cn from 'classnames';
import DoubleArrowLeftIcon from 'assets/images/icons/double-arrow-left.svg';
import classes from '../cloudSelectModal.module.css';

export const MediaItem = memo(({ file, viewMode, selected, onSetViewMode, onToggleSelect }) => {
  return (
    <div
      className={cn(classes['cloud__media'], {
        [classes['cloud__media_mode_view']]: viewMode,
        [classes['cloud__media_mode_checked']]: selected
      })}
    >
      <div
        className={classes['cloud__media-thumb']}
        style={{ backgroundImage: `url("${file.thumbnails.large?.url}")` }}
      >
        {viewMode && file.type === 'VIDEO' && (
          <video src={file.externalUrl} controls={true} className={classes['cloud__media-video']} />
        )}

        <button
          onClick={() => onToggleSelect(!selected, file)}
          className={cn(classes['cloud__media-check'], {
            [classes['cloud__media-check_checked']]: selected
          })}
        />

        {!viewMode && (
          <div className={classes['cloud__media-view-box']}>
            <button
              className={classes['cloud__media-overlay']}
              onClick={() => onToggleSelect(!selected, file)}
            />
            <button
              className={classes['cloud__media-view-btn']}
              onClick={() => onSetViewMode(file.id)}
            >
              View
            </button>
          </div>
        )}
      </div>

      <div className={classes['cloud__media-info']}>
        <div className={classes['cloud__media-info-content']}>
          <h5 className={classes['cloud__media-info-title']}>
            {file.title ? file.title : 'No title'}
          </h5>
          <p className={classes['cloud__media-info-text']}>
            {file.desciption ? file.desciption : 'No description'}
          </p>
        </div>
        <div className={classes['cloud__media-info-footer']}>
          <button
            className={classes['cloud__media-info-close']}
            onClick={() => onSetViewMode(undefined)}
          >
            <img src={DoubleArrowLeftIcon} alt="icon" />
            Hide
          </button>
        </div>
      </div>
    </div>
  );
});
