export const SET_SMART_SCHEDULE = 'SET_SMART_SCHEDULE';
export const TOGGLE_SMART_SCHEDULE = 'TOGGLE_SMART_SCHEDULE';
export const DATES_TO_BLACKLIST = 'DATES_TO_BLACKLIST';
export const REMOVE_DATE_FROM_BLACKLIST = 'REMOVE_DATE_FROM_BLACKLIST';


export function setScheduleTimeAction(date, payload) {
  return {
    type: SET_SMART_SCHEDULE,
    date,
    payload
  };
}

export function toggleSmartScheduleAction() {
  return {
    type: TOGGLE_SMART_SCHEDULE
  };
}

export function datesToBlackListAction(accountId, socialObjId, dates) {
  return {
    type: DATES_TO_BLACKLIST,
    accountId,
    socialObjId,
    dates
  };
}

export function datesToBlackListFromObjectAction(objectBlackList) {
  return {
    type: DATES_TO_BLACKLIST,
    objectBlackList
  };
}

export function removeDateFromBlackListAction(accountId, socialObjId, date) {
  return {
    type: REMOVE_DATE_FROM_BLACKLIST,
    accountId,
    socialObjId,
    date
  };
}
