import { deepCopyObject } from 'util/deepCopyObject';

export function changeAclRole(state, action) {
  const {
    payload: { elementInd, role }
  } = action;
  const nextState = deepCopyObject(state);

  nextState.team.list[elementInd] = {
    ...nextState.team.list[elementInd],
    isChangingRole: false,
    companyPermission: {
      ...nextState.team.list[elementInd].companyPermission,
      authority: role
    }
  };

  return nextState;
}
