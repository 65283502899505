import isEmpty from 'lodash/isEmpty';
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_CURRENT_USER,
  CLEAR_AUTH_ERROR_STATE,
  CHECK_EMAIL_EXIST,
  CHECK_EMAIL_EXIST_SUCCESS,
  CHECK_EMAIL_EXIST_FAILURE,
  SET_FIRST_LOGIN
} from '../../actions/auth/auth';
import baseReducerHandler from 'util/baseReducerHandler';

const initialState = {
  isAuth: false,
  isFirstLogin: false,
  loading: false,
  error: null,
  user: {
    name: ''
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return baseReducerHandler(state, true, null);
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SET_CURRENT_USER:
      const { auth } = action.user;

      return {
        ...state,
        isAuth: !isEmpty(action.user),
        isUser: auth.filter(role => role === 'ROLE_USER').length >= 1,
        isManager: auth.filter(role => role === 'ROLE_MANAGER').length >= 1,
        isAdmin: auth.filter(role => role === 'ROLE_ADMIN').length >= 1,
        isCompanyOwner: auth.filter(role => role === 'ROLE_COMPANY_OWNER').length >= 1,
        isCompanyAdmin: auth.filter(role => role === 'ROLE_COMPANY_ADMIN').length >= 1,
        isCompanyManager: auth.filter(role => role === 'ROLE_COMPANY_MANAGER').length >= 1,
        isCompanyCloudContributor: auth.filter(role => role === 'ROLE_COMPANY_CLOUD_CONTRIBUTOR').length >= 1,
        user: action.user
      };

    case LOGOUT:
      return {
        ...state,
        isAuth: false,
        user: {}
      };

    case CHECK_EMAIL_EXIST:
      return baseReducerHandler(state, true, null);
    case CHECK_EMAIL_EXIST_SUCCESS:
    case CHECK_EMAIL_EXIST_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case CLEAR_AUTH_ERROR_STATE:
      return baseReducerHandler(state, false, null);

    case SET_FIRST_LOGIN:
      return { ...state, isFirstLogin: action.payload };

    default:
      return state;
  }
};
