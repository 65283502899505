export function loadMoreProductSuccess(state, action) {
  const payload = _filterPayload(state, action);

  return Object.assign({}, state, {
    payload,
    loadedProductsOnScroll: false,
    cursor: action.cursor,
    pageNumber: action.pageNumber,
    shopId: action.shopId,
    hasNext: action.hasNext
  });
}


const _filterPayload = (state, action) => {
  const result = state.payload.slice();

  action.payload.forEach(actionItem => {
    const containsActionItem = result.some(item => item.id === actionItem.id);
    if (!containsActionItem) {
      result.push(actionItem);
    }
  });

  return result;
}