import sortBy from 'lodash/sortBy';
import setSocialTypes from './utils/setSocialTypes';

export function getAccountsSuccess(state, action) {
  const payload = state.payload;
  const nextPayload = sortBy(action.payload, ['name']).map(acc => {
    const foundAccount = payload.find(accPayload => accPayload.id === acc.id);
    if (foundAccount) {
      return { ...foundAccount, ...acc };
    } else {
      return acc;
    }
  });
  const activePayload = _getActivePayload(nextPayload);

  return Object.assign({}, state, {
    activePayload,
    payload: nextPayload,
    loading: false,
    error: null,
    socialTypes: setSocialTypes(nextPayload)
  });
}

function _getActivePayload(payload) {
  let nextActivePayload = [...payload];

  payload.forEach(account => {
    if (account.objects) {
      account.objects.forEach(page => {
        const foundPageInActivePayload = nextActivePayload.find(_page => _page.id === page.id);
        if (!foundPageInActivePayload) {
          nextActivePayload.push(page);
        }
      });
    }
  });

  return nextActivePayload;
}
