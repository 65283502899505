import sortBy from 'lodash/sortBy';
import setSocialTypes from './utils/setSocialTypes';
import { SOCIAL_TYPE_WITHOUT_PAGE } from '../../../constants/socialTypeWithoutPage';

export function setActivePayload(state, action) {
  const nextActivePayload = _sortArrayByName(action.activePayload);
  const socialTypes = setSocialTypes(action.activePayload);
  const nextPayload = _cratePayloadFromActivePayload(action.activePayload);

  return Object.assign({}, state, {
    payload: nextPayload,
    activePayload: nextActivePayload,
    socialTypes: socialTypes,
    loading: false,
    error: null
  });
}

function _sortArrayByName(array) {
  return sortBy(array, ['name']);
}

function _cratePayloadFromActivePayload(activePayload) {
  return activePayload.filter(acc => !Boolean(acc.socialNetworkType))
    .map(acc => {
      acc.objects = SOCIAL_TYPE_WITHOUT_PAGE.includes(acc.type) ? []
        : activePayload.filter(obj => obj.accounts && obj.accounts.length && obj.accounts[0].id === acc.id);
      return acc;
    });

}
