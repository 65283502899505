import {
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  CLEAR_SIGNUP_ERROR_STATE,
} from '../../actions/signup';
import baseReducerHandler from 'util/baseReducerHandler';

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP:
      return baseReducerHandler(state, true, null);

    case SIGNUP_SUCCESS:
      return baseReducerHandler(state, false, null);

    case SIGNUP_FAILURE:
      return baseReducerHandler(state, false, action.error);

    case CLEAR_SIGNUP_ERROR_STATE:
      return baseReducerHandler(state, false, null);

    default:
      return state;
  }
};
