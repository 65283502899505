//Packages
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import DefaultSocialObject from 'assets/images/icons/default_social_object.svg';

import { getSocialImage } from 'util/getSocialIcon';

//Components
import CheckboxComponent from 'components/common/checkbox/checkbox';

export const renderOptionWithIcon = page => {
  const account = page.accounts?.[0];

  return (
    <div className="option__flex" key={page.label}>
      <div className="option__flex_left">
        <img src={page.thumbnails?.small?.url || DefaultSocialObject} alt="thumbnail" 
          onError={(e) => {
            e.target.src = getSocialImage(page.type);
            e.target.title = 'This is a placeholder image. To see your regular account avatar, simply reconnect your account.'
          }} />
      </div>
      <span className="option__flex_right">
        {page.label}
        {account && <span>{account.name}</span>}
      </span>
    </div>
  );
};

export const getValueForSocialPagesSelect = socialPage => {
  if (isEmpty(socialPage)) {
    return { label: 'Social pages', thumb: null, value: '', type: null };
  }

  return {
    ...socialPage,
    label: socialPage.name,
    value: socialPage.id,
    thumb: socialPage.thumbnails?.small?.url || DefaultSocialObject,
    type: socialPage.socialNetworkType || socialPage.type
  };
};

export const getMultiValueForSocialPagesSelect = socialPages => {
  return socialPages.map(getValueForSocialPagesSelect);
};

export const renderOptionWithCheckbox = (option, params) => {
  const checked = params.selectValue.find(item => item.id === option.value) ? true : false;
  return (
    <div key={option.value} className="select-filter__item">
      {params.context === 'menu' && (
        <CheckboxComponent checked={checked} onChange={e => e.preventDefault()} />
      )}
      <span>{option.label}</span>
    </div>
  );
};

export const renderOptionWithCheckboxWithExtraData = (option, params) => {
  const checked = params.selectValue.find(item => item.id === option.value) ? true : false;
  return (
    <div key={option.value} className='select-filter__item-wrapper'>
      <div className="select-filter__item">
        {params.context === 'menu' && (
          <CheckboxComponent checked={checked} onChange={e => e.preventDefault()} />
        )}
        <span>{option.label}</span>
      </div>
      {option.extraData && <b className="select-filter__extra">{option.extraData}</b>}
    </div>
  );
};
