import { deepCopyObject } from 'util/deepCopyObject';

export function setCurrentAcl(state, action) {
  const {
    payload: { currentCompany }
  } = action;
  const nextState = deepCopyObject(state);

  nextState.currentCompany = currentCompany;

  return nextState;
}
