import axios from "axios";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const HANDLE_PASSWORD_FIELD = 'HANDLE_PASSWORD_FIELD';
export const CANCEL_CHANGE_PASSWORD = "CANCEL_CHANGE_PASSWORD";
export const CLEAR_CHANGE_PASSWORD_ERROR_STATE = "CLEAR_CHANGE_PASSWORD_ERROR_STATE";

export function changePasswordAction(currentPassword, newPassword) {
  return dispatch => {
    dispatch({type: CHANGE_PASSWORD});

    return axios
      .post(`/api/account/change-password`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: CHANGE_PASSWORD_SUCCESS});
        } else {
          dispatch({
            type: CHANGE_PASSWORD_FAILURE,
            error: res,
            incorrectPassword: res.status === 400,
          });
        }
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: CHANGE_PASSWORD_FAILURE,
          error,
          incorrectPassword: error.response.status === 400,
        });
        return Promise.resolve(error);
      });
  };
}

export function handleField(event) {
  return {
    type: HANDLE_PASSWORD_FIELD,
    name: event.target.name,
    value: event.target.value,
  };
}

export function cancelChangePassword() {
  return { type: CANCEL_CHANGE_PASSWORD };
}

export function clearChangePasswordErrorState() {
  return {type: CLEAR_CHANGE_PASSWORD_ERROR_STATE};
}
