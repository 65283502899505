import React from 'react';

export function tourPublicationSteps(hasConnectedStores) {
  const steps = [
    {
      display: true,
      selector: '[data-tour-id="accounts"]',
      content: (
        <>
          <h3>Social Media</h3>
          <p>
            The place where you can see all your social media pages and select the one you plan to
            post on.
          </p>
        </>
      )
    },
    {
      display: true,
      selector: '[data-tour-id="sources"]',
      content: (
        <>
          <h3>Products</h3>
          <p>The place where you can see and schedule product postings.</p>
        </>
      )
    },
    {
      display: hasConnectedStores,
      selector: '[data-tour-id="sourceSearchAndFilter"]',
      content: (
        <>
          <h3>Search and filter</h3>
          <p>Use search and filters to select a product for your next post.</p>
        </>
      )
    },
    {
      display: true,
      selector: '[data-tour-id="newPost"]',
      content: (
        <>
          <h3>New Post</h3>
          <p>Create and schedule new post.</p>
        </>
      )
    },
    {
      display: false,
      selector: '[data-tour-id="autoschedulingBtn"]',
      content: (
        <>
          <h3>Auto-scheduling</h3>
          <p>Schedule the whole week with one click.</p>
        </>
      )
    },
    {
      display: true,
      selector: '[data-tour-id="smartScheduleItems"]',
      content: (
        <>
          <h3>Smart-scheduling</h3>
          <p>
            This section emphasizes recommended times to schedule your next post. Simply drag a
            product into the section.
          </p>
        </>
      )
    },
    {
      display: true,
      selector: '[data-tour-id="calendar"]',
      content: (
        <>
          <h3>Calendar</h3>
          <p>The place where you can see and edit all your social media postings.</p>
        </>
      )
    },
    {
      display: true,
      selector: '',
      content: (
        <>
          <h3>Congrats, you made it!</h3>
          <p>
            Now you know everything you need to start using Onollo. Each page has its own How it
            Works guide. So don’t worry, we won’t let you down{' '}
            <span role="img" aria-label="perfect">
              🙂
            </span>
          </p>
        </>
      )
    }
  ];

  return steps.filter(step => step.display);
}
