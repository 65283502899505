import decode from 'jwt-decode';
import axios from 'axios';

export default class AuthService {
  login(data) {
    return axios.post(`/api/authenticate`, data).then(res => {
      this.setToken(res.data.id_token);
      this.setUsername(this.getProfile().sub)
      this.setAuthHeaders();
      return Promise.resolve(res);
    });
  }

  loggedIn() {
    const tokenIsValid = this.getToken();
    return tokenIsValid;
  }

  isTokenExpired() {
    try {
      const token = this.getToken();
      if (!token) {
        return true;
      }
      const decoded = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  }

  isTokenValid() {
    // Check valids of token
    const token = this.getToken();
    const profile = this.getProfile();

    if (token) {
      axios.get(`/api/authenticate`).then(res => {
        if (profile.sub !== '') {
          return res.data === profile.sub;
        }
      });
    }

    return false;
  }

  //Change of token when changing the organization
  changeToken() {
    return axios.get(`/api/token`).then(res => {
      this.setToken(res.data.id_token);
      this.setAuthHeaders();
      localStorage.removeItem('contentModalState');
      return Promise.resolve(res);
    });
  }

  setToken(idToken) {
    localStorage.setItem('id_token', idToken);
  }

  getToken() {
    return localStorage.getItem('id_token');
  }

  removeToken() {
    return localStorage.removeItem('id_token');
  }

  setUsername(username) {
    localStorage.setItem('username', username);
  }

  getUsername() {
    return localStorage.getItem('username');
  }

  removeUsername() {
    return localStorage.removeItem('username');
  }

  setAuthHeaders() {
    const token = this.getToken();
    if (token) axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  removeAuthHeaders() {
    delete axios.defaults.headers.common['Authorization'];
  }

  logout = () => {
    return new Promise((resolve) => {
      this.removeToken();
      this.removeUsername();
      this.removeAuthHeaders();
      localStorage.removeItem('contentModalState');
      resolve();
    });
  };

  getProfile = () => {
    // Using jwt-decode npm package to decode the token
    return decode(this.getToken());
  };

  outdatedPermissions = (currentCompanyPermission) => {
    if (!currentCompanyPermission) {
      return true;
    }
    if (currentCompanyPermission.state.value !== 'APPROVED' || currentCompanyPermission.status.value !== 'ACTIVE') {
      return true;
    }

    const oldProfile = this.getProfile();
    if (!oldProfile) {
      return true;
    }
    if (currentCompanyPermission.company.id !== oldProfile.company) {
      return true;
    }
    if (!oldProfile.auth.includes(currentCompanyPermission.authority.value)) {
      return true;
    }

    return false;
  };

  getProfileFromToken = (token) => {
    // Using jwt-decode npm package to decode the token
    return decode(token);
  };
}
