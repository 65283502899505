export const DND_STATUS_DISABLED_ON = 'DND_STATUS_DISABLED_ON';
export const DND_STATUS_DISABLED_OFF = 'DND_STATUS_DISABLED_OFF';
export const DND_STATUS_LOADING_ON = 'DND_STATUS_LOADING_ON';
export const DND_STATUS_LOADING_OFF = 'DND_STATUS_LOADING_OFF';

export function dndStatusDisabledOn() {
    return {
        type: DND_STATUS_DISABLED_ON
    };
}

export function dndStatusDisabledOff() {
    return {
        type: DND_STATUS_DISABLED_OFF
    };
}

export function dndStatusLoadingOn() {
    return {
        type: DND_STATUS_LOADING_ON
    };
}

export function dndStatusLoadingOff() {
    return {
        type: DND_STATUS_LOADING_OFF
    };
}