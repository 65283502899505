import uuid from 'uuid';

export const getImageFromSourceData = (sourceType, sourceData) => {
  let images = [];

  if (!sourceData) return images;

  if (sourceType === 'PRODUCT') {
    images = sourceData.images.map(image => ({
      id: uuid(),
      status: 'ACTIVE',
      origin: 'PRODUCT',
      url: image.url,
      small: image.small,
      medium: image.medium,
      large: image.large,
      mobileLarge: image.mobileLarge
    }));
  } else if (sourceType === 'VIDEO') {
    images.push({
      id: uuid(),
      status: 'ACTIVE',
      origin: 'VIDEO',
      url: sourceData.thumbnails.large.url,
      small: sourceData.thumbnails.small,
      medium: sourceData.thumbnails.medium,
      large: sourceData.thumbnails.large,
      mobileLarge: sourceData.thumbnails.large
    });
  // } else if (sourceType === 'MEDIA_IMAGES' || sourceType === 'MEDIA_VIDEOS') {
  } else if (sourceType === 'MEDIA_IMAGES') {
    images.push({
      id: sourceData.id,
      status: 'ACTIVE',
      origin: 'CONTENT_CLOUD',
      url: sourceType === 'MEDIA_IMAGES' ? sourceData.thumbnails.url : sourceData.thumbnails.large.url,
      small: sourceData.thumbnails.small,
      medium: sourceData.thumbnails.medium,
      large: sourceData.thumbnails.large,
      mobileLarge: sourceData.thumbnails.large
    });
  }
  return images;
};

export const getUrlFromSourceData = (sourceType, sourceData) => {
  if (!sourceData || !sourceType) return '';

  if (sourceType === 'VIDEO') {
    return sourceData.externalUrl || '';
  } else {
    return sourceData.url || sourceData.link || '';
  }
};
