import {
  GET_STORES,
  GET_STORES_SUCCESS,
  GET_STORES_FAILURE,
  SET_ACTIVE_STATUS_STORE,
  CONNECT_TO_STORE,
  CONNECT_TO_STORE_SUCCESS,
  CONNECT_TO_STORE_FAILURE,
  REMOVE_STORE,
  REMOVE_STORE_SUCCESS,
  REMOVE_STORE_FAILURE,
  HIDE_LOAD_STORE_NOTIFICATION,
  CLEAR_STORE_ERROR_STATE,
  CLEAR_STORE_REDUX_STATE
} from "../../actions/store/store";
import { removeStore, removeStoreSuccess } from "./removeStore";
import baseReducerHandler from 'util/baseReducerHandler';

const initialState = {
  payload: [],
  loading: false,
  error: null,
  showLoadNotification: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STORES:
      return baseReducerHandler(state, true, null);
    case GET_STORES_SUCCESS:
      return baseReducerHandler({...state, payload: action.payload}, false, action.error ? action.error : null);
    case GET_STORES_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SET_ACTIVE_STATUS_STORE:
      return {
        ...state,
        payload: state.payload.map(store => store.id === action.storeId ?
          {...store, status: 'ACTIVE'}
          : store)
      }

    case CONNECT_TO_STORE:
      return baseReducerHandler(state, true, null);
    case CONNECT_TO_STORE_SUCCESS: {
      if (action.shop) {
        return {
          ...state,
          payload: Array.isArray(state.payload) ?
            [...state.payload.filter(shop => shop.id !== action.shop.id), action.shop]
            : [action.shop],
          loading: false,
          error: null
        }
      } else {
        return baseReducerHandler(state, false, action.error ? action.error : null);
      }
    }

    case CONNECT_TO_STORE_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case REMOVE_STORE:
      return removeStore(state, action);
    case REMOVE_STORE_SUCCESS:
      return removeStoreSuccess(state, action);
    case REMOVE_STORE_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case HIDE_LOAD_STORE_NOTIFICATION:
      return Object.assign({}, state, {
        showLoadNotification: false
      });

    case CLEAR_STORE_ERROR_STATE:
      return baseReducerHandler(state, false, null);

    case CLEAR_STORE_REDUX_STATE:
      return baseReducerHandler(initialState, false, null);

    default:
      return state;
  }
};
