import { deepCopyObject } from 'util/deepCopyObject';

export function updateAclRoles(state, type, nextProps) {
  const nextState = deepCopyObject(state);

  nextState.roles[type] = {
    ...nextState.roles[type],
    ...nextProps
  };

  return nextState;
}
