import validEmail from 'util/validEmail';

export function validate(state, fieldName, value) {
  let fieldValidationErrors = state.formErrors;
  let nameValid = state.nameValid;
  let emailValid = state.emailValid;

  switch (fieldName) {
    case 'name':
      if (value.length === 0) {
        nameValid = false;
        fieldValidationErrors.name = '';
      } else if (value.length >= 2) {
        nameValid = true;
        fieldValidationErrors.name = '';
      } else {
        nameValid = false;
        fieldValidationErrors.name = 'is too short';
      }
      break;

    case 'email':
      if (value.length === 0 ) {
        emailValid = false;
        fieldValidationErrors.email = '';
      } else if (value.length > 5) {
        if (validEmail(value)) {
          emailValid = true;
          fieldValidationErrors.email = '';
        }
      } else {
        emailValid = false;
        fieldValidationErrors.email = 'Email is invalid';
      }

      break;

    default:
      break;
  }

  let validationData = {
    formErrors: fieldValidationErrors,
    nameValid: nameValid,
    emailValid: emailValid,
  };

  return {
    ...validationData,
    formValid: validateForm(validationData),
  };
}

function validateForm(validationData) {
  return validationData.nameValid && validationData.emailValid;
}