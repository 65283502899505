export function toggleAccountInAnalyticsSuccess(state, action) {
  const newPayload = state.activePayload.map(account => {
    if (account.id === action.id) {
      return {
        ...account,
        selectedInAnalytics: !action.nowSelectedStatus,
      };
    } else {
      return account;
    }
  });

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null,
  });
}
