import React, { memo, useState, useEffect } from 'react';
import axios from 'axios';
import format from 'date-fns/format';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import Button from 'components/common/button/button';
import ModalComponent from 'components/common/modal/modal';
import WithErrorHandling from 'components/common/withErrorHandling/withErrorHandling';
import { useActions, useQueryWithCache } from 'hooks';
import { showModal, hideModal, modalTypes } from 'store/actions/modal/modal';
import FridayShape from 'assets/images/friday-shape.svg';
import ChristmasShape from 'assets/images/christmas-shape.svg';
import CloseIcon from 'assets/images/icons/close.svg';
import getModalData from 'util/getModalData';
import classes from './promoPlanModal.module.css';

export const PromoPlanModal = memo(() => {
  const actions = useActions({ showModal, hideModal });
  const [error, setError] = useState('');
  const [promoPlan, setPromoPlan] = useState({});
  const { isOpen, onClose } = useSelector(state => getModalData(state, modalTypes.PROMO)) || {};
  const isChristmasPlan = promoPlan.name === 'Christmas Plan';
  const validUntil = promoPlan?.validUntil
    ? `${format(new Date(promoPlan?.validUntil), 'MMMM do')}`
    : 'loading...';

  const promoPlanQuery = useQueryWithCache(
    () => 'promo-plan',
    () => axios.get('/api/billing/tariffs/promo'),
    {
      enabled: false,
      onSuccess: res => setPromoPlan(res.data),
      onError: err => {
        setError(err.response.data?.title || 'Something went wrong on get plan');
      }
    }
  );

  useEffect(() => {
    isOpen && promoPlanQuery.refetch();
  }, [isOpen]);

  function handleCloseModal() {
    actions.hideModal();
    onClose && onClose();
  }

  return (
    <WithErrorHandling
      showError={!!error}
      errorMessage={error}
      clearErrorHandler={() => setError('')}
    >
      <ModalComponent
        center
        isOpen={isOpen}
        closeModal={handleCloseModal}
        hideCloseButton={true}
        modalPadding="0"
        width={700}
        className={cn(classes['bfm'], {
          [classes['bfm_theme_christmas']]: isChristmasPlan
        })}
      >
        <div className={classes.bfm__flex}>
          <div className={classes.bfm__left}>
            <div className={classes.bfm__logo}>
              {isChristmasPlan ? (
                <img src={ChristmasShape} alt="Christmas shape" />
              ) : (
                <img src={FridayShape} alt="Friday shape" />
              )}
              {!isChristmasPlan && (
                <div className={classes['bfm__logo-info']}>
                  <h4 className={classes['bfm__logo-title']}>BLACK FRIDAY</h4>
                  <span className={classes['bfm__logo-desc']}>until {validUntil}</span>
                </div>
              )}
            </div>
            <div className={classes['bfm__features-wrp']}>
              <h3 className={classes['bfm__features-title']}>Offer includes</h3>
              <ul className={classes['bfm__features']}>
                <li className={classes['bfm__feature']}>Unlimited Postings</li>
                <li className={classes['bfm__feature']}>Unlimited Autopilots</li>
                <li className={classes['bfm__feature']}>Unlimited Social Networks</li>
                <li className={classes['bfm__feature']}>Unlimited Stores</li>
                <li className={classes['bfm__feature']}>Unlimited Video Channels</li>
                {promoPlan.trialDays ? (
                  <li className={classes['bfm__feature']}>
                    {promoPlan.trialDays}-day trial period
                  </li>
                ) : (
                  <></>
                )}
                <li className={cn(classes['bfm__feature'], classes['bfm__feature_disc-plus'])}>
                  Content Cloud Beta
                </li>
              </ul>
            </div>
          </div>
          <div className={classes.bfm__right}>
            <button
              className={classes.bfm__close}
              title="Close the modal"
              onClick={handleCloseModal}
            >
              <img src={CloseIcon} alt="Close the modal" />
            </button>

            <span className={classes.bfm__period}>
              {promoPlan.recurringInterval?.toLowerCase() || 'loading...'}
            </span>
            <h2 className={classes.bfm__name}>{promoPlan.name || 'loading...'}</h2>
            <p className={classes.bfm__desc}>All features included. Everything is unlimited.</p>
            <div className={classes.bfm__prices}>
              <span className={classes['bfm__prices-label']}>just for</span>
              <span className={classes['bfm__prices-past']}>
                ${promoPlan?.amountRegular || 'loading...'}
              </span>
              <span className={classes['bfm__prices-current']}>
                ${promoPlan?.amountSale || 'loading...'}
              </span>
            </div>
            <Button
              darkBlue
              label="Sign Up"
              className={classes.bfm__btn}
              onClick={() => {
                handleCloseModal();
                actions.showModal(modalTypes.UPGRADE_PLAN_SELECT_STORE, {
                  planId: promoPlan.id,
                  planType: promoPlan.type
                });
              }}
            />
            <span className={classes.bfm__date}>until {validUntil}</span>
          </div>
        </div>
      </ModalComponent>
    </WithErrorHandling>
  );
});
