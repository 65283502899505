import React, { Component } from 'react';
import AuthService from './authService';

export default function withAuth(AuthComponent) {
  const Auth = new AuthService(process.env.API_URL);

  return class AuthWrapped extends Component {
    constructor(props) {
      super(props);
      this.state = {
        user: null
      };
    }

    componentWillMount() {
      if (window.location.pathname.startsWith('/login')) {
        return;
      }

      if (!Auth.loggedIn()) {
        window.location.replace('/login');
        localStorage.removeItem('contentModalState');
      } else {
        try {
          const profile = Auth.getProfile();
          this.setState({
            user: profile
          });
        } catch (err) {
          Auth.logout();
          window.location.replace('/login');
          localStorage.removeItem('contentModalState');
        }
      }
    }

    render() {
      if (this.state.user) {
        return <AuthComponent history={this.props.history} user={this.state.user} />;
      } else {
        return null;
      }
    }
  };
}
