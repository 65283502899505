import axios from 'axios';

export const SET_URL_SHORTENER_ACCOUNT = 'SET_URL_SHORTENER_ACCOUNT';
export const REMOVE_URL_SHORTENER_ACCOUNT = 'REMOVE_URL_SHORTENER_ACCOUNT';
export const TOGGLE_SHORTENER_ACCOUNT_STATUS = 'TOGGLE_SHORTENER_ACCOUNT_STATUS';
export const TOGGLE_SHORTENER_DOMAIN_STATUS = 'TOGGLE_SHORTENER_DOMAIN_STATUS';

export const SET_ERROR_SHORTENER_ACCOUNTS = 'SET_ERROR_SHORTENER_ACCOUNTS';
export const CLEAR_ERROR_SHORTENER_ACCOUNTS = 'CLEAR_ERROR_SHORTENER_ACCOUNTS';

export function setUrlShortenerAccountAction(account) {
  return {
    type: SET_URL_SHORTENER_ACCOUNT,
    payload: {
      account
    }
  };
}

export function removeUrlShortenerAccountAction(id) {
  return dispatch => {
    return new Promise(async (resolve, reject) => {
      try {
        await axios.delete(`/api/url-shortener-account/${id}`);

        dispatch({
          type: REMOVE_URL_SHORTENER_ACCOUNT,
          payload: { id }
        });
        resolve({ success: true });
      } catch (error) {
        dispatch({ type: SET_ERROR_SHORTENER_ACCOUNTS, error });
        reject(error);
      }
    });
  };
}

export function toggleStatusForUrlShortenerAccountAction(accountId, nextStatus) {
  return async dispatch => {
    try {
      await axios.put(`/api/url-shortener-account/${accountId}/status/${nextStatus}`, {
        propagateInDomains: true
      });

      dispatch({
        type: TOGGLE_SHORTENER_ACCOUNT_STATUS,
        payload: { accountId, status: nextStatus }
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_ERROR_SHORTENER_ACCOUNTS, error });
    }
  };
}

export function toggleStatusForUrlShortenerDomainAction(accountId, domainId, nextStatus) {
  return async dispatch => {
    try {
      await axios.put(`/api/url-shortener-domain/${domainId}/status/${nextStatus}`);

      dispatch({
        type: TOGGLE_SHORTENER_DOMAIN_STATUS,
        payload: { accountId, domainId, status: nextStatus }
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_ERROR_SHORTENER_ACCOUNTS, error });
    }
  };
}

export function clearErrorForShortenerAccountsAction() {
  return {
    type: CLEAR_ERROR_SHORTENER_ACCOUNTS
  };
}
