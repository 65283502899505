import differenceInMinutes from 'date-fns/differenceInMinutes';
import set from 'date-fns/set';
import addDays from 'date-fns/addDays';
import { ANTI_SPAM_TIME } from 'constants/autopilot';

export function validateSchedules(schedules) {
  let isValid = true;
  const nextSchedules = [...schedules];

  schedules.forEach(({ dayOfWeek, time }, ind) => {
    const leftSide = [...schedules].slice(0, ind);
    const [hours, minutes] = time.split(':');
    let date = set(new Date(), { hours, minutes, seconds: 0, milliseconds: 0 });

    if (time === '00:00') {
      date = addDays(date, 1);
    }

    const foundConflict = leftSide.some(lSchedule => {
      const [lHours, lMinutes] = lSchedule.time.split(':');
      const lDate = set(new Date(), {
        hours: lHours,
        minutes: lMinutes,
        seconds: 0,
        milliseconds: 0
      });

      if (lSchedule.time === time || Math.abs(differenceInMinutes(date, lDate)) < ANTI_SPAM_TIME) {
        const foundBusyDay = dayOfWeek.find(day => lSchedule.dayOfWeek.includes(day));
        return !!foundBusyDay;
      } else {
        return false;
      }
    });

    nextSchedules[ind].hasTimeConflict = foundConflict;
  });

  return { nextSchedules, isValid };
}
