export function togglePageInPublicationSuccess(state, action) {
  let newPayload;
  //If we make an page inactive
  if (action.isActive) {
    newPayload = state.activePayload.map(page => {
      //We check whether the page is or not, because ID can match
      if (page.id === action.id  && page.socialNetworkType) {
        return {
          ...page,
          selectedInPublications: false
        };
      } else {
        return page;
      }
    });
  }

  //If we make an active page, then deactivate other page of the same social network
  else {
    newPayload = state.activePayload.map(page => {
      //We check whether the page is or not, because ID can match
      if (page.id === action.id && page.socialNetworkType) {
        return {
          ...page,
          selectedInPublications: true
        };
      } else if (page.socialNetworkType && page.socialNetworkType === action.socialType) {
        return {
          ...page,
          selectedInPublications: false
        };
      }
      else {
        return page;
      }
    });
  }

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null,
  });
}

export function activateAllPagesInPublicationSuccess(state, action) {
  const newPayload = state.activePayload.map(page => {
    if (page.socialNetworkType) {
      return {
        ...page,
        selectedInPublications: true,
      };
    } else {
      return page;
    }
  });

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null,
    togglePagesLoading: false,
  });
}

export function deactivateAllPagesInPublicationSuccess(state, action) {
  const newPayload = state.activePayload.map(page => {
    if (page.socialNetworkType) {
      return {
        ...page,
        selectedInPublications: false,
      };
    } else {
      return page;
    }
  });

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null,
    togglePagesLoading: false,
  });
}
