import React, { Component } from "react";
import InfoModal from "./info/info";

export default class ModalWrapper extends Component {
  render() {
    return (
      <section>
        <InfoModal />
      </section>
    );
  }
}
