import { SET_TOUR, CLEAR_TOUR } from 'store/actions/tour';

const initialState = {
  val: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TOUR:
      return { ...state, val: action.tourName };

    case CLEAR_TOUR:
      return { ...state, val: '' };

    default:
      return state;
  }
};
