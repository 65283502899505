import { VIDEO_ACCOUNT_TYPES } from 'constants/accounts';
import { getChannelsAction } from '../../actions/video/video';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_PAGE = 'GET_PAGE';
export const REMOVE_PAGE = 'REMOVE_PAGE';

export function getAccountAction(account) {
  return dispatch => {
    dispatch({ type: GET_ACCOUNT, account });

    if(VIDEO_ACCOUNT_TYPES.includes(account.type)) {
      dispatch(getChannelsAction());
    }
  }

}
export function getPageAction(page) {
  return {
    type: GET_PAGE,
    page
  }
}
export function removePageAction(pageId) {
  return {
    type: REMOVE_PAGE,
    page: pageId
  }
}
