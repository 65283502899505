import { GET_PLAYLIST, ADD_PLAYLIST, REMOVE_PLAYLIST, CHANGE_PLAYLIST } from '../../actions/playlist';
import uuid from 'uuid';

export default (state = [], action) => {
  switch (action.type) {
    case GET_PLAYLIST:
      return [...action.payload];
    case ADD_PLAYLIST:
      return [
        ...state,
        {
          id: uuid(),
          name: action.data.name,
          type: action.data.type,
          background: action.data.background ? action.data.background : ''
        }
      ];
    case CHANGE_PLAYLIST:
      return state.map(playlist => {
        if (playlist.id === action.id) {
          return {
            ...playlist,
            ...action
          };
        } else {
          return playlist;
        }
      });
    case REMOVE_PLAYLIST:
      return state.filter(playlist => playlist.id !== action.id);
    default:
      return state;
  }
};
