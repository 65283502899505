import { deepCopyObject } from 'util/deepCopyObject';

export function deleteAclItem(state, action) {
  const {
    payload: { listName, elementInd }
  } = action;
  const nextState = deepCopyObject(state);

  nextState[listName].list.splice(elementInd, 1);
  nextState[listName].count = nextState[listName].count - 1;

  return nextState;
}
