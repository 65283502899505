export function _formatCloudMediaForPost(mediaKey, file, setSourceProperty) {
  const mediaFile = {
    id: file.id,
    status: file.status,
    origin: 'CONTENT_CLOUD',
    url: file.externalUrl
  };

  if (setSourceProperty) {
    mediaFile.source = file.source;
  }

  if (mediaKey === 'images') {
    mediaFile.small = file.thumbnails.small;
    mediaFile.medium = file.thumbnails.medium;
    mediaFile.large = file.thumbnails.large;
    mediaFile.mobileLarge = file.thumbnails.mobileLarge;
    mediaFile.largeTile = file.thumbnails.largeTile;
  }

  if (mediaKey === 'videos') {
    mediaFile.thumbnailUrl = file.thumbnails?.large?.url || null;
  }

  return mediaFile;
}
