import {
  ADD_ACCOUNT,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAILURE,
  UPDATE_ACCOUNT,
  REMOVE_ACCOUNT,
  REMOVE_ACCOUNT_FAILURE,
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAILURE,
  TOGGLE_ACCOUNT_IN_PUBLICATION,
  TOGGLE_ACCOUNT_IN_PUBLICATION_SUCCESS,
  TOGGLE_ACCOUNT_IN_PUBLICATION_FAILURE,
  TOGGLE_ACCOUNT_IN_ANALYTICS,
  TOGGLE_ACCOUNT_IN_ANALYTICS_SUCCESS,
  TOGGLE_ACCOUNT_IN_ANALYTICS_FAILURE,
  TOGGLE_PAGE_IN_PUBLICATION,
  TOGGLE_PAGE_IN_PUBLICATION_SUCCESS,
  TOGGLE_PAGE_IN_PUBLICATION_FAILURE,
  TOGGLE_PAGE_IN_ANALYTICS,
  TOGGLE_PAGE_IN_ANALYTICS_SUCCESS,
  TOGGLE_PAGE_IN_ANALYTICS_FAILURE,
  TOGGLE_ACCOUNT_STATUS,
  TOGGLE_ACCOUNT_STATUS_FAILURE,
  TOGGLE_PAGE_STATUS,
  TOGGLE_PAGE_STATUS_FAILURE,
  SET_ACTIVE_SOCIAL_TYPE,
  CLEAR_ACCOUNTS_ERROR_STATE,
  ACTIVATE_ALL_PAGES_IN_PUBLICATION,
  ACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS,
  ACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE,
  DEACTIVATE_ALL_PAGES_IN_PUBLICATION,
  DEACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS,
  DEACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE,
  ACTIVATE_ALL_PAGES_IN_ANALYTICS,
  ACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS,
  ACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE,
  DEACTIVATE_ALL_PAGES_IN_ANALYTICS,
  DEACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS,
  DEACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE,
  CHECK_PAGE_FOR_SCHEDULE_POSTS,
  SET_ERROR_ACCOUNTS_STATE,
  CLEAR_ACCOUNTS_REDUX_STATE,
  SHOW_ACCOUNT_ERROR_CONNECT,
  UPDATE_PAGE,
  SYNC_ACCOUNT,
  SYNC_ACCOUNT_SUCCESS,
  SYNC_ACCOUNT_FAILURE
} from '../../actions/account';

import {
  GET_ACTIVE_ACCOUNTS,
  GET_ACTIVE_ACCOUNTS_SUCCESS,
  GET_ACTIVE_ACCOUNTS_FAILURE,
  SET_ACTIVE_PAYLOAD,
  GET_ACTIVE_PAGES,
  GET_ACTIVE_PAGES_SUCCESS,
  GET_ACTIVE_PAGES_FAILURE
} from '../../actions/account/getActiveAccountsAndPages';

import {
  CONNECT_TO_SOCIAL_NETWORK,
  CONNECT_TO_SOCIAL_NETWORK_SUCCESS,
  CONNECT_TO_SOCIAL_NETWORK_FAILURE
} from '../../actions/account/authorizationToSocialNetwork';

import {
  GET_ACCOUNT,
  GET_PAGE,
  REMOVE_PAGE
} from '../../actions/account/getAccountsAndPages';
import { getAccount, getPage, removePage } from './getAccountsAndPages';

import baseReducerHandler from 'util/baseReducerHandler';

import { getAccountsSuccess } from './getAccounts';
import { setActivePayload } from './getActiveAccounts';
import { toggleAccountInPublicationSuccess } from './toggleAccountInPublication';
import {
  togglePageInPublicationSuccess,
  activateAllPagesInPublicationSuccess,
  deactivateAllPagesInPublicationSuccess
} from './togglePageInPublication';
import { toggleAccountInAnalyticsSuccess } from './toggleAccountInAnalytics';
import {
  togglePageInAnalyticsSuccess,
  activateAllPagesInAnalyticsSuccess,
  deactivateAllPagesInAnalyticsSuccess
} from './togglePageInAnalytics';
import { toggleAccountStatusSuccess } from './toggleAccountStatus';
import { togglePageStatusSuccess } from './togglePageStatus';
import { removeAccount } from './removeAccount';
import toggleHasScheduledPosts from './toggleHasScheduledPosts';
import { updateAccount } from './updateAccount';
import { updatePage } from './updatePage';

const initialState = {
  payload: [],
  activePayload: [],
  socialTypes: [],
  activeSocialType: '',
  loading: false,
  error: null,
  togglePagesLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACCOUNT:
      return baseReducerHandler(state, true, null);
    case ADD_ACCOUNT_SUCCESS:
    case ADD_ACCOUNT_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case GET_ACCOUNT:
      return getAccount(state, action);
    case GET_PAGE:
      return getPage(state, action);

    case UPDATE_PAGE:
      return updatePage(state, action);
    case REMOVE_PAGE:
      return removePage(state, action);

    case CONNECT_TO_SOCIAL_NETWORK:
      return baseReducerHandler(state, true, null);
    case CONNECT_TO_SOCIAL_NETWORK_SUCCESS:
    case CONNECT_TO_SOCIAL_NETWORK_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case GET_ACCOUNTS:
      return baseReducerHandler(state, true, null);
    case GET_ACCOUNTS_SUCCESS:
      return getAccountsSuccess(state, action);
    case GET_ACCOUNTS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case GET_ACTIVE_ACCOUNTS:
      return baseReducerHandler(state, true, null);

    case GET_ACTIVE_ACCOUNTS_SUCCESS:
    case GET_ACTIVE_ACCOUNTS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SET_ACTIVE_PAYLOAD:
      return setActivePayload(state, action);

    case GET_ACTIVE_PAGES:
    case GET_ACTIVE_PAGES_SUCCESS:
      //  because after GET_ACTIVE_PAGES_SUCCESS app loading active pages
      return baseReducerHandler(state, true, null);

    case GET_ACTIVE_PAGES_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case UPDATE_ACCOUNT:
      return updateAccount(state, action);
    case REMOVE_ACCOUNT:
      return removeAccount(state, action);
    case REMOVE_ACCOUNT_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case TOGGLE_ACCOUNT_IN_PUBLICATION:
      return baseReducerHandler(state, false, null);
    case TOGGLE_ACCOUNT_IN_PUBLICATION_SUCCESS:
      return toggleAccountInPublicationSuccess(state, action);
    case TOGGLE_ACCOUNT_IN_PUBLICATION_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case TOGGLE_PAGE_IN_PUBLICATION:
      return baseReducerHandler(state, false, null);
    case TOGGLE_PAGE_IN_PUBLICATION_SUCCESS:
      return togglePageInPublicationSuccess(state, action);
    case TOGGLE_PAGE_IN_PUBLICATION_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SET_ACTIVE_SOCIAL_TYPE:
      return { ...state, activeSocialType: action.name };

    case ACTIVATE_ALL_PAGES_IN_PUBLICATION:
      return baseReducerHandler({ ...state, togglePagesLoading: true }, false, null);

    case ACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS:
      return activateAllPagesInPublicationSuccess(state, action);
    case ACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE:
      return baseReducerHandler({ ...state, togglePagesLoading: false }, false, null);

    case DEACTIVATE_ALL_PAGES_IN_PUBLICATION:
      return baseReducerHandler({ ...state, togglePagesLoading: true }, false, null);

    case DEACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS:
      return deactivateAllPagesInPublicationSuccess(state, action);
    case DEACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE:
      return baseReducerHandler({ ...state, togglePagesLoading: false }, false, null);

    case ACTIVATE_ALL_PAGES_IN_ANALYTICS:
      return baseReducerHandler({ ...state, togglePagesLoading: true }, false, null);
    case ACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS:
      return activateAllPagesInAnalyticsSuccess(state, action);
    case ACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE:
      return baseReducerHandler({ ...state, togglePagesLoading: false }, false, null);

    case DEACTIVATE_ALL_PAGES_IN_ANALYTICS:
      return baseReducerHandler({ ...state, togglePagesLoading: true }, false, null);
    case DEACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS:
      return deactivateAllPagesInAnalyticsSuccess(state, action);
    case DEACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE:
      return baseReducerHandler({ ...state, togglePagesLoading: false }, false, null);

    case TOGGLE_ACCOUNT_IN_ANALYTICS:
      return baseReducerHandler(state, false, null);
    case TOGGLE_ACCOUNT_IN_ANALYTICS_SUCCESS:
      return toggleAccountInAnalyticsSuccess(state, action);
    case TOGGLE_ACCOUNT_IN_ANALYTICS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case TOGGLE_PAGE_IN_ANALYTICS:
      return baseReducerHandler(state, false, null);
    case TOGGLE_PAGE_IN_ANALYTICS_SUCCESS:
      return togglePageInAnalyticsSuccess(state, action);
    case TOGGLE_PAGE_IN_ANALYTICS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case TOGGLE_ACCOUNT_STATUS:
      return toggleAccountStatusSuccess(state, action);
    case TOGGLE_ACCOUNT_STATUS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case TOGGLE_PAGE_STATUS:
      return togglePageStatusSuccess(state, action);
    case TOGGLE_PAGE_STATUS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case CHECK_PAGE_FOR_SCHEDULE_POSTS:
      return toggleHasScheduledPosts(state, action);

    case SHOW_ACCOUNT_ERROR_CONNECT:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SYNC_ACCOUNT:
      return baseReducerHandler(state, true, null);
    case SYNC_ACCOUNT_SUCCESS:
    case SYNC_ACCOUNT_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SET_ERROR_ACCOUNTS_STATE:
      return baseReducerHandler(state, false, action.error);

    case CLEAR_ACCOUNTS_ERROR_STATE:
      return baseReducerHandler(state, false, null);

    case CLEAR_ACCOUNTS_REDUX_STATE:
      return baseReducerHandler(initialState, false, null);

    default:
      return state;
  }
};
