import _showTourByModalIfNeed from './_showTourByModalIfNeed';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const modalTypes = {
  SUCCESS_REGISTER_INFO: 'SUCCESS_REGISTER_INFO',
  RESTORE_PASSWORD_INIT: 'RESTORE_PASSWORD_INIT',
  RESTORE_PASSWORD_FINISH: 'RESTORE_PASSWORD_FINISH',
  NEW_CONTENT: 'NEW_CONTENT',
  READY: 'READY',
  NEW_COLLECTION: 'NEW_COLLECTION',
  NEW_TEMPLATE: 'NEW_TEMPLATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE',
  NEW_PLAYLIST: 'NEW_PLAYLIST',
  CREATE_AUTOPILOT: 'CREATE_AUTOPILOT',
  AUTOPILOT_MODAL: 'AUTOPILOT_MODAL',
  TEMPLATE_MODAL: 'TEMPLATE_MODAL',
  NEW_SOCIAL: 'NEW_SOCIAL',
  NEW_STORE: 'NEW_STORE',
  CONNECT_STORE: 'CONNECT_STORE',
  ATTENTION_REMOVE: 'ATTENTION_REMOVE',
  POST_PREVIEW: 'POST_PREVIEW',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  REMOVE_STORE: 'REMOVE_STORE',
  INFO: 'INFO',
  POST_MODAL: 'POST_MODAL',
  ALERT: 'ALERT',
  ADD_COMPETITOR: 'ADD_COMPETITOR',
  RATE_BY_STARS: 'RATE_BY_STARS',
  FEEDBACK: 'FEEDBACK',
  FEEDBACK_GRATITUDE: 'FEEDBACK_GRATITUDE',
  FREE_PLAN_EXTINCTION: 'FREE_PLAN_EXTINCTION',
  UPGRADE_PLAN: 'UPGRADE_PLAN',
  UPGRADE_PLAN_SUCCESS: 'UPGRADE_PLAN_SUCCESS',
  UPGRADE_PLAN_SELECT_STORE: 'UPGRADE_PLAN_SELECT_STORE',
  UPLOAD_MEDIA: 'UPLOAD_MEDIA',
  VIEW_MEDIA: 'VIEW_MEDIA',
  PROMO: 'PROMO',
  CLOUD_SELECT: 'CLOUD_SELECT',
  ACL_ADD_USER: 'ACL_ADD_USER',
  ADD_COMPANY: 'ADD_COMPANY',
  ADD_MEDIA_TAGS: 'ADD_MEDIA_TAGS',
  CHANGE_AVATAR: 'CHANGE_AVATAR'
};

export function showModal(modalType, data, withIgnoreModals) {
  _showTourByModalIfNeed(modalType);
  return {
    type: SHOW_MODAL,
    data,
    modalType,
    withIgnoreModals
  };
}

export function hideModal(modalType, data, withIgnoreModals) {
  return {
    type: HIDE_MODAL,
    modalType,
    data,
    withIgnoreModals
  };
}
