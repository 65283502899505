import axios from 'axios';

export const SCHEDULE_DEMO = 'SCHEDULE_DEMO';
export const SCHEDULE_DEMO_SUCCESS = 'SCHEDULE_DEMO_SUCCESS';
export const SCHEDULE_DEMO_FAILURE = 'SCHEDULE_DEMO_FAILURE';

export const HANDLE_SCHEDULE_DEMO_FIELD = 'HANDLE_SCHEDULE_DEMO_FIELD';
export const HANDLE_SCHEDULE_DEMO_SELECT = 'HANDLE_SCHEDULE_DEMO_SELECT';
export const CLEAR_SCHEDULE_DEMO_ERROR_STATE = 'CLEAR_SCHEDULE_DEMO_ERROR_STATE';

export function scheduleDemoAction(data) {
  return dispatch => {
    dispatch({ type: SCHEDULE_DEMO });
    return axios
      .post(`/api/demos`, data)
      .then(res => {
        if (res.status === 201) {
          dispatch({ type: SCHEDULE_DEMO_SUCCESS });
          return Promise.resolve(res);
        } else if (res.status === 400 || res.status === 500) {
          dispatch({
            type: SCHEDULE_DEMO_FAILURE,
            error: res.data.title,
            reqStatus: res.status,
          });
        }
      })
      .catch(error => {
        dispatch({
          type: SCHEDULE_DEMO_FAILURE,
          error: error.response.data.title,
          reqStatus: error.response.status,
        });
        return Promise.resolve(error);
      });
  };
}

export function handleField(event) {
  return {
    type: HANDLE_SCHEDULE_DEMO_FIELD,
    name: event.target.name,
    value: event.target.value,
  };
}

export function handleSelect(name, value) {
  return {
    type: HANDLE_SCHEDULE_DEMO_SELECT,
    name,
    value,
  };
}

export function clearScheduleDemoErrorState() {
  return { type: CLEAR_SCHEDULE_DEMO_ERROR_STATE };
}
