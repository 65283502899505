export default product => {
  if (!product) {
    return null;
  }

  if (product?.version === 'v2') {
    return returnObjForOptionProductV2(product);
  } else {
    return returnObjForOptionProductV1(product);
  }
};

export const returnObjForOptionProductV1 = product => {
  const label = product.sku ? `${product.name} - ${product.sku}` : product.name;

  return {
    id: product.id,
    shop: product.shop,
    name: product.name,
    images: product.images,
    url: product.link,
    description: product.descriptionLong,
    descriptionHtml: product.descriptionHtml,
    hashtags: product.tags,
    sku: product.sku,
    brand: product.brand,
    price: product.priceRegular,
    priceRegularWithCurrency: product.priceRegularMoneyFormat,
    priceSale: product.priceSale,
    priceSaleWithCurrency: product.priceSaleMoneyFormat,
    // IsRequered for plugin react-select
    value: product.id,
    label
  }
};

export const returnObjForOptionProductV2 = product => {
  const label = product.sku ? `${product.name} - ${product.sku}` : product.name;

  return {
    id: product.id,
    images: product.images,
    url: product.link,
    sku: product.sku,
    brand: product.brand,
    name: product.name,
    hashtags: product.tags,
    'min price': product.priceRange.min,
    description: product.description,
    descriptionHtml: product.descriptionHtml,
    shop: product.shop,
    // IsRequered for plugin react-select
    value: product.id,
    label
  }
};

export const returnObjForFilterOptionProduct = category => {
  if (!category) {
    return null;
  }

  if (category?.version === 'v2') {
    return returnObjForFilterOptionProductV2(category);
  } else {
    return returnObjForFilterOptionProductV1(category);
  }
};

export const returnObjForFilterOptionProductV1 = category => {
  if (typeof category === 'object') {
    return {
      ...category,
      value: category.id,
      label: category.name,
      extraData: category?.shop?.name
    }
  } else {
    return {
      ...category,
      id: category,
      value: category,
      label: category
    }
  }
};

export const returnObjForFilterOptionProductV2 = category => {
  if (typeof category === 'object') {
    return {
      ...category,
      value: category.id,
      label: category.name,
      extraData: category?.shop?.name
    }
  } else {
    return {
      ...category,
      id: category,
      value: category,
      label: category
    }
  }

};
