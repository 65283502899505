import {
  PREPARE_PROFILE_FOR_EDIT,
  PREPARE_PROFILE_FOR_EDIT_SUCCESS,
  PREPARE_PROFILE_FOR_EDIT_FAILURE,
  HANDLE_PROFILE_FIELD,
  CANCEL_EDIT_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  CLEAR_PROFILE_ERROR_STATE,
  CHANGE_SUBSCRIBE, CHANGE_SUBSCRIBE_SUCCESS, CHANGE_SUBSCRIBE_FAILURE
} from '../../actions/auth/editProfile';
import { prepare } from '../editProfile/prepare';
import { handleField } from '../editProfile/handle';

const initialState = {
  userId: null,
  loading: false,
  error: null,
  firstName: '',
  lastName: '',
  website: '',
  phone: '',
  email: '',
  oldEmail: '',
  status: 'ACTIVE',
  subscribed: false,
  loadingSubscribed: false,
  formErrors: {
    firstName: '',
    lastName: '',
    website: '',
    phone: '',
    email: ''
  },
  formValid: false,
  firstNameValid: false,
  lastNameValid: false,
  websiteValid: false,
  phoneValid: false,
  emailValid: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PREPARE_PROFILE_FOR_EDIT:
      return Object.assign({}, state, { loading: action.loading, error: action.error });

    case PREPARE_PROFILE_FOR_EDIT_SUCCESS:
      return prepare(state, action);

    case PREPARE_PROFILE_FOR_EDIT_FAILURE:
      return Object.assign({}, state, { loading: action.loading, error: action.error });

    case HANDLE_PROFILE_FIELD:
      return handleField(state, action);

    case CANCEL_EDIT_PROFILE:
      return Object.assign({}, initialState);

    case UPDATE_PROFILE:
    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PROFILE_FAILURE:
      return Object.assign({}, state, { loading: action.loading, error: action.error });

    case CHANGE_SUBSCRIBE:
    case CHANGE_SUBSCRIBE_FAILURE:
      return Object.assign({}, state, { loadingSubscribed: action.loading, error: action.error });

    case CHANGE_SUBSCRIBE_SUCCESS:
      return Object.assign({}, state,
        {
          subscribed: action.subscribed,
          loadingSubscribed: action.loading,
          error: action.error
        });


    case CLEAR_PROFILE_ERROR_STATE:
      return Object.assign({}, state, {
        loading: action.loading,
        error: action.error
      });

    default:
      return state;
  }
};
