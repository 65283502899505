import axios from 'axios';
import AuthService from 'util/authService';

export const GET_ACL_COMPANIES_PENDING = 'GET_ACL_COMPANIES_PENDING';
export const GET_ACL_COMPANIES_SUCCESS = 'GET_ACL_COMPANIES_SUCCESS';
export const GET_ACL_COMPANIES_FAIL = 'GET_ACL_COMPANIES_FAIL';
export const CLEAR_ACL_COMPANIES_ERROR = 'CLEAR_ACL_COMPANIES_ERROR';

export const GET_ACL_TEAM_PENDING = 'GET_ACL_TEAM_PENDING';
export const GET_ACL_TEAM_SUCCESS = 'GET_ACL_TEAM_SUCCESS';
export const GET_ACL_TEAM_FAIL = 'GET_ACL_TEAM_FAIL';
export const CLEAR_ACL_TEAM_ERROR = 'CLEAR_ACL_TEAM_ERROR';

export const UPDATE_ACL_ITEM = 'UPDATE_ACL_ITEM';
export const DELETE_ACL_ITEM = 'DELETE_ACL_ITEM';

export const UPDATE_ACL_ROLES = 'UPDATE_ACL_ROLES';

export const GET_ACL_ROLES_PENDING = 'GET_ACL_ROLES_PENDING';
export const GET_ACL_ROLES_SUCCESS = 'GET_ACL_ROLES_SUCCESS';
export const GET_ACL_ROLES_FAIL = 'GET_ACL_ROLES_FAIL';

export const PROCESS_ACL_INVITE_PENDING = 'PROCESS_ACL_INVITE_PENDING';
export const PROCESS_ACL_INVITE_SUCCESS = 'PROCESS_ACL_INVITE_SUCCESS';
export const PROCESS_ACL_INVITE_FAIL = 'PROCESS_ACL_INVITE_FAIL';

export const CHANGE_ACL_ROLE_PENDING = 'CHANGE_ACL_ROLE_PENDING';
export const CHANGE_ACL_ROLE_SUCCESS = 'CHANGE_ACL_ROLE_SUCCESS';
export const CHANGE_ACL_ROLE_FAIL = 'CHANGE_ACL_ROLE_FAIL';

export const SET_NEW_ACL = 'SET_NEW_ACL';
export const SET_NEW_ACL_SUCCESS = 'SET_NEW_ACL_SUCCESS';
export const SET_NEW_ACL_FAIL = 'SET_NEW_ACL_FAIL';

const Auth = new AuthService();

export function getAclCompaniesAction() {
  return async dispatch => {
    try {
      dispatch({ type: GET_ACL_COMPANIES_PENDING });

      const res = await axios.get('/api/team/acls', {
        params: {
          offset: 0,
          size: 1000
        }
      });

      dispatch({
        type: GET_ACL_COMPANIES_SUCCESS,
        payload: {
          data: res.data,
          count: res.headers['x-total-count'] || 0
        }
      });
    } catch (error) {
      dispatch({
        type: GET_ACL_COMPANIES_FAIL,
        payload: {
          error: error.response?.data?.message || error.message
        }
      });
    }
  };
}

export function clearAclCompaniesErrorAction() {
  return {
    type: CLEAR_ACL_COMPANIES_ERROR
  };
}

export function getAclTeamAction() {
  return async dispatch => {
    try {
      dispatch({ type: GET_ACL_TEAM_PENDING });

      const res = await axios.get('/api/team', {
        params: {
          offset: 0,
          size: 1000
        }
      });

      dispatch({
        type: GET_ACL_TEAM_SUCCESS,
        payload: {
          data: res.data,
          count: res.headers['x-total-count'] || 0
        }
      });
    } catch (error) {
      dispatch({
        type: GET_ACL_TEAM_FAIL,
        payload: {
          error: error.response?.data?.message || error.message
        }
      });
    }
  };
}

export function clearAclTeamErrorAction() {
  return {
    type: CLEAR_ACL_TEAM_ERROR
  };
}

export function deleteAclItemAction(listName, id, ind, onSuccess) {
  return async dispatch => {
    function getApiUrl() {
      if (listName === 'companies') {
        return `/api/team/${id}/invitation`;
      } else if (listName === 'team') {
        return `/api/team/user/${id}`;
      }
    }

    try {
      dispatch(updateAclItemAction(listName, ind, { isDeleting: true }));

      await axios.delete(getApiUrl());

      dispatch({
        type: DELETE_ACL_ITEM,
        payload: {
          listName,
          elementInd: ind
        }
      });
      onSuccess();
    } catch (error) {
      dispatch(
        updateAclItemAction(listName, ind, {
          isDeleting: false,
          error: error.response?.data?.message || error.message
        })
      );
    }
  };
}

export function setEditingModeAction(listName, ind, isEditing) {
  return async dispatch => {
    dispatch(
      updateAclItemAction(listName, ind, {
        isEditing: isEditing
      })
    );
  }
}

export function updateAclItemAction(listName, ind, nextProps) {
  return {
    type: UPDATE_ACL_ITEM,
    payload: {
      elementInd: ind,
      listName,
      nextProps
    }
  };
}

export function getAclRolesAction(type) {
  return async dispatch => {
    dispatch({
      type: GET_ACL_ROLES_PENDING,
      payload: { type, nextProps: { loading: true } }
    });

    try {
      const res = await axios.get(`/api/team/acl/roles?type=${type}`);

      dispatch({
        type: GET_ACL_ROLES_SUCCESS,
        payload: {
          type,
          nextProps: { list: res.data, loading: false, didFirstLoad: true }
        }
      });
    } catch (error) {
      dispatch({
        type: GET_ACL_ROLES_FAIL,
        payload: {
          type,
          nextProps: { error: error.response?.data?.message || error.message, didFirstLoad: true }
        }
      });
    }
  };
}

export function proccessAclInviteAction(type, companyId, elementInd) {
  return async dispatch => {
    dispatch({
      type: PROCESS_ACL_INVITE_PENDING,
      payload: { elementInd, nextProps: { isInviteProccessing: true } }
    });

    try {
      const res = await axios.post(`/api/team/${companyId}/invitation/${type}`);

      dispatch({
        type: PROCESS_ACL_INVITE_SUCCESS,
        payload: {
          elementInd,
          nextProps: res.data
        }
      });
    } catch (error) {
      dispatch({
        type: PROCESS_ACL_INVITE_FAIL,
        payload: {
          elementInd,
          nextProps: {
            error: error.response?.data?.message || error.message,
            isInviteProccessing: false
          }
        }
      });
    }
  };
}

export function changeAclRoleAction(userId, role, elementInd) {
  return async dispatch => {
    dispatch({
      type: CHANGE_ACL_ROLE_PENDING,
      payload: { elementInd, nextProps: { isChangingRole: true } }
    });

    try {
      await axios.put(`/api/team/user/${userId}/role?newRole=${role.value}`);

      dispatch({
        type: CHANGE_ACL_ROLE_SUCCESS,
        payload: {
          elementInd,
          role
        }
      });
    } catch (error) {
      dispatch({
        type: CHANGE_ACL_ROLE_FAIL,
        payload: {
          elementInd,
          nextProps: {
            error: error.response?.data?.message || error.message,
            isChangingRole: false
          }
        }
      });
    }
  };
}

export function setNewAcl(id) {
  return dispatch => {
    return axios
      .post(`/api/account/company/${id}`, {})
      .then(res => {
        const dataForCopmpanySuccess = res.data;
        return Auth.changeToken().then(res => {
          dispatch({ type: SET_NEW_ACL_SUCCESS, payload: {
            currentCompany: dataForCopmpanySuccess
          }});
          return Promise.resolve(res);
        });
      })
      .catch(error => {
        return Promise.resolve(error);
      });
  };
}
