export function removeStore(state, action) {
  return Object.assign({}, state, {
    loading: true,
    error: null,
    payload: state.payload.filter(storeItem => storeItem.id !== action.id),
  });
}

export function removeStoreSuccess(state, action) {
  return Object.assign({}, state, {
    loading: false,
    error: null,
    payload: state.payload?.filter(s => s.id !== action.payload),
  });
}
