import {
  PREPARE_TEMPLATE_FOR_EDIT,
  PREPARE_TEMPLATE_FOR_EDIT_SUCCESS,
  PREPARE_TEMPLATE_FOR_EDIT_FAILURE,
  UPDATE_TEMPLATE,
  UPDATE_TEMPLATE_SUCCESS,
  UPDATE_TEMPLATE_FAILURE,
  CANCEL_EDIT_TEMPLATE,
  HANDLE_TEMPLATE_FIELD,
  HANDLE_TEMPLATE_SELECT,
  HANDLE_TEMPLATE_SOCIAL,
  HANDLE_TEMPLATE_SOCIAL_ACCOUNTS,
  HANDLE_TEMPLATE_SOCIAL_PAGES,
  HANDLE_TEMPLATE_TYPE,
  HANDLE_TEMPLATE_TYPE_ADDITIONAL,
  HANDLE_TEMPLATE_TAG,
} from "../../actions/template/editTemplate";
import {prepare} from './prepare';
import {handleField, handleSelect, handleSocial, handleSocialAccounts, handleSocialPages, handleType, handleTag, handleTypeAdditional} from './handle';
import baseReducerHandler from 'util/baseReducerHandler'

const initialState  = {
  id: "",
  name: "",
  status: null,
  type: null,
  typeAdditional: [],
  stock: [],
  features: [],
  socialType: null,
  socialAccounts: [],
  socialPages: [],
  template: "",

  nameValid: false,
  statusValid: false,
  typeValid: false,
  typeAdditionalValid: false,
  stockValid: false,
  featuresValid: false,
  socialTypeValid: false,
  socialAccountsValid: false,
  socialPagesValid: false,
  templateValid: false,
  formValid: false,

  formErrors: {
    name: "",
    status: "",
    type: "",
    typeAdditional: "",
    stock: "",
    features: "",
    socialType: "",
    socialAccounts: "",
    socialPages: "",
    template: "",
  },

  typeSelectData: {
    disabled: true,
    isMulti: false,
    placeholder: "First choose social type",
    name: "type",
    options: [],
  },

  typeAdditionalData: {
    disabled: true,
    isMulti: false,
    placeholder: "First choose type",
    name: "",
    options: [],
  },

  stockAdditionalData: {
    options: [],
  },

  featuresAdditionalData: {
    options: [],
  },

  socialAccountsAdditionalData: {
    disabled: true,
    isMulti: true,
    placeholder: "First choose social media type",
    name: "socialAccounts",
    options: [
      {
        key: 666777,
        value: 666777,
        label: "Select all accounts",
      },
    ],
  },

  socialPagesAdditionalData: {
    name: "socialPages",
    placeholder: "First choose social accounts",
    disabled: true,
    isMulti: true,
    options: [
      {
        key: 666777,
        value: 666777,
        label: "Select all pages",
      },
    ],
    value: [],
  },

  tags: [],

  facebookPagesFromApi: [],
  loading: false,
  error: null,
  sendData: false
};

export default (state = initialState, action) => {
  switch (action.type) {

    case PREPARE_TEMPLATE_FOR_EDIT:
      return baseReducerHandler(state, true, null);

    case PREPARE_TEMPLATE_FOR_EDIT_SUCCESS:
      return prepare(state, action);

    case PREPARE_TEMPLATE_FOR_EDIT_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case UPDATE_TEMPLATE:
      return baseReducerHandler({...state, sendData: true}, true, null);
    case UPDATE_TEMPLATE_SUCCESS:
    case UPDATE_TEMPLATE_FAILURE:
      return baseReducerHandler({...state, sendData: false}, false, action.error ? action.error : null);

    case CANCEL_EDIT_TEMPLATE:
      return baseReducerHandler(initialState, false, null);

    case HANDLE_TEMPLATE_FIELD:
      return handleField(state, action);

    case HANDLE_TEMPLATE_SELECT:
      return handleSelect(state, action);

    case HANDLE_TEMPLATE_SOCIAL:
      return handleSocial(state, action);

    case HANDLE_TEMPLATE_SOCIAL_ACCOUNTS:
      return handleSocialAccounts(state, action);

    case HANDLE_TEMPLATE_SOCIAL_PAGES:
      return handleSocialPages(state, action);

    case HANDLE_TEMPLATE_TYPE:
      return handleType(state, action);

    case HANDLE_TEMPLATE_TYPE_ADDITIONAL:
      return handleTypeAdditional(state, action);

    case HANDLE_TEMPLATE_TAG:
      return handleTag(state, action);

    default:
      return state;
  }
};
