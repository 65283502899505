import {
  POST_NOW,
  POST_NOW_SUCCESS,
  POST_NOW_FAILURE,
  SCHEDULE_NEW_POST,
  SCHEDULE_NEW_POST_SUCCESS,
  SCHEDULE_NEW_POST_FAILURE,
  PUT_POST,
  PUT_POST_SUCCESS,
  PUT_POST_FAILURE,
  CLEAR_NEW_POST_ERROR_STATE,
} from 'store/actions/post/post';
import baseReducerHandler from 'util/baseReducerHandler';

const initialState = {
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_NOW:
      return baseReducerHandler(state, true, null);
    case POST_NOW_SUCCESS:
    case POST_NOW_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SCHEDULE_NEW_POST:
    case PUT_POST:
      return baseReducerHandler(state, true, null);
    case SCHEDULE_NEW_POST_SUCCESS:
    case SCHEDULE_NEW_POST_FAILURE:
    case PUT_POST_SUCCESS:
    case PUT_POST_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case CLEAR_NEW_POST_ERROR_STATE:
      return baseReducerHandler(state, true, action.error ? action.error : null);

    default:
      return state;
  }
};
