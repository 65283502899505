import {
  SET_URL_SHORTENER_ACCOUNT,
  REMOVE_URL_SHORTENER_ACCOUNT,
  TOGGLE_SHORTENER_ACCOUNT_STATUS,
  TOGGLE_SHORTENER_DOMAIN_STATUS,
  SET_ERROR_SHORTENER_ACCOUNTS,
  CLEAR_ERROR_SHORTENER_ACCOUNTS
} from 'store/actions/urlShortenerAccounts';
import { setUrlShortenerAccount } from './helpers/setUrlShortenerAccount';
import { toggleStatusUrlShortener } from './helpers/toggleStatusUrlShortener';

const initialState = {
  payload: [],
  error: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_URL_SHORTENER_ACCOUNT:
      return setUrlShortenerAccount(state, action);

    case REMOVE_URL_SHORTENER_ACCOUNT:
      return { ...state, payload: state.payload.filter(account => account.id !== action.payload.id)}; // prettier-ignore

    case TOGGLE_SHORTENER_ACCOUNT_STATUS:
      return toggleStatusUrlShortener(state, action, 'account');

    case TOGGLE_SHORTENER_DOMAIN_STATUS:
      return toggleStatusUrlShortener(state, action, 'domain');

    case SET_ERROR_SHORTENER_ACCOUNTS:
      return { ...state, error: action.error };

    case CLEAR_ERROR_SHORTENER_ACCOUNTS:
      return { ...state, error: undefined };

    default:
      return state;
  }
};
