import utcTimeToLocalTime from '../../../../util/utcTimeTolocalTime';
import { WEEK_REVERSE } from 'constants/autopilot';
import { typePostFromApi } from 'util/definePostType';
import { getSourceBrandFromObject } from '../../../../util/getInfoFromApiObjects';

export default (id, state, initialState) => {
  const activeAutopilot = state.autopilotList.find(autopilot => autopilot.id === id);

  let form = { ...state.form };

  if (activeAutopilot) {
    form.autopilotId = activeAutopilot.id;
    form.name = activeAutopilot.name;
    form.socialPages = _getSocialPages(
      activeAutopilot.socialAccounts,
      activeAutopilot.socialObjects
    );

    form.collections = activeAutopilot.categories ? activeAutopilot.categories : [];
    form.types = activeAutopilot.features || [];
    form.brands = activeAutopilot.brands ? activeAutopilot.brands.filter(brand => !!brand) : [];
    form.productFeatures = activeAutopilot.productFeatures ? activeAutopilot.productFeatures : [];
    form.stockLevels = activeAutopilot.stockLevels ? activeAutopilot.stockLevels : [];

    form.schedules = _getSchedules(activeAutopilot.schedules);
    form.mode = activeAutopilot.mode;
    form.typePost = typePostFromApi(activeAutopilot.type);
    form.sourceType = _getSourceType(activeAutopilot);
    form.sourceBrand = getSourceBrandFromObject(activeAutopilot);

    form.playlists = activeAutopilot.videoPlaylists ? activeAutopilot.videoPlaylists : [];
    form.advancedOptions = activeAutopilot.advancedOptions.map(opt => opt.id);
    form.mediaTags = activeAutopilot.mediaTags ? activeAutopilot.mediaTags : [];
    form.addParticipants = activeAutopilot.addParticipants ? activeAutopilot.addParticipants : [];
    form.selectedStoresIds = activeAutopilot.shopIds;

    form.urlShortenerParams = {
      domainId:
        activeAutopilot?.urlShortenerParams?.domain?.id || state.form.urlShortenerParams.domainId
    };
    form.shortContentLinks = !!activeAutopilot.urlShortenerParams;
    /*    form.templates = activeAutopilot.templates.map(template => ({
          albumNameTemplate: template.albumNameTemplate,
          contentTemplate: template.contentTemplate,
          imageContentTemplate: template.imageContentTemplate
        }));*/

    form.templates = activeAutopilot.templates || [
      {
        albumNameTemplate: '',
        contentTemplate: '',
        contentHtmlTemplate: '',
        imageContentTemplate: ''
      }
    ];
  }

  return {
    ...state,
    form,
    view: initialState.view
  };
};

const _getSocialPages = (accounts, objects) => {
  let socialPages = [];
  [...accounts, ...objects].map(object => {
    const socialType = object.socialNetworkType || object.type;
    const findedIndexSocialType = socialPages.findIndex(
      socialPage => socialPage.socialType === socialType
    );
    if (findedIndexSocialType + 1) {
      socialPages[findedIndexSocialType].socialObjects.push(object);
    } else {
      socialPages.push({
        socialType: socialType,
        socialObjects: [object],
        default: []
      });
    }
    return object;
  });

  return socialPages.length
    ? socialPages
    : [
        {
          socialType: '',
          socialObjects: [],
          default: []
        }
      ];
};

const _getSchedules = schedules => {
  let schedulesToForm = [];

  schedules.map(item => {
    if (item.status === 'ACTIVE') {
      const schedule = utcTimeToLocalTime(item.dayOfWeek, item.time);
      const findedIndexTime = schedulesToForm.findIndex(s => s.time === schedule.time);
      if (findedIndexTime + 1) {
        schedulesToForm[findedIndexTime].dayOfWeek.push(WEEK_REVERSE[schedule.dayOfWeek]);
      } else {
        schedulesToForm.push({
          dayOfWeek: [WEEK_REVERSE[schedule.dayOfWeek]],
          time: schedule.time
        });
      }
    }

    return item;
  });

  return schedulesToForm.length
    ? schedulesToForm
    : [
        {
          dayOfWeek: [],
          time: '12:00'
        }
      ];
};

const _getSourceType = autopilot => {
  if (autopilot && autopilot.resourceType) {
    return autopilot.resourceType === 'NONE' ? '' : autopilot.resourceType;
  } else if (
    (autopilot.features && autopilot.features.length) ||
    (autopilot.categories && autopilot.categories.length) ||
    (autopilot.brands && autopilot.brands.length)
  ) {
    return 'PRODUCT';
  } else if (autopilot.playlists && autopilot.playlists.length) {
    return 'VIDEO';
  } else {
    return '';
  }
};
