export default (state, action) => {
  const newState = { ...state, images: [...state.images] };
  const { sourceIndex, destinationIndex } = action;
  const draggable = { ...newState.images[sourceIndex] };

  newState.images = newState.images.filter(img => img.id !== draggable.id);
  newState.images.splice(destinationIndex, 0, draggable);

  return newState;
};
