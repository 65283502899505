import axios from 'axios';

export const GET_ACTIVE_ACCOUNTS = 'GET_ACTIVE_ACCOUNTS';
export const GET_ACTIVE_ACCOUNTS_SUCCESS = 'GET_ACTIVE_ACCOUNTS_SUCCESS';
export const GET_ACTIVE_ACCOUNTS_FAILURE = 'GET_ACTIVE_ACCOUNTS_FAILURE';
export const SET_ACTIVE_PAYLOAD = 'SET_ACTIVE_PAYLOAD';
export const GET_ACTIVE_PAGES = 'GET_ACTIVE_PAGES';
export const GET_ACTIVE_PAGES_SUCCESS = 'GET_ACTIVE_PAGES_SUCCESS';
export const GET_ACTIVE_PAGES_FAILURE = 'GET_ACTIVE_PAGES_FAILURE';


export function getActiveAccountsAndPagesAction() {
  return dispatch => {
    dispatch({ type: GET_ACTIVE_ACCOUNTS });

    return axios
      .get(`/api/social-network-accounts/status/active`)
      .then(res => {
        dispatch({ type: GET_ACTIVE_ACCOUNTS_SUCCESS });

        //let activeAccounts = _getPayloadWithoutAccountWithPages(res.data);
        let activeAccounts = res.data;

        dispatch({ type: GET_ACTIVE_PAGES });

        axios
          .get(`/api/social-network-objects/status/active`)
          .then(objectsRes => {
            if (objectsRes.status === 200) {
              dispatch({ type: GET_ACTIVE_PAGES_SUCCESS });
              objectsRes.data.forEach(item => {
                activeAccounts = activeAccounts.concat(item);
              });
              dispatch(setActivePayload(activeAccounts));
              return Promise.resolve(activeAccounts);
            } else {
              dispatch({ type: GET_ACTIVE_PAGES_FAILURE, error: objectsRes });
              return Promise.resolve(objectsRes);
            }
          })
          .catch(error => {
            dispatch({ type: GET_ACTIVE_PAGES_FAILURE, error });
            return Promise.resolve(error);
          });
      })
      .catch(error => {
        dispatch({ type: GET_ACTIVE_ACCOUNTS_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

function setActivePayload(activePayload) {
  return {
    type: SET_ACTIVE_PAYLOAD,
    activePayload: activePayload,
    loading: false,
    error: null
  };
}

/*function _getPayloadWithoutAccountWithPages(accountsList) {
  return accountsList.filter(
    account => SOCIAL_TYPE_WITHOUT_PAGE.includes(account.type)
  );
}*/
