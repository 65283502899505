export function toggleAccountInPublicationSuccess(state, action) {
  let newPayload;

  //If we make an account inactive
  if (action.nowSelectedStatus) {
    newPayload = state.activePayload.map(account => {
      //We check whether the account is or not, because ID can match
      if (account.id === action.id && !account.socialNetworkType) {
        return {
          ...account,
          selectedInPublications: false
        };
      }
      else {
        return account;
      }
    });
  }
  //If we make an active account, then deactivate other accounts of the same social network
  else {
    newPayload = state.activePayload.map(account => {
      //We check whether the account is or not, because ID can match
      if (account.id === action.id && !account.socialNetworkType) {
        return {
          ...account,
          selectedInPublications: true
        };
      } else if (!account.socialNetworkType && account.type === action.socialType) {
        return {
          ...account,
          selectedInPublications: false
        };
      }
      else {
        return account;
      }
    });
  }

  return Object.assign({}, state, {
    activePayload: newPayload,
    loading: false,
    error: null
  });
}
