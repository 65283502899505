import {
  TL_CHANGE_NAME,
  TL_SET_COLLECTIONS,
  TL_SET_BRANDS,
  TL_SET_STOCK_LEVELS,
  TL_SET_FEATURES,
  TL_SET_TYPES,
  TL_SET_PLAYLISTS,
  TL_SET_SOCIAL_PAGES,
  TL_CHANGE_TEMPLATE,
  TL_SET_TYPE_POST,
  TL_SET_SOURCE_TYPE,
  TL_RESET_FORM,
  TL_SET_TEMPLATE_ID,
  TL_LOADING,
  TL_CLEAR_ERROR,
  TL_SET_OBJECT_SAMPLE,
  TL_SET_LOADING_SAMPLE,
  TL_SET_TEMPLATE_LIST,
  TL_ADD_TEMPLATE,
  TL_DELETE_TEMPLATE,
  TL_CHANGE_ACTIVE_TEMPLATE,
  TL_EDIT_TEMPLATE,
  TL_SET_MEDIA_TAGS
} from 'store/actions/templatesUser/template';

//Utils & Helpers
import validateTemplateForm from './helpers/validateTemplateForm';
import setTemplateId from './helpers/setTemplateId';

const initialState = {
  templateList: [],
  form: {
    templateId: null,
    name: '',
    socialPages: [{
      socialType: '',
      socialObjects: [],
      default: []
    }],
    types: [], //news, bestsellers, etc
    collections: [],
    brands: [],
    playlists: [], //array objects playlist
    typePost: 'PRODUCT_ALBUM', //PRODUCT_LINK
    productFeatures: [],
    addParticipants: [],
    mediaTags: [],
    stockLevels: [],
    sourceType: '', //PRODUCT, VIDEO, etc
    sourceBrand: '', //MAGENTO, SHOPIFY, etc
    template: {
      albumNameTemplate: '',
      contentTemplate: '',
      contentHtmlTemplate: '',
      imageContentTemplate: ''
    },
    validate: {
      nameValid: false,
      sourceTypeValid: false,
      albumNameValid: true,
      formValid: false,
      error: []
    }
  },
  view: {
    objectSample: null, //sample product, sample video
    isLoadingSample: false
  },
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {

    case TL_SET_TEMPLATE_ID:
      return validateTemplateForm(setTemplateId(action.id, state, initialState), initialState);

    case TL_CHANGE_NAME:
      return validateTemplateForm({
        ...state,
        form: {
          ...state.form,
          name: action.name
        }
      }, initialState);

    case TL_SET_SOCIAL_PAGES:
      return validateTemplateForm({
        ...state,
        form: {
          ...state.form,
          socialPages: action.socialPages
        }
      }, initialState);

    case TL_SET_COLLECTIONS:
      return {
        ...state,
        form: {
          ...state.form,
          collections: action.values
        }
      };

    case TL_SET_BRANDS:
      return {
        ...state,
        form: {
          ...state.form,
          brands: action.values
        }
      };

    case TL_SET_STOCK_LEVELS:
      return {
        ...state,
        form: {
          ...state.form,
          stockLevels: action.values
        }
      };

      case TL_SET_FEATURES:
        return {
          ...state,
          form: {
            ...state.form,
            productFeatures: action.values
          }
        };

    case TL_SET_TYPES:
      return {
        ...state,
        form: {
          ...state.form,
          types: action.values
        }
      };

    case TL_SET_PLAYLISTS:
      return {
        ...state,
        form: {
          ...state.form,
          playlists: action.playlists
        }
      };

    case TL_SET_MEDIA_TAGS:
      return {
        ...state,
        form: {
          ...state.form,
          mediaTags: action.mediaTags
        }
      };

    case TL_SET_TYPE_POST:
      return validateTemplateForm({
        ...state,
        form: {
          ...state.form,
          typePost: action.typePost
        }
      }, initialState);

    case TL_SET_SOURCE_TYPE:
      return validateTemplateForm({
        ...state,
        form: {
          ...state.form,
          sourceType: action.sourceType,
          sourceBrand: action.sourceBrand,
          typePost: action.sourceType === 'VIDEO' ? 'PRODUCT_LINK' : 'PRODUCT_ALBUM',
          collections: [],
          brands: [],
          types: [],
          playlists: []
        }
      }, initialState);

    case TL_CHANGE_TEMPLATE:
      return validateTemplateForm({
        ...state,
        form: {
          ...state.form,
          template: action.template
        }
      }, initialState);


    case TL_SET_OBJECT_SAMPLE:
      return {
        ...state,
        view: {
          ...state.view,
          objectSample: action.objectSample,
          isLoadingSample: false
        }
      };

    case TL_SET_LOADING_SAMPLE:
      return {
        ...state,
        view: {
          ...state.view,
          isLoadingSample: action.isLoadingSample
        }
      };

    case TL_RESET_FORM:
      return {
        ...state,
        form: {
          ...initialState.form
        },
        view: initialState.view
      };

    case TL_SET_TEMPLATE_LIST:
      return {
        ...state,
        templateList: action.templateList,
        loading: false
      };

    case TL_ADD_TEMPLATE:
      return {
        ...state,
        templateList: [...state.templateList, action.template],
        loading: false
      };

    case TL_DELETE_TEMPLATE:
      return {
        ...state,
        templateList: state.templateList.filter(template => template.id !== action.id),
        loading: false
      };

    case TL_CHANGE_ACTIVE_TEMPLATE:
      return {
        ...state,
        templateList:
          state.templateList.map((template) => (template.id !== action.id
            ? template : { ...template, status: action.template.status })),
        loading: false
      };

    case TL_EDIT_TEMPLATE:
      return {
        ...state,
        templateList:
          state.templateList.map((template) => (template.id !== action.id
            ? template : action.template)),
        loading: false
      };

    case TL_LOADING:
      return {
        ...state,
        loading: action.isLoading,
        error: action.error ? action.error : null
      };

    case TL_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
