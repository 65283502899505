// Packages
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Tour from 'reactour';
// Actions
import { clearTourAction } from 'store/actions/tour';
// Data constants
import { tourPostModalSteps } from './stepsData/postModal';
import { tourPublicationSteps } from './stepsData/publication';
import { tourSettingsSteps } from './stepsData/settings';

const mapStateToProps = state => ({
  hasConnectedStores: !!state.productList.payload?.length,
  tourVal: state.tour.val
});

const mapDispatchToProps = {
  clearTourAction
};

class TourProvider extends PureComponent {
  onExit = () => {
    document.body.style.overflowY = 'visible';
    this.props.clearTourAction();
  };

  getSteps() {
    const { tourVal, history, hasConnectedStores } = this.props;

    switch (tourVal) {
      case 'TOUR_BY_POST_MODAL':
        return tourPostModalSteps;

      case 'TOUR_BY_PUBLICATION':
        return tourPublicationSteps(hasConnectedStores);

      case 'TOUR_BY_SETTINGS':
        return tourSettingsSteps(history);

      default:
        return [];
    }
  }

  render() {
    const { tourVal } = this.props;
    const steps = this.getSteps();

    if (!tourVal || !steps.length) return null;

    return (
      <Tour
        onBeforeOpen={() => console.log('before open')}
        isOpen={true}
        steps={steps}
        onRequestClose={this.onExit}
        onAfterOpen={() => (document.body.style.overflowY = 'hidden')}
        prevButton={
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
              <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit="20"
                strokeWidth="2"
                d="M1.174 1.192a.625.625 0 01.883-.018l5.411 5.19c.16.152.22.37.181.572a.624.624 0 01-.18.572l-5.412 5.19a.625.625 0 01-.865-.903l5.068-4.86-5.068-4.86a.625.625 0 01-.018-.883z"
              />
            </svg>
            back
          </>
        }
        nextButton={
          <>
            next
            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
              <path
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeMiterlimit="20"
                strokeWidth="2"
                d="M1.174 1.192a.625.625 0 01.883-.018l5.411 5.19c.16.152.22.37.181.572a.624.624 0 01-.18.572l-5.412 5.19a.625.625 0 01-.865-.903l5.068-4.86-5.068-4.86a.625.625 0 01-.018-.883z"
              />
            </svg>
          </>
        }
        lastStepNextButton={<>done</>}
      />
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TourProvider)
);
