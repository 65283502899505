import axios from 'axios';

export const PREPARE_TEMPLATE_FOR_EDIT = 'PREPARE_TEMPLATE_FOR_EDIT';
export const PREPARE_TEMPLATE_FOR_EDIT_SUCCESS = 'PREPARE_TEMPLATE_FOR_EDIT_SUCCESS';
export const PREPARE_TEMPLATE_FOR_EDIT_FAILURE = 'PREPARE_TEMPLATE_FOR_EDIT_FAILURE';

export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';

export const CANCEL_EDIT_TEMPLATE = 'CANCEL_EDIT_TEMPLATE';

export const HANDLE_TEMPLATE_FIELD = 'HANDLE_TEMPLATE_FIELD';
export const HANDLE_TEMPLATE_SELECT = 'HANDLE_TEMPLATE_SELECT';
export const HANDLE_TEMPLATE_SOCIAL = 'HANDLE_TEMPLATE_SOCIAL';
export const HANDLE_TEMPLATE_SOCIAL_ACCOUNTS = 'HANDLE_TEMPLATE_SOCIAL_ACCOUNTS';
export const HANDLE_TEMPLATE_SOCIAL_PAGES = 'HANDLE_TEMPLATE_SOCIAL_PAGES';
export const HANDLE_TEMPLATE_TYPE = 'HANDLE_TEMPLATE_TYPE';
export const HANDLE_TEMPLATE_TYPE_ADDITIONAL = 'HANDLE_TEMPLATE_TYPE_ADDITIONAL';
export const HANDLE_TEMPLATE_TAG = 'HANDLE_TEMPLATE_TAG';

export function prepareTemplateToEditing(data, accountsList, categoriesList, topicsList) {
  return dispatch => {
    dispatch({
      type: PREPARE_TEMPLATE_FOR_EDIT});
    axios
      .all([getFacebookPages(), getStockLevels(), getProductFeatures()])
      .then(
        axios.spread(function(facebookPagesFromApi, stockLevels, productFeatures) {
          dispatch({
            type: PREPARE_TEMPLATE_FOR_EDIT_SUCCESS,
            data,
            accountsList,
            categoriesList,
            topicsList,
            facebookPagesFromApi: facebookPagesFromApi.data,
            stockAdditionalData: stockLevels.data,
            featuresAdditionalData: productFeatures.data,
          });
        }),
      )
      .catch(error => {
        dispatch({
          type: PREPARE_TEMPLATE_FOR_EDIT_FAILURE,
          error,
        });
      });
  };
}

export function updateTemplateAction(data) {
  return dispatch => {
    dispatch({
      type: UPDATE_TEMPLATE
    });
    return axios
      .put(`/api/templates`, data)
      .then(res => {
        if (res.status === 201 || 200) {
          dispatch({
            type: UPDATE_TEMPLATE_SUCCESS,
          });
          return Promise.resolve(res);
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_TEMPLATE_FAILURE,
          error,
        });
        return Promise.resolve(error);
      });
  };
}

export function cancelEditTemplate() {
  return {
    type: CANCEL_EDIT_TEMPLATE
  };
}

export function handleField(event) {
  return {
    type: HANDLE_TEMPLATE_FIELD,
    name: event.target.name,
    value: event.target.value,
  };
}

export function handleSelect(name, value) {
  return {
    type: HANDLE_TEMPLATE_SELECT,
    name,
    value,
  };
}

export function handleSocial(name, value, accountsList) {
  return {
    type: HANDLE_TEMPLATE_SOCIAL,
    name,
    value,
    accountsList,
  };
}

export function handleSocialAccounts(value) {
  return {
    type: HANDLE_TEMPLATE_SOCIAL_ACCOUNTS,
    value,
  };
}

export function handleSocialPages(value) {
  return {
    type: HANDLE_TEMPLATE_SOCIAL_PAGES,
    value,
  };
}

export function handleType(name, value, topicsList, categoriesList) {
  return {
    type: HANDLE_TEMPLATE_TYPE,
    value,
    name,
    topicsList,
    categoriesList,
  };
}

export function handleTypeAdditional(name, value) {
  return {
    type: HANDLE_TEMPLATE_TYPE_ADDITIONAL,
    value,
    name,
  };
}

export function handleTag(tag, caretPos) {
  return {
    type: HANDLE_TEMPLATE_TAG,
    tag,
    caretPos,
  };
}

function getFacebookPages() {
  return axios.get(`/api/social-network-objects`);
}

function getStockLevels() {
  return axios.get(`/api/stock-levels`);
}

function getProductFeatures() {
  return axios.get(`/api/product-features`);
}
