import { deepCopyObject } from 'util/deepCopyObject';
import getMediaListName from 'util/getMediaListName';

export function getStateWithUpdatedFile(state, action, newProps) {
  const mediaSection = action.payload?.mediaSection;
  const mediaListType = getMediaListName(mediaSection);

  const { payload } = action;
  const nextState = deepCopyObject(state);
  const fileInd = nextState[mediaListType][payload.mediaType]?.list?.findIndex(
    file => file.id === payload.id
  );

  if (fileInd !== -1) {
    nextState[mediaListType][payload.mediaType].list[fileInd] = {
      ...nextState[mediaListType][payload.mediaType].list[fileInd],
      ...newProps
    };
  }

  return nextState;
}
