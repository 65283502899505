import axios from 'axios';
import { profileAPI } from '../../../dal/api';

export const PREPARE_PROFILE_FOR_EDIT = 'PREPARE_PROFILE_FOR_EDIT';
export const PREPARE_PROFILE_FOR_EDIT_SUCCESS = 'PREPARE_PROFILE_FOR_EDIT_SUCCESS';
export const PREPARE_PROFILE_FOR_EDIT_FAILURE = 'PREPARE_PROFILE_FOR_EDIT_FAILURE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const CHANGE_SUBSCRIBE = 'CHANGE_SUBSCRIBE';
export const CHANGE_SUBSCRIBE_SUCCESS = 'CHANGE_SUBSCRIBE_SUCCESS';
export const CHANGE_SUBSCRIBE_FAILURE = 'CHANGE_SUBSCRIBE_FAILURE';

export const HANDLE_PROFILE_FIELD = 'HANDLE_PROFILE_FIELD';

export const CANCEL_EDIT_PROFILE = 'CANCEL_EDIT_PROFILE';
export const CLEAR_PROFILE_ERROR_STATE = 'CLEAR_PROFILE_ERROR_STATE';

export const CHANGE_USER_AVATAR = 'CHANGE_USER_AVATAR';
export const CHANGE_USER_AVATAR_SUCCESS = 'CHANGE_USER_AVATAR_SUCCESS';
export const CHANGE_USER_AVATAR_FAILURE = 'CHANGE_USER_AVATAR_FAILURE';

export function getProfileData() {
    return dispatch => {
        dispatch({
            type: PREPARE_PROFILE_FOR_EDIT,
            loading: true,
            error: null
        });
        return axios
            .get(`/api/account`)
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: PREPARE_PROFILE_FOR_EDIT_SUCCESS,
                        loading: false,
                        error: null,
                        payload: res.data
                    });
                    return Promise.resolve(res);
                }
                dispatch({
                    type: PREPARE_PROFILE_FOR_EDIT_FAILURE,
                    loading: false,
                    error: res
                });
                return Promise.resolve(res);
            })
            .catch(error => {
                dispatch({
                    type: PREPARE_PROFILE_FOR_EDIT_FAILURE,
                    loading: false,
                    error
                });
                return Promise.resolve(error);
            });
    };
}

// avatar
export function changeUserAvatar() {
    return dispatch => {
        dispatch({
            type: CHANGE_USER_AVATAR,
            loading: true,
            error: null
        });
        return axios
            .put(`/api/account/avatar`)
            .then(res => {
                if (res.status === 200) {
                    dispatch({
                        type: PREPARE_PROFILE_FOR_EDIT_SUCCESS,
                        loading: false,
                        error: null,
                        payload: res.data
                    });
                    return Promise.resolve(res);
                }
                dispatch({
                    type: PREPARE_PROFILE_FOR_EDIT_FAILURE,
                    loading: false,
                    error: res
                });
                return Promise.resolve(res);
            })
            .catch(error => {
                dispatch({
                    type: PREPARE_PROFILE_FOR_EDIT_FAILURE,
                    loading: false,
                    error
                });
                return Promise.resolve(error);
            });
    };
}

export function getProfileDataResult() {
    return dispatch => {
        return axios.get(`/api/account`).then(res => {
            if (res.status === 200) {
                return Promise.resolve(res);
            }
            return Promise.resolve(res);
        });
    }
}

export function updateProfileAction(data) {
    return dispatch => {
        dispatch({
            type: UPDATE_PROFILE,
            loading: true,
            error: null
        });
        return axios
            .post(`/api/account`, data)
            .then(res => {
                if (res.status === 201 || res.status === 200) {
                    dispatch({
                        type: UPDATE_PROFILE_SUCCESS,
                        loading: false,
                        error: null
                    });
                    return Promise.resolve(res);
                }
            })
            .catch(error => {
                dispatch({
                    type: UPDATE_PROFILE_FAILURE,
                    loading: false,
                    error
                });
                return Promise.resolve(error);
            });
    };
}

export function toggleSubscription(userId, currentStatus) {
    return dispatch => {
        dispatch({
            type: CHANGE_SUBSCRIBE,
            loading: true,
            error: null
        });
        return profileAPI
            .toggleSubscription(userId, currentStatus)
            .then(() => {
                dispatch({
                    type: CHANGE_SUBSCRIBE_SUCCESS,
                    loading: false,
                    error: null,
                    subscribed: !currentStatus
                });
            })
            .catch(error => {
                dispatch({
                    type: CHANGE_SUBSCRIBE_FAILURE,
                    loading: false,
                    error
                });
            });
    };
}

export function updateProfile() {
    return {
        type: UPDATE_PROFILE,
        loading: true,
        error: null
    };
}
export function updateProfileSuccess() {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        loading: false,
        error: null
    };
}
export function updateProfileFailure(error) {
    return {
        type: UPDATE_PROFILE_FAILURE,
        loading: false,
        error
    };
}

export function handleProfileField(event) {
    return {
        type: HANDLE_PROFILE_FIELD,
        loading: false,
        name: event.target.name,
        value: event.target.value,
        actionError: null
    };
}

export function cancelEditProfile() {
    return {
        type: CANCEL_EDIT_PROFILE,
        loading: false,
        error: null
    };
}

export function clearProfileErrorState() {
    return {
        type: CLEAR_PROFILE_ERROR_STATE,
        loading: false,
        error: null
    };
}
