const changeArrayToSelectLike = arr => {
  let result = [];
  arr.forEach(val =>
    result.push({
      key: val.id,
      value: val.id,
      label: val.name
    })
  );
  return result;
};

export default changeArrayToSelectLike;
