export const MAX_LENGTH_PASSWORD = 50;
export const MIN_LENGTH_PASSWORD = 6;

function validatePassword(password) {
  /* Old rules
  const specialSymbol = "*|,\"!:<>[]{}`';()@&$#%_";
  const letter = /[a-zA-Z]/;
  const number = /[0-9]/;

  let specialSymbolValid = false;

  for (let i = 0; i < password.length; i++) {
    if (specialSymbol.indexOf(password.charAt(i)) !== -1) {
      specialSymbolValid = true;
    }
  }*/
// password > 5  and <= 50 symb
  return password.length >= MIN_LENGTH_PASSWORD && password.length <= MAX_LENGTH_PASSWORD;
}

export default validatePassword;
