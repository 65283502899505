import {
  SET_SMART_SCHEDULE,
  TOGGLE_SMART_SCHEDULE,
  DATES_TO_BLACKLIST,
  REMOVE_DATE_FROM_BLACKLIST,
} from 'store/actions/smartSchedule/smartSchedule';
import datesToBlackList, {datesToBlackListFromObject} from './helpers/datesToBlackList';
import removeDateFromBlackList from './helpers/removeDateFromBlackList';


const initialState = {
  enable: true,
  lastGeneratedDate: null,
  payload: [],
  blackList: []
};

export default (state = initialState, action) => {
  switch(action.type) {

    case SET_SMART_SCHEDULE:
      return { ...state, lastGeneratedDate: action.date, payload: action.payload };

    case TOGGLE_SMART_SCHEDULE:
      return { ...state, enable: !state.enable };

    case DATES_TO_BLACKLIST:
      return action.objectBlackList ? datesToBlackListFromObject(state, action.objectBlackList) : datesToBlackList(state, action);

    case REMOVE_DATE_FROM_BLACKLIST:
      return removeDateFromBlackList(state, action);

    default:
      return state;
  }
}
