export function fillCalendarNewPosts(state, action) {
  let copyPayload = state.payload.slice();
  const { posts } = action;

  if (posts && posts.length) {
    posts.forEach(post => {
      const { scheduledPublicationDate } = post;

      if (scheduledPublicationDate) {
        const [date] = scheduledPublicationDate.split('T');

        for (let [itemInd, item] of copyPayload.entries()) {
          // Remove post if have in calendar ceil
          _removePostIfHave(copyPayload, post);

          if (date === item.day) {
            copyPayload[itemInd].postsList = [post, ...copyPayload[itemInd].postsList];
            break;
          }
        }
      } else {
        copyPayload[0].postsList = [post, ...copyPayload[0].postsList];
      }
    });
  }

  return Object.assign({}, state, {
    payload: [...state.payload],
    copyPayload
  });
}


function _removePostIfHave(payload, newPost) {
	for (let [dateInd, date] of payload.entries()) {

		for (let [postInd, post] of date.postsList.entries()) {
			if (post.id === newPost.id) {
				payload[dateInd].postsList.splice(postInd, 1);
				return;
			}
		}

	}
}
