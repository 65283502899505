import axios from 'axios';
import { shopAPI } from '../../../dal/api';

export const CONNECT_TO_STORE = 'CONNECT_TO_STORE';
export const CONNECT_TO_STORE_SUCCESS = 'CONNECT_TO_STORE_SUCCESS';
export const CONNECT_TO_STORE_FAILURE = 'CONNECT_TO_STORE_FAILURE';

export const SET_ACTIVE_STATUS_STORE = 'SET_ACTIVE_STATUS_STORE';

export const REMOVE_STORE = 'REMOVE_STORE';
export const REMOVE_STORE_SUCCESS = 'REMOVE_STORE_SUCCESS';
export const REMOVE_STORE_FAILURE = 'REMOVE_STORE_FAILURE';

export const GET_STORES = 'GET_STORES';
export const GET_STORES_SUCCESS = 'GET_STORES_SUCCESS';
export const GET_STORES_FAILURE = 'GET_STORES_FAILURE';

export const HIDE_LOAD_STORE_NOTIFICATION = 'GET_STORES_FAILURE';
export const CLEAR_STORE_ERROR_STATE = 'CLEAR_STORE_ERROR_STATE';

export const CLEAR_STORE_REDUX_STATE = 'CLEAR_STORE_REDUX_STATE';

export function getStoresAction() {
  return dispatch => {
    dispatch({ type: GET_STORES });

    return axios
      .get(`/api/shops`)
      .then(res => {
        dispatch({ type: GET_STORES_SUCCESS, payload: res.data });

        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: GET_STORES_FAILURE, error });
        return Promise.reject(error);
      });
  };
}

export function connectToShopifyAction(storeName, redirectPath) {
  return dispatch => {
    dispatch({ type: CONNECT_TO_STORE });
    return axios
      .get(`/api/shopify/authorization?shopName=${storeName}&currentPath=${redirectPath}`)
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: CONNECT_TO_STORE_SUCCESS });
          return Promise.resolve(res);
        }
      })
      .catch(error => {
        dispatch({ type: CONNECT_TO_STORE_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function connectToMagentoAction(paramsFromUrl) {
  return dispatch => {
    dispatch({ type: CONNECT_TO_STORE });
    return shopAPI
      .magentoAuth(paramsFromUrl['success_call_back'], paramsFromUrl['oauth_consumer_key'])
      .then(res => {
        dispatch({ type: CONNECT_TO_STORE_SUCCESS, shop: res.data });
        return Promise.resolve(res);
      })
      .catch(err => {
        const error =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err;
        dispatch({ type: CONNECT_TO_STORE_FAILURE, error });
        return Promise.reject(error);
      });
  };
}

export function connectToWooCommerceAction(storeName, consumerKey, consumerSecret) {
  return dispatch => {
    dispatch({ type: CONNECT_TO_STORE });
    return shopAPI
      .wooCommerceAuth(storeName, consumerKey, consumerSecret)
      .then(res => {
        dispatch({ type: CONNECT_TO_STORE_SUCCESS, shop: res.data });
        return Promise.resolve(res);
      })
      .catch(err => {
        dispatch({ type: CONNECT_TO_STORE_FAILURE, error: err });
        return Promise.reject(err);
      });
  };
}

export function removeStoreAction(id, removeAppSubscription) {
  return dispatch => {
    dispatch({ type: REMOVE_STORE });

    return axios
      .delete(`/api/shops/${id}?removeAppSubscription=${!!removeAppSubscription}`)
      .then(res => {
        dispatch({
          type: REMOVE_STORE_SUCCESS,
          payload: id
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: REMOVE_STORE_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function setActiveStatus(storeId) {
  return {
    type: SET_ACTIVE_STATUS_STORE,
    storeId
  };
}

export function hideLoadStoreNotification() {
  return { type: HIDE_LOAD_STORE_NOTIFICATION };
}

export function clearStoreErrorState() {
  return { type: CLEAR_STORE_ERROR_STATE };
}

export function clearStoreReduxState() {
  return { type: CLEAR_STORE_REDUX_STATE };
}
