import { combineReducers } from 'redux';
import { persistReducer, purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth/auth';
import signup from './signup';
import modal from './modal/modal';
import templatesList from './template';
import topicsList from './topic';
import categoriesList from './category';
import accountsListReducer from './account';
import postModal from './postModal/postModal';
import postCreate from './post/post';
import productList from './product/product';
import videoListReducer from './video/video';
import calendarReducer from './calendar/calendar';
import storesList from './store/store';
import smartScheduleReducer from './smartSchedule';
import autopilotReducer from './autopilot/autopilot';
import templateUserReducer from './templatesUser/template';
import tour from './tour';
import settings from './settings/settings';
import dndStatus from './dndStatus';

import editTemplate from './editTemplate/editTemplate';
import editProfile from './editProfile/editProfile';
import changePassword from './changePassword/changePassword';
import scheduleDemo from './scheduleDemo';
import companies from './companies';

import playlist from './common/playlist';
import paymentHistory from './billing/paymentHistory';

import competitors from './competitors';
import spyFeed from './spyFeed';
import profile from './profile/profile';
import counters from './counters';
import urlShortenerAccounts from './urlShortenerAccounts/urlShortenerAccounts';
import cloud from './cloud';
import acl from './acl';

export const persistConfig = {
  key: 'root',
  storage: storage,
  throttle: 1000,
  blacklist: [
    'auth',
    'signup',
    'modal',
    'editTemplate',
    'editProfile',
    'changePassword',
    'scheduleDemo',
    'postModal',
    'tour',
    'accountsList',
    'smartSchedule',
    'calendar',
    'autopilot',
    'templateUser',
    'videoList',
    'competitors',
    'spyFeed',
    'profile',
    'counters',
    'urlShortenerAccounts',
    'cloud',
    'acl'
  ]
};

const accountsListPersistConfig = {
  key: 'accountsList',
  storage: storage,
  throttle: 1000,
  blacklist: ['togglePagesLoading', 'error', 'loading']
};

const smartSchedulePersistConfig = {
  key: 'smartSchedule',
  storage: storage,
  throttle: 1000,
  blacklist: ['lastGeneratedDate', 'payload']
};
const calendarPersistConfig = {
  key: 'calendar',
  storage: storage,
  throttle: 1000,
  blacklist: ['error', 'loading']
};

const autopilotsPersistConfig = {
  key: 'autopilot',
  storage: storage,
  throttle: 1000,
  blacklist: ['form', 'view', 'error', 'loading']
};

const templatesPersistConfig = {
  key: 'templateUser',
  storage: storage,
  throttle: 1000,
  blacklist: ['form', 'view', 'error', 'loading']
};
const videosPersistConfig = {
  key: 'videoList',
  storage: storage,
  throttle: 1000,
  blacklist: [
    'payload',
    'error',
    'showLimit',
    'loadedVideosOnScroll',
    'searchQuery',
    'loading',
    'sync'
  ]
};

const appReducer = combineReducers({
  auth,
  signup,
  modal,
  templatesList,
  topicsList,
  categoriesList,
  accountsList: persistReducer(accountsListPersistConfig, accountsListReducer),
  productList,
  videoList: persistReducer(videosPersistConfig, videoListReducer),
  postModal,
  postCreate,
  calendar: persistReducer(calendarPersistConfig, calendarReducer),
  storesList,
  editTemplate,
  editProfile,
  changePassword,
  scheduleDemo,
  companies,
  playlist,
  paymentHistory,
  smartSchedule: persistReducer(smartSchedulePersistConfig, smartScheduleReducer),
  autopilot: persistReducer(autopilotsPersistConfig, autopilotReducer),
  templateUser: persistReducer(templatesPersistConfig, templateUserReducer),
  tour,
  settings,
  dndStatus,
  competitors,
  spyFeed,
  profile,
  counters,
  urlShortenerAccounts,
  cloud,
  acl
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT' || action.type === 'SET_NEW_ACL_SUCCESS') {
    purgeStoredState(persistConfig);
    purgeStoredState(accountsListPersistConfig);
    purgeStoredState(smartSchedulePersistConfig);
    purgeStoredState(autopilotsPersistConfig);
    purgeStoredState(templatesPersistConfig);
    purgeStoredState(calendarPersistConfig);
    purgeStoredState(videosPersistConfig);
    state = undefined;
  }
  return appReducer(state, action);
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
