export default function updatePostMedia(state, action) {
  const { id, mediaKey, newProps } = action.payload || {};
  const nextState = { ...state, [mediaKey]: [...state[mediaKey]] };
  const mediaInd = nextState[mediaKey].findIndex(media => media.id === id);

  if (mediaInd !== -1) {
    nextState[mediaKey][mediaInd] = {
      ...nextState[mediaKey][mediaInd],
      ...newProps
    };
  }

  return nextState;
}
