import {
  REORDER_VIDEO,
  SET_VIDEO_LIST,
  SET_CHANNELS,
  SET_LOAD_MORE_VIDEOS,
  SET_LOADED_ON_SCROLL_VIDEOS,
  SET_SEARCH_VIDEOS,
  ADD_VIDEO,
  DELETE_VIDEO,
  DELETE_VIDEOS_ACCOUNT,
  SET_VIDEO_ERROR,
  CLEAR_VIDEO_ERROR,
  SET_VIDEO_LOADING,
  SET_SHOW_LIMIT_TO_VIDEOS,
  SET_FILTER_OPTIONS_FOR_VIDEOS,
  TOGGLE_CHECK_FILTER_OPTION_FOR_VIDEOS,
  SET_CHECK_FILTER_OPTION_ALL,
  ADD_SYNC_VIDEO_ACCOUNT,
  DELETE_SYNC_VIDEO_ACCOUNT
} from 'store/actions/video/video';

import reorder from 'util/reorder';
import addVideo from './helpers/addVideo';


const initialState = {
  payload: [],
  channels: [],
  filterOptions: [], //playlists
  showLimit: 20,
  loadedVideosOnScroll: false,
  searchQuery: '',
  loading: false,
  error: null,
  sync: [], //array id-accounts
};

export default (state = initialState, action) => {
  switch (action.type) {

    case SET_VIDEO_LIST:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null
      };

    case SET_CHANNELS:
      return {
        ...state,
        channels: action.payload,
        loading: false,
        error: null
      };


    case SET_FILTER_OPTIONS_FOR_VIDEOS:
      return {
        ...state,
        filterOptions: action.payload,
        loading: false,
        error: null
      };

    case SET_SEARCH_VIDEOS:
      return {
        ...state,
        searchQuery: action.payload
      };

    case SET_VIDEO_LOADING:
      return {
        ...state,
        loading: action.loading,
        error: action.error ? action.erorr : null,
      };

    case SET_VIDEO_ERROR:
      return {
        ...state,
        error: action.payload
      };

    case CLEAR_VIDEO_ERROR:
      return {
        ...state,
        error: null
      };

    case ADD_VIDEO:
      return {
        ...state,
        payload: addVideo(state.payload, action.video)
      };

    case DELETE_VIDEO:
      return {
        ...state,
        payload: state.payload.filter(video => video.id !== action.id)
      };

    case ADD_SYNC_VIDEO_ACCOUNT:
      return {
        ...state,
        sync: [...new Set([...state.sync, action.id])]
      };

    case DELETE_SYNC_VIDEO_ACCOUNT:
      return {
        ...state,
        sync: state.sync.filter( id => id !== action.id )
      };

    case DELETE_VIDEOS_ACCOUNT:
      return {
        ...state,
        payload: state.payload.filter(video => !video.channel || !video.channel.socialAccount || video.channel.socialAccount.id !== action.accountId)
      };

    case TOGGLE_CHECK_FILTER_OPTION_FOR_VIDEOS:
      return {
        ...state,
        filterOptions: state.filterOptions.map(
          playlist =>
            playlist.id === action.id ?
              {...playlist, selectedInPublications: action.selected}
              : playlist
        )
      };

    case SET_CHECK_FILTER_OPTION_ALL:
      return {
        ...state,
        filterOptions: state.filterOptions.map(
          playlist => ({...playlist, selectedInPublications: action.select})
        )
      };

    case SET_SHOW_LIMIT_TO_VIDEOS:
      return {
        ...state,
        showLimit: action.limit
      };

    case SET_LOADED_ON_SCROLL_VIDEOS:
      return {
        ...state,
        loadedVideosOnScroll: action.loading,
        error: action.error ? action.erorr : null,
      };

    case SET_LOAD_MORE_VIDEOS:
      return {
        ...state,
        payload: [...state.payload, ...action.payload],
        loadedVideosOnScroll: false,
        loading: false,
        error: null
      };

    case REORDER_VIDEO:
      return {
        ...state,
        payload: reorder(state.payload, action.oldId, action.newId)
      };

    default:
      return state;
  }
};
