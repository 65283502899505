import axios from 'axios';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const CLEAR_SIGNUP_ERROR_STATE = 'CLEAR_SIGNUP_ERROR_STATE';

export function signupAction(userData) {
  return dispatch => {
    dispatch({ type: SIGNUP });

    return axios
      .post(`/api/register`, userData)
      .then(res => {
        if (res.status === 201) {
          dispatch({ type: SIGNUP_SUCCESS });
          return Promise.resolve(res);
        } else {
          dispatch({ type: SIGNUP_FAILURE, error: res });
          return Promise.resolve(res);
        }
      })
      .catch(error => {
        dispatch({ type: SIGNUP_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function clearSignupErrorState() {
  return { type: CLEAR_SIGNUP_ERROR_STATE };
}
