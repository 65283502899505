import returnObjForOptionProduct from 'util/returnObjForOptionProduct';
import returnObjForOptionVideo from 'util/returnObjForOptionVideo';
import returnObjForOptionMedia from 'util/returnObjForOptionMedia';

export default (state, action) => {
  const dataForSourceItemsSelect = prepareDataForSourceItemsSelect(state, action);

  return {
    ...state,
    sourceType: action.sourceType,
    sourceBrand: action.sourceBrand,
    sourceItem: {},
    dataForSourceItemsSelect,
    // images: [], // clear images after choose source type
    // link: '' // clear link after choose source type
  };
}

const prepareDataForSourceItemsSelect = (state, action) => {
  let defaultOptions = [];

  if (action.sourceType === 'PRODUCT' && action.sourceItems.length) {
    const value = (state.sourceItem && state.sourceItem.sourceType) === action.sourceType ?
      returnObjForOptionProduct(state.sourceItem) : null;
    defaultOptions = action.sourceItems.map(product => returnObjForOptionProduct(product));

    if (value) defaultOptions.unshift(value);

  } else if (action.sourceType === 'VIDEO' && action.sourceItems.length) {
    defaultOptions = action.sourceItems.map(video => returnObjForOptionVideo(video));
  } else if (['MEDIA_IMAGES', 'MEDIA_VIDEOS'].includes(action.sourceType) && action.sourceItems.length) {
    defaultOptions = action.sourceItems.map(m => returnObjForOptionMedia(m));
  } 
  return {
    placeholder: defaultOptions.length ? 'Source items' : 'No source',
    value: null,
    defaultOptions: [],
    disabled: !defaultOptions.length
  };
};
