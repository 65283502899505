import axios from 'axios';

export const PROFILE_GET_PENDING = 'PROFILE_GET_PENDING';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_FAIL = 'PROFILE_GET_FAIL';

export const UPDATE_COMPANY_PENDING = 'UPDATE_COMPANY_PENDING';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export function profileGetAction() {
  return async dispatch => {
    try {
      dispatch({ type: PROFILE_GET_PENDING });

      const res = await axios.get('/api/account');

      dispatch({
        type: PROFILE_GET_SUCCESS,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: PROFILE_GET_FAIL,
        error: err.response?.data?.message || 'Someting went wrong on get profile data'
      });
    }
  };
}

export function updateCompanyAction(id, company) {
  return dispatch => {
    dispatch({ type: UPDATE_COMPANY_PENDING });

    return axios
      .put(`/api/company/${id}`, {
        name: company.name
      })
      .then(res => {
        dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: res.data });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: UPDATE_COMPANY_FAIL, error });
        return Promise.resolve(error);
      });
  };
}
