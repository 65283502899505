import {validate} from './validate';
import changeArrayToSelectLike from 'util/changeArrayToSelectLike';
import getCapitalizedItemName from 'util/getCapitalizedItemName';

export function handleField(state, action) {
  const name = action.name;
  const value = action.value;

  return Object.assign({}, state, {
    [name]: value,
    loading: false,
    error: null,
  }, validate(state, name, value));
}

export function handleSelect(state, action) {
  const name = action.name;
  const value = action.value;

  return Object.assign({}, state, {
    [name]: value,
    loading: false,
    error: null,
  }, validate(state, name, value));
}

export function handleSocial(state, action) {
  let options = [
    {
      key: 666777,
      value: 666777,
      label: "Select all accounts",
    },
  ];
  const name = action.name;
  const value = action.value;
  const val = value.value;
  const accountList = changeArrayToSelectLike(action.accountsList.filter(account => account.type === val));

  if(state[name].value !== val) {
    return Object.assign({}, state, {
        [name]: value,
        loading: false,
        error: null,
        socialAccounts: state.socialType.value !== val ? [] : options.concat(state.socialAccounts),
        socialPages: [],
        socialPagesAdditionalData: {
          ...state.socialPagesAdditionalData,
          disabled: true,
          placeholder: `First choose ${value.label} accounts`,
        }

      },
      _changeSocialTypeAndAdditions(state, val, options.concat(accountList)),
      validate(state, name, value)
    );
  } else {
    return state
  }
}

export function handleSocialAccounts(state, action) {
  const name = "socialAccounts";
  const valueFromState = state[name];
  const valueFromAction = action.value;

  const alreadyAll = valueFromState.filter(page => page.value === 666777).length !== 0;
  const checkAll = valueFromAction.filter(page => page.value === 666777).length !== 0;
  const uncheckAll = alreadyAll && valueFromAction.filter(page => page.value === 666777).length === 0;

  if (alreadyAll) {

    if (uncheckAll) {
      return Object.assign({}, state, {
          [name]: [],
          loading: false,
          error: null,
        },
        validate(state, name, []),
        _changeSocialPages(state, valueFromAction, alreadyAll, checkAll, uncheckAll)
      );
    } else {
      return Object.assign({}, state, {
          loading: false,
          error: null,
          [name]: valueFromAction.filter(page => page.value !== 666777),
        },
        validate(state, name, valueFromAction.filter(page => page.value !== 666777)),
        _changeSocialPages(state, valueFromAction, alreadyAll, checkAll, uncheckAll)
      );
    }
  } else {
    if (checkAll) {

      return Object.assign({}, state, {
          loading: false,
          error: null,
          [name]: state.socialAccountsAdditionalData.options,
        },
        validate(state, name, state.socialAccountsAdditionalData.options),
        _changeSocialPages(state, valueFromAction, alreadyAll, checkAll, uncheckAll)
      );

    } else {
      return Object.assign({}, state, {
          loading: false,
          error: null,
          [name]: valueFromAction.filter(page => page.value !== 666777),
        },
        validate(state, name, valueFromAction.filter(page => page.value !== 666777)),
        _changeSocialPages(state, valueFromAction, alreadyAll, checkAll, uncheckAll)
      );
    }
  }
}

export function handleSocialPages(state, action) {
  const name = "socialPages";
  const valueFromState = state[name];
  const valueFromAction = action.value;

  const alreadyAllPages = valueFromState.filter(page => page.value === 666777).length !== 0;

  if (alreadyAllPages) {
    const uncheckAllPages = valueFromAction.filter(page => page.value === 666777).length === 0;

    if (uncheckAllPages) {

      return Object.assign({}, state, {
          loading: false,
          error: null,
          socialPages: [],
        },
        validate(state, name, [])
      );

    } else {

      return Object.assign({}, state, {
          loading: false,
          error: null,
          socialPages: valueFromAction.filter(page => page.value !== 666777),
        },
        validate(state, name, valueFromAction.filter(page => page.value !== 666777))
      );

    }
  } else {
    const checkAllPages = valueFromAction.filter(page => page.value === 666777).length !== 0;

    if (checkAllPages) {

      return Object.assign({}, state, {
          loading: false,
          error: null,
          socialPages: state.socialPagesAdditionalData.options,
        },
        validate(state, name, state.socialPagesAdditionalData.options)
      );

    } else {
      return handleSelect(state, {name: name, value: valueFromAction});
    }
  }
}

export function handleType(state, action) {
  const {name, value, topicsList, categoriesList} = action;
  const val = value.value;
  let initial = [
    {
      key: 666777,
      value: 666777,
      label: "Select all categories",
    },
  ];

  switch (val) {
    case "NEWS":
    case "ARTICLE":

      return Object.assign({}, state, handleSelect(state, {name: name, value: value, loading: false,
          error: null}),{
        typeAdditionalData: {
            disabled: false,
            isMulti: true,
            placeholder: val === "NEWS" ? "News" : "Articles",
            options: initial.concat(changeArrayToSelectLike(topicsList.payload)),
          },
          formErrors: {
            ...state.formErrors,
            typeAdditional: "",
          },
          tags: [{ id: "title", label: "Title" }, { id: "url", label: "Url" }, { id: "description", label: "Description" }, { id: "hashtags", label: "Hashtags" }],
        },
        setAdditionalTypeValue(state, val),
        validate(state, name, state.socialPagesAdditionalData.options),
      );

    case "PRODUCT_LINK":
    case "PRODUCT_ALBUM":

      return Object.assign({}, state, handleSelect(state, {name: name, value: value, loading: false,
          error: null}),{
          typeAdditionalData: {
            disabled: false,
            isMulti: true,
            placeholder: "Product categories",
            options: initial.concat( changeArrayToSelectLike(categoriesList.payload)),
          },
          formErrors: {
            ...state.formErrors,
            typeAdditional: "",
          },
          tags: [
            { id: "name", label: "Name" },
            { id: "url", label: "Url" },
            { id: "description", label: "Description" },
            { id: "hashtags", label: "Hashtags" },
            { id: "sku", label: "Sku" },
            { id: "brand", label: "Brand" },
            { id: "price", label: "Price" },
          ],
        },
        setAdditionalTypeValue(state, val),
        validate(state, name, state.socialPagesAdditionalData.options)
      );


    case "VIDEO":

      return Object.assign({}, state, handleSelect(state, {name: name, value: value,loading: false,
          error: null}),{
          typeAdditionalData: {
            disabled: false,
            isMulti: true,
            placeholder: "Videos",
            options: initial.concat(changeArrayToSelectLike(categoriesList.payload)),
          },
          formErrors: {
            ...state.formErrors,
            typeAdditional: "",
          },
          tags: [
            { id: "name", label: "Name" },
            { id: "url", label: "Url" },
            { id: "description", label: "Description" },
            { id: "hashtags", label: "Hashtags" },
            { id: "sku", label: "Sku" },
            { id: "brand", label: "Brand" },
            { id: "price", label: "Price" },
          ],
        },
        setAdditionalTypeValue(state, val),
        validate(state, name, state.socialPagesAdditionalData.options)
      );

    default:
      return Object.assign({}, state, {
        loading: false,
        error: null,
          typeAdditional: [],
          typeAdditionalValid: false,
        }
      );
  }

  function setAdditionalTypeValue(state, val) {
    if (state.type.value !== val) {
      return {
        typeAdditional: [],
        typeAdditionalValid: false,
      };
    } else {
      return {
        typeAdditional: state.typeAdditional,
        typeAdditionalValid: true,
      };
    }
  }
}

export function handleTypeAdditional(state, action){
  const {name, value} = action;
  const { typeAdditional } = state;
  const alreadyAll = typeAdditional.filter(page => page.value === 666777).length !== 0;

  if (alreadyAll) {
    const uncheckAll = value.filter(page => page.value === 666777).length === 0;

    if (uncheckAll) {
      return Object.assign({}, state,{
          loading: false,
          error: null,
          typeAdditional: [],
        },
        validate(state, name, []),
      );
    } else {
      return Object.assign({}, state,{
        typeAdditional: value.filter(page => page.value !== 666777),
      }, validate(state, name, value.filter(page => page.value !== 666777)));

    }
  } else {
    const checkAll = value.filter(page => page.value === 666777).length !== 0;

    if (checkAll) {
      return Object.assign({}, state,{
        loading: false,
        error: null,
        typeAdditional: state.typeAdditionalData.options,
      }, validate(state, "typeAdditional", state.typeAdditionalData.options));
    } else {
      return handleSelect(state, {name: name, value: value});
    }
  }
}

export function handleTag(state, action) {
  let templateBodyToArray = state.template.split("");

  let caretPos = action.caretPos;
  templateBodyToArray.splice(caretPos, 0, ` {{${action.tag}}} `);

  return Object.assign({}, state, {
      loading: false,
      error: null,
    template: templateBodyToArray.join("").replace(/  +/g, " "),
  },
    validate(state, "template", templateBodyToArray.join("").replace(/  +/g, " "))
  );
}


function _changeSocialTypeAndAdditions(state, val, accountList){
  switch (val) {
    case "FACEBOOK":
      return {
        socialAccountsAdditionalData: {
          disabled: false,
          isMulti: true,
          name: "socialAccounts",
          placeholder: "Facebook accounts",
          options: accountList,
        },
        socialPagesValid: true,
        typeSelectData: {
          ...state.typeSelectData,
          disabled: false,
          placeholder: "Type of Post",
          options: [
            { value: "NEWS", label: "News" },
            { value: "VIDEO", label: "Video" },
            { value: "ARTICLE", label: "Articles" },
            { value: "PRODUCT_LINK", label: "Product link" },
            { value: "PRODUCT_ALBUM", label: "Product album" },
          ],
        },
      };

    case "PINTEREST":
      return {
        socialAccountsAdditionalData: {
          disabled: false,
          isMulti: true,
          name: "socialAccounts",
          placeholder: "Pinterest accounts",
          options: accountList,
        },
        typeSelectData: {
          ...state.typeSelectData,
          disabled: false,
          placeholder: "Type of Post",
          options: [
            { value: "NEWS", label: "News" },
            { value: "VIDEO", label: "Video" },
            { value: "ARTICLE", label: "Articles" },
            { value: "PRODUCT_LINK", label: "Product link" },
            { value: "PRODUCT_ALBUM", label: "Product album" },
          ],
        }
      };

    case "INSTAGRAM":
      return {
        socialAccountsAdditionalData: {
          disabled: false,
          isMulti: true,
          name: "socialAccounts",
          placeholder: "Instagram accounts",
          options: accountList,
        },
        typeSelectData: {
          ...state.typeSelectData,
          disabled: false,
          placeholder: "Type of Post",
          options: [{ value: "VIDEO", label: "Video" }, { value: "PRODUCT_ALBUM", label: "Product album" }],
        },
        socialPages: [],
        socialPagesValid: false,
      };

    case "TWITTER":
      return {
        socialAccountsAdditionalData: {
          disabled: false,
          isMulti: true,
          name: "socialAccounts",
          placeholder: "Twitter accounts",
          options: accountList,
        },
        typeSelectData: {
          ...state.typeSelectData,
          disabled: false,
          placeholder: "Type of Post",
        },
        socialPages: [],
        socialPagesValid: false,
      };

    default:
      break;
  }
}

function _changeSocialPages(state, value, alreadyAll, checkAll, uncheckAll){

  // (alreadyAll && checkAll) - that means user uncheck accounts and in select last checked value is "Select all" (no sense without active accounts)
  if (uncheckAll || (alreadyAll && checkAll)) {
    return {
        socialPages: [],
        socialPagesAdditionalData: {
          ...state.socialPagesAdditionalData,
          disabled: true,
          placeholder: `First choose ${state.socialType.label} accounts`,
        }
      };
  } else if ((state.socialType.value === "FACEBOOK" || state.socialType.value === "PINTEREST") && value.length >= 1) {
    return !checkAll ? {
      socialPages: [],
      socialPagesAdditionalData: {
        ...state.socialPagesAdditionalData,
        options: _filterChosenFacebookPages(state.facebookPagesFromApi, value.filter(item => item.value !== 666777)),
        disabled: false,
        placeholder: `${getCapitalizedItemName(state.socialType.value)} pages`,
        value: [],
      },
    } : {
      socialPages: [],
      socialPagesAdditionalData: {
        ...state.socialPagesAdditionalData,
        options: _getAllPageObjects(state.facebookPagesFromApi, state.socialType.value),
        disabled: false,
        placeholder: `${getCapitalizedItemName(state.socialType.value)} pages`,
        value: [],
      },
    };
  } else {
    return {
      socialPages: [],
      socialPagesAdditionalData: {
        ...state.socialPagesAdditionalData,
        disabled: true,
        placeholder: `First choose ${state.socialType.label} accounts`,
      },
    };
  }
}

function _filterChosenFacebookPages(chosePagesList, socialAccounts) {
  let result = [
    {
      key: 666777,
      value: 666777,
      label: "Select all pages",
    },
  ];

  socialAccounts.forEach(chosenAccount => {
    chosePagesList.forEach(page => {
      page.accounts.forEach(pageAccount => {
        if (chosenAccount.key === pageAccount.id) {
          result.push(page);
        }
      });
    });
  });

  return changeArrayToSelectLike(result);
}

function _getAllPageObjects(allPageObjects, choosenSocialType) {
  let result = [
    {
      key: 666777,
      value: 666777,
      label: "Select all pages",
    },
  ];

  const objects =  allPageObjects.filter(pageObject => pageObject.socialNetworkType === choosenSocialType);

  return [...result, ...changeArrayToSelectLike(objects)];
}
