// import { SET_LANGUAGE, LangAction, LangState } from '../types';
import {
    DND_STATUS_DISABLED_ON,
    DND_STATUS_DISABLED_OFF,
    DND_STATUS_LOADING_ON,
    DND_STATUS_LOADING_OFF
} from '../../actions/dndStatus';

const initialState = {
    isDndStatusDisabled: false,
    isDndStatusLoading: false
};

const dndStatus = (state = initialState, action) => {
    switch (action.type) {
        case DND_STATUS_DISABLED_ON:
            return {
                ...state,
                isDndStatusDisabled: true
            };
        case DND_STATUS_DISABLED_OFF:
            return {
                ...state,
                isDndStatusDisabled: false
            };
        case DND_STATUS_LOADING_ON:
            return {
                ...state,
                isDndStatusLoading: true
            };
        case DND_STATUS_LOADING_OFF:
            return {
                ...state,
                isDndStatusLoading: false
            };
        default:
            return state;
    }
};

export default dndStatus;
