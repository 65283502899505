export default function addVideo(payload, video) {
  if (video.channel && video.channel.socialAccount && video.channel.socialAccount === 'ACTIVE') {
    let copyPayload = payload.slice();

    const findedIndex = copyPayload.find(v => v.id === video.id);
    if (findedIndex + 1) {
      copyPayload[findedIndex] = { ...copyPayload[findedIndex], ...video };
    } else {
      copyPayload.push(video);
    }

    return copyPayload;
  } else {
    return payload;
  }
}
