import React, { useState, useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import classes from './timer.module.css';

export const Timer = ({endDate}) => {
  const end = moment(endDate);

  // init timer values
  const duration = moment.duration(end.diff(moment()));
  const [ days, setDays ] = useState(duration.days());
  const [ hours, setHours ] = useState(duration.hours());
  const [ minutes, setMinutes ] = useState(duration.minutes());

  useEffect(()=> {
    if (moment().isAfter(end)) {
      return;
    }

    let interval = setInterval(() => {
      const duration = moment.duration(end.diff(moment()));
      if (duration.asSeconds() < 0) {
        clearInterval(interval);
        return;
      }

      setDays(Math.floor(duration.days()));
      setHours(Math.floor(duration.hours()));
      setMinutes(Math.floor(duration.minutes()));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={classes['timer']}>
      <div className={cn(classes['time_unit-wrapper'], classes['days'])}>
        <div className={classes['time_unit']}>{days > 0 ? days : 0}</div>
        <div className={classes['time_unit-label']}>Days</div>
      </div>
      <div className={cn(classes['time_unit-wrapper'], classes['hours'])}>
        <div className={classes['time_unit']}>{hours > 0 ? hours : 0}</div>
        <div className={classes['time_unit-label']}>Hours</div>
      </div>
      <div className={cn(classes['time_unit-wrapper'], classes['minutes'])}>
        <div className={classes['time_unit']}>{minutes > 0 ? minutes : 0}</div>
        <div className={classes['time_unit-label']}>Minutes</div>
      </div>
    </div>
  );
};
