import axios from 'axios';
export const GET_PLAYLIST = 'GET_PLAYLIST';
export const ADD_PLAYLIST = 'ADD_PLAYLIST';
export const REMOVE_PLAYLIST = 'REMOVE_PLAYLIST';
export const CHANGE_PLAYLIST = 'CHANGE_PLAYLIST';

export function getPlaylists() {
  return dispatch => {
    axios.get('/api/video-playlists')
      .then(res => {
        dispatch({
          type: GET_PLAYLIST,
          payload: res.data
        });
      })
  }
}

export function addPlaylist(data) {
  return {
    type: ADD_PLAYLIST,
    data,
  };
}

export function changePlaylist(id, data) {
  return {
    type: CHANGE_PLAYLIST,
    id,
    data,
  };
}

export function removePlaylist(id) {
  return {
    type: REMOVE_PLAYLIST,
    id,
  };
}
