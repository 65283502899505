import { templateAPI, autopilotAPI } from 'dal/api.js';

/*Prefix 'TL_'*/

/*Form*/
export const TL_CHANGE_NAME = 'TL_CHANGE_NAME';
export const TL_SET_COLLECTIONS = 'TL_SET_COLLECTIONS';
export const TL_SET_BRANDS = 'TL_SET_BRANDS';
export const TL_SET_STOCK_LEVELS = 'TL_SET_STOCK_LEVELS';
export const TL_SET_FEATURES = 'TL_SET_FEATURES';
export const TL_SET_TYPES = 'TL_SET_TYPES';
export const TL_SET_PLAYLISTS = 'TL_SET_PLAYLISTS';
export const TL_SET_MEDIA_TAGS = 'TL_SET_MEDIA_TAGS';
export const TL_SET_SOCIAL_PAGES = 'TL_SET_SOCIAL_PAGES';
export const TL_CHANGE_TEMPLATE = 'TL_CHANGE_TEMPLATE';
export const TL_SET_TYPE_POST = 'TL_SET_TYPE_POST';
export const TL_SET_SOURCE_TYPE = 'TL_SET_SOURCE_TYPE';
export const TL_RESET_FORM = 'TL_RESET_FORM';
export const TL_SET_TEMPLATE_ID = 'TL_SET_TEMPLATE_ID';

/*Common*/
export const TL_LOADING = 'TL_LOADING';
export const TL_CLEAR_ERROR = 'TL_CLEAR_ERROR';

/*View*/
export const TL_SET_OBJECT_SAMPLE = 'TL_SET_OBJECT_SAMPLE';
export const TL_SET_LOADING_SAMPLE = 'TL_SET_LOADING_SAMPLE';

/*Template*/
export const TL_SET_TEMPLATE_LIST = 'TL_SET_TEMPLATE_LIST';
export const TL_ADD_TEMPLATE = 'TL_ADD_TEMPLATE';
export const TL_DELETE_TEMPLATE = 'TL_DELETE_TEMPLATE';
export const TL_CHANGE_ACTIVE_TEMPLATE = 'TL_CHANGE_ACTIVE_TEMPLATE';
export const TL_EDIT_TEMPLATE = 'TL_EDIT_TEMPLATE';

export function changeTemplateName(name) {
  return {
    type: TL_CHANGE_NAME,
    name
  };
}

export function changeTemplate(template) {
  return {
    type: TL_CHANGE_TEMPLATE,
    template
  };
}

export function changeTypePost(typePost) {
  return {
    type: TL_SET_TYPE_POST,
    typePost
  };
}

export function changeSourceType(sourceType, sourceBrand='') {
  return {
    type: TL_SET_SOURCE_TYPE,
    sourceType,
    sourceBrand
  };
}

export function setCollections(values) {
  return {
    type: TL_SET_COLLECTIONS,
    values
  };
}

export function setBrands(values) {
  return {
    type: TL_SET_BRANDS,
    values
  };
}

export function setStockLevels(values) {
  return {
    type: TL_SET_STOCK_LEVELS,
    values
  };
}

export function setProductFeatures(values) {
  return {
    type: TL_SET_FEATURES,
    values
  };
}

export function setTypes(values) {
  return {
    type: TL_SET_TYPES,
    values
  };
}

export function setPlaylists(playlists) {
  return {
    type: TL_SET_PLAYLISTS,
    playlists
  };
}

export function setMediaTags(mediaTags) {
  return {
    type: TL_SET_MEDIA_TAGS,
    mediaTags
  };
}

export function setSocialPages(socialPages) {
  return {
    type: TL_SET_SOCIAL_PAGES,
    socialPages
  };
}

export function resetForm() {
  return {
    type: TL_RESET_FORM,
  };
}

export function setTemplateIdToForm(id) {
  return {
    type: TL_SET_TEMPLATE_ID,
    id
  };
}

export function clearError() {
  return {
    type: TL_CLEAR_ERROR,
  };
}

export function getObjectSample(options, objectType) {
  return dispatch => {

    dispatch({ type: TL_SET_LOADING_SAMPLE, isLoadingSample: true });
    dispatch({ type: TL_LOADING, isLoading: false });

    return autopilotAPI.getRandomObject(options, objectType)
      .then(res => {
        dispatch({
          type: TL_SET_OBJECT_SAMPLE,
          objectSample: res
        });

        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: TL_SET_LOADING_SAMPLE, isLoadingSample: false });
        dispatch({ type: TL_LOADING, isLoading: false, error: error.message });
        return Promise.reject(error);
      });
  };
}

export function getTemplateList() {
  return dispatch => {

    dispatch({ type: TL_LOADING, isLoading: true });

    return templateAPI.getList()
      .then(templateList => {
        dispatch({ type: TL_SET_TEMPLATE_LIST, templateList });
      })
      .catch(error => {
        dispatch({ type: TL_LOADING, isLoading: false, error: error.message });
        return Promise.reject(error);
      });
  };
}

export function saveTemplate(templateObject) {
  return dispatch => {

    dispatch({ type: TL_LOADING, isLoading: true });

    if (templateObject.id) {
      return templateAPI.edit(templateObject)
        .then(res => {
          dispatch({
            type: TL_EDIT_TEMPLATE,
            id: templateObject.id,
            template: res
          });

          return Promise.resolve(res);
        })
        .catch(error => {
          dispatch({ type: TL_LOADING, isLoading: false, error: error.message });
          return Promise.reject(error);
        });
    } else {
      return templateAPI.save(templateObject)
        .then(res => {
          dispatch({
            type: TL_ADD_TEMPLATE,
            template: res
          });

          return Promise.resolve(res);
        })
        .catch(error => {
          dispatch({ type: TL_LOADING, isLoading: false, error: error.message });
          return Promise.reject(error);
        });
    }
  };
}

export function changeActiveTemplate(templateId, currentState) {
  return dispatch => {

    dispatch({ type: TL_LOADING, isLoading: true });

    const apiFunction = currentState === 'ACTIVE' ? templateAPI.inactive : templateAPI.active;

    return apiFunction(templateId)
      .then(template => {
        dispatch({ type: TL_CHANGE_ACTIVE_TEMPLATE, id: templateId, template})
      })
      .catch(error => {
        dispatch({ type: TL_LOADING, isLoading: false, error: error.message });
        return Promise.reject(error);
      });
  };
}

export function deleteTemplate(templateId) {
  return dispatch => {

    dispatch({ type: TL_LOADING, isLoading: true });


    return templateAPI.delete(templateId)
      .then(res => {
        dispatch({ type: TL_DELETE_TEMPLATE, id: templateId})
      })
      .catch(error => {
        dispatch({ type: TL_LOADING, isLoading: false, error: error.message });
        return Promise.reject(error);
      });
  };
}
