import { MEDIA_SECTION } from "constants/mediaSections";

export default function getMediaListName(mediaSectionType) {
    switch (mediaSectionType) {
        case MEDIA_SECTION.MY:
            return 'myUploads';
        case MEDIA_SECTION.TEAM:
            return 'teamUploads';
        default:
            return null;
    }
}