import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import returnObjForOptionProduct from 'util/returnObjForOptionProduct';
import returnObjForOptionVideo from 'util/returnObjForOptionVideo';
import returnObjForOptionMedia from 'util/returnObjForOptionMedia';
import { getImageFromSourceData, getUrlFromSourceData } from 'util/getInfoFromSourceData';
import prepareOptionsForSourceTypeSelect from 'util/prepareOptionsForSourceTypeSelect';
import { DATE_TIME_FORMAT } from 'constants/date';
import { POST } from "constants/postTypes";
import { getValueForSocialPagesSelect } from '../../../../../util/asyncSelectHelper';

export default function preparePostModalNew(state, action) {
  const {
    accountsList: { payload, activeSocialType, activePayload },
    storesList,
    sourceItem,
    sourceType,
    sourceBrand,
    dateForPost,
    processedDataForModal
  } = action;

  const socialType = activeSocialType;
  const socialPage = _getSocialPage(socialType, activePayload);
  const optionsForSourceTypeSelect = prepareOptionsForSourceTypeSelect(storesList, payload);
  const dataForSocialPagesSelect = _prepeareDataForSocialPagesSelect(socialPage, state);
  const dataForSourceItemsSelect = _prepareDataForSourceItemsSelect(sourceType, sourceItem, state);
  const momentPostDate = _getPostDate(dateForPost);
  const images = getImageFromSourceData(sourceType, sourceItem);
  const link = getUrlFromSourceData(sourceType, sourceItem);

  return {
    ...state,
    socialType,
    socialPages: [
      {
        default: [],
        socialObjects: Object.keys(socialPage).length ? [socialPage] : [],
        socialType
      }
    ],
    sourceItem,
    sourceType,
    sourceBrand,
    extraPublishingParams: socialType ? {
      [socialType]: {
        postType: POST
      }
    } : {},
    optionsForSourceTypeSelect,
    dataForSocialPagesSelect,
    dataForSourceItemsSelect,
    images: sourceType !== 'VIDEO' ? images : [],
    link: sourceType === 'VIDEO' ? link : '',
    momentPostDate,
    ...processedDataForModal
  };
}

function _getSocialPage(socialType, activeAccounts) {
  if (!activeAccounts.length) return {};

  const excludeSocials = ['FACEBOOK', 'LINKEDIN', 'PINTEREST'];
  const socialAccount = activeAccounts.find(page => {
    return (
      page.type &&
      page.type.search(socialType) !== -1 &&
      page.selectedInPublications &&
      !excludeSocials.includes(page.type)
    );
  });

  if (isEmpty(socialAccount)) {
    return {};
  }

  return {
    ...socialAccount,
    label: socialAccount.name
  };
}

function _prepeareDataForSocialPagesSelect(socialPage, { dataForSocialPagesSelect }) {
  if (!isEmpty(socialPage)) {
    return {
      ...dataForSocialPagesSelect,
      value: { ...getValueForSocialPagesSelect(socialPage) }
    };
  }
  return dataForSocialPagesSelect;
}

function _prepareDataForSourceItemsSelect(sourceType, sourceItem, state) {
  if (sourceType === 'PRODUCT' || sourceType === 'VIDEO') {
    const currentProductForSelect =
      sourceType === 'PRODUCT'
        ? returnObjForOptionProduct(sourceItem)
        : returnObjForOptionVideo(sourceItem);

    return {
      placeholder: 'Source items',
      value: currentProductForSelect,
      disabled: false
    };
  } else if (['MEDIA_IMAGES', 'MEDIA_VIDEOS'].includes(sourceType)) {
    const currentProductForSelect = returnObjForOptionMedia(sourceItem);

    return {
      placeholder: 'Source items',
      value: currentProductForSelect,
      disabled: false
    };
  } else {
    return state.dataForSourceItemsSelect;
  }
}

function _getPostDate(dateForPost) {
  if (dateForPost) {
    if (dateForPost.search('T') !== -1) {
      return moment(dateForPost, DATE_TIME_FORMAT);
    }
    // Set schedule time
    const currentTime = moment().format('HH:mm');
    const scheduleDateAndCurrentTime = moment(dateForPost + currentTime, DATE_TIME_FORMAT);
    const scheduleDateTime = moment(dateForPost + '08:00', DATE_TIME_FORMAT);
    const isSomeOrAfterDateTime = scheduleDateTime.isSameOrAfter(moment(), 'minutes');

    return isSomeOrAfterDateTime ? scheduleDateTime : scheduleDateAndCurrentTime;
  }
  return moment();
}
