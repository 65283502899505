import setSocialTypes from './utils/setSocialTypes';

export function togglePageStatusSuccess(state, action) {

  const newPayload = state.payload.map(account => {
    if (account.id === action.accountId) {
      return {
        ...account,
        //status: action.newStatus === 'ACTIVE' ? 'ACTIVE' : account.status,
        objects: account.objects.map(page => {
          if (page.id === action.id) {
            return {
              ...page,
              status: page.status = action.newStatus,
              selectedInAnalytics: action.page.selectedInAnalytics,
              selectedInPublications: action.page.selectedInPublications,
            };
          } else {
            return page;
          }
        }),
      };
    } else {
      return account;
    }
  });

  let newActivePayload;
  const findedAccount = newPayload.find(account => account.id === action.accountId);

  if(action.newStatus === 'INACTIVE') {
    newActivePayload = state.activePayload.filter(page => page.id !== action.id);
  } else {
    newActivePayload = [...state.activePayload, findedAccount.objects.find(page => page.id === action.id)];
   /* Пока не делать активацию акк-та если активирована страница
   if (!newActivePayload.find(account => account.id === action.accountId)) {
      newActivePayload.push(findedAccount);
    }*/
  }

  return Object.assign({}, state, {
    payload: newPayload,
    activePayload: newActivePayload,
    socialTypes: setSocialTypes(newActivePayload)
  });
}
