import axios from 'axios';
import { getErrorMessageFromError } from '../../../util/getErrorMessageFromError';

export const CONNECT_TO_SOCIAL_NETWORK = 'CONNECT_TO_SOCIAL_NETWORK';
export const CONNECT_TO_SOCIAL_NETWORK_SUCCESS = 'CONNECT_TO_SOCIAL_NETWORK_SUCCESS';
export const CONNECT_TO_SOCIAL_NETWORK_FAILURE = 'CONNECT_TO_SOCIAL_NETWORK_FAILURE';


export function authorizationToSocialNetwork(socialName, redirectPath) {
  let path;
  if (socialName === 'instagram') {
    path = 'facebook';
  } else if (socialName === 'google') {
    path = 'youtube';
  } else {
    path = socialName;
  }

  return dispatch => {
    dispatch({ type: CONNECT_TO_SOCIAL_NETWORK });

    return axios
      .get(`/api/${path}/authorization?currentPath=${redirectPath}`)
      .then(res => {
        if (res.status === 200) {
          dispatch({ type: CONNECT_TO_SOCIAL_NETWORK_SUCCESS });
          return Promise.resolve(res);
        } else {
          dispatch({ type: CONNECT_TO_SOCIAL_NETWORK_FAILURE, error: res });
          return Promise.reject(res);
        }
      })
      .catch(error => {
        dispatch({ type: CONNECT_TO_SOCIAL_NETWORK_FAILURE, error: { message: getErrorMessageFromError(error) } });
        return Promise.reject(error);
      });
  };
}
