import setSocialTypes from './utils/setSocialTypes';

export function updateAccount(state, action) {
  const copyState = {...state};

  let newAccount = action.account;
  // find account index by id and type
  let accountIndex = copyState.payload
    .findIndex(e => {return e.id === newAccount.id && e.type === newAccount.type});

  let activeAccountIndex = copyState.activePayload
    .findIndex(e => {return e.id === newAccount.id && e.type === newAccount.type});

  if (accountIndex > -1) {
    const safeObject = copyState.payload[accountIndex].objects ?
      copyState.payload[accountIndex].objects
      : action.account.objects ? action.account.objects : [];
    //let accountChanged = copyState.payload[accountIndex].imageUrl !== newAccount.imageUrl;
    //accountChanged && (copyState.payload[accountIndex] = {...newAccount, objects: safeObject});
    copyState.payload[accountIndex] = {...copyState.payload[accountIndex], ...newAccount, objects: safeObject};

    if (newAccount.status === 'ACTIVE') {
      if (activeAccountIndex > -1) {
        copyState.activePayload[activeAccountIndex] = {...copyState.activePayload[activeAccountIndex], ...newAccount, objects: safeObject};
      } else {
        copyState.activePayload =  [...copyState.activePayload, {...newAccount, objects: safeObject}]
      }
    }
  }

  if (activeAccountIndex > -1 && newAccount.status !== 'ACTIVE') {
    copyState.activePayload = [...copyState.activePayload].slice(activeAccountIndex, 1);
  }

  copyState.socialTypes = setSocialTypes(copyState.activePayload);

  return copyState;
}
