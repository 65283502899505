import { deepCopyObject } from 'util/deepCopyObject';
import utcToLocal from 'util/utcToLocal';

export function initCalendar(state, action) {
  const nextState = deepCopyObject(state);
  const { payload } = action || {};
  const { calendarData, startDate } = payload || {};

  nextState.loading = false;
  nextState.startDate = startDate;
  nextState.payload = calendarData.map(item => ({
    day: item.date,
    postsList: item.posts.map(post => ({
      ...post,
      publicationDate: utcToLocal(post.publicationDate),
      scheduledPublicationDate: utcToLocal(post.scheduledPublicationDate),
      calendarDate: utcToLocal(post.calendarDate)
    })),
    isLoading: false,
    error: '',
    count: item.totalCount
  }));

  return nextState;
}
