export function prepare(state, action){
  return Object.assign({}, state, {
    loading: action.loading,
    userId: action.payload.id,
    firstName: action.payload.firstName,
    lastName: action.payload.lastName,
    website: action.payload.website,
    phone: action.payload.phone,
    email: action.payload.email,
    oldEmail: action.payload.email,
    status: action.payload.status,
    subscribed: action.payload.subscribed,
    formErrors: {
      firstName: "",
      lastName: "",
      website: "",
      phone: "",
      email: "",
    },
    formValid: true,
    firstNameValid: true,
    lastNameValid: true,
    websiteValid: true,
    phoneValid: true,
    emailValid: true
  });
}
