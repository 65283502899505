import { deepCopyObject } from 'util/deepCopyObject';

export function getStateWithUpdatedDay(state, date, nextProps) {
  const nextState = deepCopyObject(state);
  const dayInd = nextState.payload.findIndex(item => item.day === date);

  nextState.payload[dayInd] = {
    ...nextState.payload[dayInd],
    ...nextProps
  };

  return nextState;
}
