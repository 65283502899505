import {
  REORDER_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  LOAD_MORE_PRODUCTS,
  LOAD_MORE_PRODUCTS_SUCCESS,
  LOAD_MORE_PRODUCTS_FAILURE,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS_FAILURE,
  SET_BRANDS_LIST,
  ADD_PRODUCT,
  DELETE_PRODUCTS,
  CLEAR_PRODUCT_LIST_ERROR_STATE,
  CLEAR_PRODUCT_LIST_REDUX_STATE,
  SET_SHOW_LIMIT_TO_PRODUCTS,
  GET_FILTER_OPTIONS_FOR_PRODUCTS,
  GET_FILTER_OPTIONS_FOR_PRODUCTS_FAILURE,
  REMOVE_FILTER_OPTIONS_FOR_PRODUCTS,
  TOGGLE_CHECK_FILTER_OPTION_FOR_PRODUCTS,
  SET_FILTER_OPTION_FOR_PRODUCTS_ALL,
  SET_FILTER_OPTION_FOR_PRODUCTS_ALL_SUCCESS,
  SET_FILTER_OPTION_FOR_PRODUCTS_ALL_FAILURE,
} from 'store/actions/product/product';

import baseReducerHandler from 'util/baseReducerHandler'

import { reorderProductList } from './helpers/productListManipulate/productListManipulate';
import { getProductListSuccess } from './helpers/getProductList/getProductList';
import { loadMoreProductSuccess } from './helpers/loadProductList/loadProductList';
import addProduct from './helpers/addProduct/addProduct';
import { searchProducts, searchProductsSuccess } from './helpers/searchProducts/searchProducts';
import toggleCheckFilterOption from './helpers/toggleCheckFilterOption/toggleCheckFilterOption';
import deleteProducts from './helpers/deleteProducts/deleteProducts';
import setShowLimitForElements from 'util/setShowLimitForElements';
import { STORE_TYPES } from '../../../constants/stores';

const initialState = {
  payload: [],
  filterOptions: [],
  brands: [],
  loading: false,
  error: null,
  showLimit: setShowLimitForElements(150),
  loadedProductsOnScroll: false,
  searchQuery: STORE_TYPES.reduce((acc, item) => {
    acc[item] = '';
    return acc;
  }, {})

};

export default (state = initialState, action) => {
  switch (action.type) {
    case REORDER_PRODUCT:
      return reorderProductList(state, action);

    case DELETE_PRODUCTS:
      return deleteProducts(state, action);

    case GET_PRODUCTS:
      return baseReducerHandler(state, true, null);
    case GET_PRODUCTS_FAILURE:
      return baseReducerHandler({...state, payload: []}, false, action.error ? action.error : null);
    case GET_PRODUCTS_SUCCESS:
      return getProductListSuccess(state, action);

    case LOAD_MORE_PRODUCTS:
      return { ...state, loadedProductsOnScroll: true };
    case LOAD_MORE_PRODUCTS_SUCCESS:
      return loadMoreProductSuccess(state, action);
    case LOAD_MORE_PRODUCTS_FAILURE:
      return baseReducerHandler({...state, loadedProductsOnScroll: false}, false, action.error ? action.error : null);

    case SEARCH_PRODUCTS:
      return searchProducts(state, action);
    case SEARCH_PRODUCTS_SUCCESS:
      return searchProductsSuccess(state, action);
    case SEARCH_PRODUCTS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);


    case ADD_PRODUCT:
      return addProduct(state, action);

    case CLEAR_PRODUCT_LIST_ERROR_STATE:
      return baseReducerHandler(state, false, null);

    case CLEAR_PRODUCT_LIST_REDUX_STATE:
      return baseReducerHandler(initialState, false, null);

    case SET_SHOW_LIMIT_TO_PRODUCTS:
      return { ...state, showLimit: action.val };

    case GET_FILTER_OPTIONS_FOR_PRODUCTS:
      return { ...state, filterOptions: action.payload };
    case GET_FILTER_OPTIONS_FOR_PRODUCTS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SET_BRANDS_LIST:
      return { ...state, brands: action.payload };

    case REMOVE_FILTER_OPTIONS_FOR_PRODUCTS:
      return { ...state, filterOptions: [] };

    case TOGGLE_CHECK_FILTER_OPTION_FOR_PRODUCTS:
      return toggleCheckFilterOption(state, action);

    case SET_FILTER_OPTION_FOR_PRODUCTS_ALL:
      return { ...state, loading: true };

    case SET_FILTER_OPTION_FOR_PRODUCTS_ALL_SUCCESS:
      return {
        ...state,
        filterOptions: state.filterOptions.map(
          categotry => ({...categotry, selectedInPublications: action.select})),
        loading: false
      };

    case SET_FILTER_OPTION_FOR_PRODUCTS_ALL_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    default:
      return state;
  }
};
