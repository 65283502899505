import validUrl from 'util/validUrl';
import validEmail from 'util/validEmail';
import validName from 'util/validName';
import {checkEmailExistFunc} from 'store/actions/auth/auth'

export function validate(state, fieldName, value) {
  let fieldValidationErrors = state.formErrors;
  let firstNameValid = state.firstNameValid;
  let lastNameValid = state.lastNameValid;
  let websiteValid = state.websiteValid;
  let phoneValid = state.phoneValid;
  let emailValid = state.emailValid;

  switch (fieldName) {
    case 'firstName':
      if (value.length === 0) {
        firstNameValid = false;
        fieldValidationErrors.firstName = '';
      } else if (validName(value)) {
        firstNameValid = true;
        fieldValidationErrors.firstName = '';
      } else {
        firstNameValid = false;
        fieldValidationErrors.firstName = 'First name incorrect';
      }
      break;
    case 'lastName':
      if (value.length === 0) {
        lastNameValid = false;
        fieldValidationErrors.lastName = '';
      } else if (validName(value)) {
        lastNameValid = true;
        fieldValidationErrors.lastName = '';
      } else {
        lastNameValid = false;
        fieldValidationErrors.lastName = 'Last name incorrect';
      }
      break;
    case 'website':
      if (value.length === 0) {
        websiteValid = false;
        fieldValidationErrors.website = '';
      } else if(validUrl(value, 100)){
        websiteValid = true;
        fieldValidationErrors.website = '';
      } else {
        websiteValid = false;
        fieldValidationErrors.website = ' is invalid';
      }
      break;
    case 'phone':
      phoneValid = true;
      fieldValidationErrors.phone = '';
      break;
    case 'email':
      const sameEmail = value === state.oldEmail;

      if (sameEmail) {
        emailValid = true;
        fieldValidationErrors.email = '';
      } else if (value.length === 0) {
        emailValid = false;
        fieldValidationErrors.email = '';
      } else if (validEmail(value)) {
        emailValid = true;
        fieldValidationErrors.email = '';

        checkEmailExistFunc(value).then(res => {
          if (res.emailAlreadyUsed) {
            fieldValidationErrors.email = 'Another account using this email, try one more time';
            emailValid = false;
          }
        });
      } else {
        fieldValidationErrors.email = 'Email is invalid';
        emailValid = false;
      }
      break;

    default:
      break;
  }

  let validationData = {
    formErrors: fieldValidationErrors,
    firstNameValid: firstNameValid,
    lastNameValid: lastNameValid,
    websiteValid: websiteValid,
    phoneValid: phoneValid,
    emailValid: emailValid,
  };

  return {
    ...validationData,
    formValid: validateForm(validationData),
  };
}

function validateForm(validationData) {
  return validationData.firstNameValid && validationData.lastNameValid && validationData.emailValid && validationData.websiteValid && validationData.phoneValid;
}
