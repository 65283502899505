import {
  SET_COMPETITORS,
  ADD_COMPETITOR,
  REMOVE_COMPETITOR,
  TOGGLE_STATUS_COMPETITOR
} from 'store/actions/competitors';

const initialState = {
  list: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPETITORS:
      return { ...state, list: action.payload };

    case ADD_COMPETITOR:
      const nextCompetitors = [action.payload, ...state.list].reduce((acc, account) => {
        const foundItem = acc.find(accountInAcc => accountInAcc.id === account.id);

        if (!foundItem) acc.push(account);

        return acc;
      }, []);

      return { ...state, list: nextCompetitors };

    case REMOVE_COMPETITOR:
      return { ...state, list: state.list.filter(item => item.id !== action.copmetitorId) };

    case TOGGLE_STATUS_COMPETITOR:
      return {
        ...state,
        list: state.list.map(item => ({
          ...item,
          status: item.id === action.copmetitorId ? action.status.toUpperCase() : item.status
        }))
      };

    default:
      return state;
  }
};
