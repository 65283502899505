export default function removeDateFromBlackList(state, action) {
  const { accountId, socialObjId, date } = action;
  let blackList = { ...state.blackList };
  const idItemFromBlackList = `${accountId}${socialObjId}`;

  if (blackList[idItemFromBlackList]) {
    blackList[idItemFromBlackList] = blackList[idItemFromBlackList].filter( dateItem => !(dateItem.indexOf(date)+1) )
  }

  return { ...state, blackList };
}
