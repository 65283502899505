import {
  GET_UPLOADS_PENDING,
  GET_UPLOADS_SUCCESS,
  GET_UPLOADS_FAIL,
  CLEAR_MY_UPLOADS_ERROR,
  ADD_UPLOAD_TO_UPLOADS,
  DELETE_UPLOADED_FILE,
  UPDATE_UPLOADED_FILE,
  UPLOAD_MEDIA_TO_CLOUD
} from 'store/actions/cloud';
import { getStateWithUpdatedFile } from './helpers/getStateWithUpdatedFile';
import getMediaListName from 'util/getMediaListName';

const initialState = {
  myUploads: {
    image: {
      list: [],
      count: 0,
      didFirstLoad: false,
      isLoading: false,
      error: ''
    },
    video: {
      list: [],
      count: 0,
      didFirstLoad: false,
      isLoading: false,
      error: ''
    }
  },
  teamUploads: {
    image: {
      list: [],
      count: 0,
      didFirstLoad: false,
      isLoading: false,
      error: ''
    },
    video: {
      list: [],
      count: 0,
      didFirstLoad: false,
      isLoading: false,
      error: ''
    }
  }
};

export default (state = initialState, action) => {
  const payload = action?.payload;
  const mediaType = payload?.mediaType;
  const mediaSection = payload?.mediaSection; // MY or TEAM
  const mediaListType = getMediaListName(mediaSection);

  switch (action.type) {
    case GET_UPLOADS_PENDING:
      return {
        ...state,
        [mediaListType]: {
          ...state[mediaListType],
          [mediaType]: {
            ...state[mediaListType][mediaType],
            isLoading: true
          }
        }
      };

    case GET_UPLOADS_SUCCESS:
      return {
        ...state,
        [mediaListType]: {
          ...state[mediaListType],
          [mediaType]: {
            ...state[mediaListType][mediaType],
            didFirstLoad: true,
            isLoading: false,
            list: [...state[mediaListType][mediaType].list, ...payload.list],
            count: payload.count
          }
        }
      };

    case ADD_UPLOAD_TO_UPLOADS:
      return {
        ...state,
        myUploads: {
          ...state.myUploads,
          [mediaType]: {
            ...state.myUploads[mediaType],
            list: [payload.entity, ...state.myUploads[mediaType].list],
            count: state.myUploads[mediaType].count + 1
          }
        }
      }

    case DELETE_UPLOADED_FILE:
      return {
        ...state,
        [mediaListType]: {
          ...state[mediaListType],
          [mediaType]: {
            ...state[mediaListType][mediaType],
            list: state[mediaListType][mediaType].list.filter(file => file.id !== action.payload.id),
            count: state[mediaListType][mediaType].count - 1
          }
        }
      };

    case UPDATE_UPLOADED_FILE:
      return getStateWithUpdatedFile(state, action, action.payload.newProps);

    case GET_UPLOADS_FAIL:
      return {
        ...state,
        myUploads: {
          ...state.myUploads,
          [mediaType]: {
            ...state.myUploads[mediaType],
            isLoading: false,
            error: payload.error
          }
        }
      };

    case UPLOAD_MEDIA_TO_CLOUD:
      return {
        ...state,
        myUploads: {
          ...state.myUploads,
          [mediaType]: {
            ...state.myUploads[mediaType],
            list: [payload.file, ...state.myUploads[mediaType].list],
            count: state.myUploads[mediaType].count + 1
          }
        }
      };

    case CLEAR_MY_UPLOADS_ERROR:
      return {
        ...state,
        [mediaListType]: {
          ...state[mediaListType],
          [mediaType]: {
            ...state[mediaListType][mediaType],
            error: ''
          }
        }
      };

    default:
      return state;
  }
};
