export default video => {
  return {
    ...video,
    // IsRequered for plugin react-select
    value: video.id,
    label: video.title
  }
};

export const returnObjForFilterOptionVideo = playlist => {
  return {
    ...playlist,
    value: playlist.id,
    label: playlist.title
  }
};
