export default function filterSocialPagesBySocialType(socialPagesFromApi, socialType) {
  let result = socialPagesFromApi.filter(page => page.socialNetworkType === socialType);

  return changeResultForSelectSocialPages(result);
}

function changeResultForSelectSocialPages(arr) {
  let result = [];
  arr.forEach(val =>
    result.push({
      key: val.id,
      value: val.id,
      label: val.name,
      accounts: val.accounts,
    }),
  );
  return result;
}
