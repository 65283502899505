import axios from 'axios';

export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_FAILURE = 'ADD_TEMPLATE_FAILURE';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE';

export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
export const REMOVE_TEMPLATE_SUCCESS = 'REMOVE_TEMPLATE_SUCCESS';
export const REMOVE_TEMPLATE_FAILURE = 'REMOVE_TEMPLATE_FAILURE';

export const CLEAR_TEMPLATE_ERROR_STATE = 'CLEAR_TEMPLATE_ERROR_STATE';

export function addTemplateAction(data) {
  return dispatch => {
    dispatch({ type: ADD_TEMPLATE });

    return axios
      .post(`/api/templates`, data)
      .then(res => {
        if (res.status === 201 || res.status === 200) {
          dispatch({ type: ADD_TEMPLATE_SUCCESS });
          return Promise.resolve(res);
        } else {
          dispatch({ type: ADD_TEMPLATE_FAILURE, error: res });
          return Promise.resolve(res);
        }
      })
      .catch(error => {
        dispatch({ type: ADD_TEMPLATE_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function getTemplatesAction(page, perPage) {
  return dispatch => {
    dispatch({ type: GET_TEMPLATES });

    return axios
      .get(`/api/templates?page=${page}&size=${perPage}`)
      .then(res => {
        dispatch({
          type: GET_TEMPLATES_SUCCESS,
          payload: res.data,
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: GET_TEMPLATES_FAILURE });
        return Promise.resolve(error);
      });
  };
}

export function removeTemplateAction(id) {
  return dispatch => {
    dispatch({ type: REMOVE_TEMPLATE });

    return axios
      .delete(`/api/templates/${id}`)
      .then(res => {
        dispatch({ type: REMOVE_TEMPLATE_SUCCESS });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: REMOVE_TEMPLATE_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function clearTemplateErrorState() {
  return dispatch => {
    dispatch({
      type: CLEAR_TEMPLATE_ERROR_STATE,
    });
  };
}
