import {
  ADD_TOPIC,
  ADD_TOPIC_SUCCESS,
  ADD_TOPIC_FAILURE,
  GET_TOPICS,
  GET_TOPICS_SUCCESS,
  GET_TOPICS_FAILURE,

  // EDIT_TOPIC,
  // EDIT_TOPIC_SUCCESS,
  // EDIT_TOPIC_FAILURE,
  REMOVE_TOPIC,
  REMOVE_TOPIC_SUCCESS,
  REMOVE_TOPIC_FAILURE,
  CLEAR_TOPIC_ERROR_STATE,
} from '../../actions/topic';
import baseReducerHandler from 'util/baseReducerHandler';
import { getTopicsSuccess } from './getTopics';

const initialState = {
  payload: [],
  maxAmount: false,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOPIC:
      return baseReducerHandler(state, true, null);
    case ADD_TOPIC_SUCCESS:
    case ADD_TOPIC_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case GET_TOPICS:
      return baseReducerHandler(state, true, null);
    case GET_TOPICS_SUCCESS:
      return getTopicsSuccess(state, action);
    case GET_TOPICS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case REMOVE_TOPIC:
      return baseReducerHandler(state, true, null);
    case REMOVE_TOPIC_SUCCESS:
    case REMOVE_TOPIC_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case CLEAR_TOPIC_ERROR_STATE:
      return baseReducerHandler(state, false, null);

    default:
      return state;
  }
};
