import reorder from 'util/reorder';

export function reorderProductList(state, action) {
  const draggableProduct = state.payload.find(product => product.id === action.productId);

  const productsByShopType = state.payload.filter(
    product => product.shop.type === draggableProduct.shop.type
  );
  const otherProducts = state.payload.filter(
    product => product.shop.type !== draggableProduct.shop.type
  );

  const sortedPayload = [...productsByShopType, ...otherProducts];
  const newProductList = reorder(sortedPayload, action.oldId, action.newId);

  return Object.assign({}, state, {
    payload: newProductList
  });
}
