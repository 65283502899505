import React from 'react';


export const tourPostModalSteps = [
  {
    selector: '[data-tour-id="chooseSocialMedia"]',
    content: <>
      <h3>Choose where to post</h3>
      <p>Choose a social media page where you want to schedule a post.</p>
    </>,
  },
  {
    selector: '[data-tour-id="chooseSource"]',
    content: <>
      <h3>Choose content source</h3>
      <p>Choose the content source and define what you want to post. </p>
    </>,
  },
  {
    selector: '[data-tour-id="postModalContent"]',
    content: <>
      <h3>Add and edit content</h3>
      <p>Modify text, images, and add content directly from the source with one click.</p>
    </>,
  },
  {
    selector: '[data-tour-id="postModalActions"]',
    content: <>
      <h3>Schedule a post</h3>
      <p>Schedule good content at the right time. Onollo knows when is the best time to make a post.</p>
    </>,
  },
  {
    selector: '',
    content: <>
      <h3>Congrats, you made it!</h3>
      <p>
        Congrats, you’ve done it!
        Now you know how to schedule a post
        with Onollo <span role="img" aria-label="perfect">🙂</span>
      </p>
    </>,
  },
];
