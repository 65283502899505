import setSocialTypes from './utils/setSocialTypes';

export function toggleAccountStatusSuccess(state, action) {
  const newPayload = state.payload.map(account => {
    if (account.id === action.id) {
      return {
        ...account,
        status: action.newStatus,
        objects: account.objects
          ? account.objects.map(page => {
              return {
                ...page,
                status: !action.pageId ? action.newStatus : page.status
              };
            })
          : []
      };
    } else {
      return account;
    }
  });

  let newActivePayload;

  const findedAccount = newPayload.find(account => account.id === action.id);

  if (action.newStatus === 'INACTIVE' || action.pageId) {
    let inactiveId = [...findedAccount.objects.map(account => account.id), action.id];
    newActivePayload = state.activePayload.filter(account => inactiveId.indexOf(account.id) === -1);
    if (action.pageId) {
      newActivePayload = [
        ...state.activePayload,
        findedAccount,
        findedAccount.objects.filter(page => {
          return page.id === action.pageId;
        })
      ];
    }
  } else {
    newActivePayload = [...state.activePayload, findedAccount, ...findedAccount.objects];
  }

  const nextActiveSocialType = newActivePayload.find(account => account.type !== 'GOOGLE')?.type;

  return Object.assign({}, state, {
    payload: newPayload,
    activePayload: newActivePayload,
    socialTypes: setSocialTypes(newActivePayload),
    activeSocialType: nextActiveSocialType || ''
  });
}
