import sortBy from 'lodash/sortBy';
import setSocialTypes from './utils/setSocialTypes';


export function getAccount(state, action) {

  let activePayload = state.activePayload.filter(account => account.id !== action.account.id);

  const findedAccount = state.payload.find(acc => acc.id === action.account.id);
  const safeObject = findedAccount ? findedAccount.objects : action.account.objects ? action.account.objects : [];

  let payload = findedAccount ? state.payload.map(acc => acc.id === action.account.id ? { ...acc, ...action.account, objects: safeObject } : acc)
    : sortBy([...state.payload, action.account], ['name']);

  if (action.account.status === 'ACTIVE') {
    activePayload = [...activePayload, payload.find(acc => acc.id === action.account.id)];
    activePayload = sortBy(activePayload, ['name']);
  }

  const socialTypes = setSocialTypes(activePayload);

  return {
    ...state,
    payload,
    activePayload,
    socialTypes
  };
}

export function getPage(state, action) {
  return {
    ...state,
    ..._addPageToAccount(state, action.page)
  };
}

export function removePage(state, action) {
  return {
    ...state,
    ..._removePageFromAccount(state, action.id)
  };
}

// Helpers
function _addPageToAccount(state, page) {
  const copyPayload = state.payload.slice();
  const copyActivePayload = state.activePayload.slice();

  // Add page to account objects
  try {
    for (let account of copyPayload) {
      if (page.accounts && !page.accounts.some(a => a.id === account.id)) {
        continue;
      }

      let objects = account.objects;
      const isNewPage = objects && objects.every(objectsPage => objectsPage.id !== page.id);


      if (isNewPage || !objects) {

        // Push new page to account objects
        if (page.accounts && page.accounts.find(acc => acc.id === account.id)) {
          account.objects = !objects ? [page] : sortBy([...objects, page], ['name']);
          // Add page to active payload if the page is active and page doesn't exists
          let objectIndex = copyActivePayload.findIndex(o => o.id === page.id);
          if (page.status === 'ACTIVE') {
            if (objectIndex === -1) {
              copyActivePayload.push(page);
            } else {
              copyActivePayload[objectIndex].selectedInPublications = page.selectedInPublications;
              copyActivePayload[objectIndex].selectedInAnalytics = page.selectedInAnalytics;
            }
          } else if (page.status !== 'ACTIVE') {
            // remove social object from active payload
            if (objectIndex > -1) {
              copyActivePayload.splice(objectIndex, 1);
            }
          }
        }
      } else {

        //update suggestedPostTimes time
        const findPageIndexFromObjects = account.objects && account.objects.findIndex(obj => obj.id === page.id);
        if (findPageIndexFromObjects && findPageIndexFromObjects > -1) {
          account.objects[findPageIndexFromObjects] = {...account.objects[findPageIndexFromObjects], ...page};
        }

        const findPageIndexFromActivePayload =  copyActivePayload.findIndex(obj => obj.id === page.id);
        if (findPageIndexFromActivePayload > -1) {
          copyActivePayload[findPageIndexFromActivePayload] = {...copyActivePayload[findPageIndexFromActivePayload], ...page};
        }
      }
    }
  } catch (err) {
    console.log(err);
  }

  return {
    payload: copyPayload,
    activePayload: copyActivePayload,
    socialTypes: setSocialTypes(copyActivePayload)
  };
}

function _removePageFromAccount(state, pageId) {
  // Remove page from active account payload
  const copyActivePayload = state.activePayload.filter(page => !(page.accounts && page.id === pageId));

  // Remove page from account payload
  const copyPayload = [...state.payload];
  for (let i = 0; i < copyPayload.length; i++) {
    if (copyPayload[i].objects && copyPayload[i].objects.length && copyPayload[i].objects.some(page => page.id === pageId)) {
      copyPayload[i].objects = copyPayload[i].objects.filter(page => page.id !== pageId)
      break;
    }
  }


  return {
    payload: copyPayload,
    activePayload: copyActivePayload
  };
}
