import { getImageFromSourceData, getUrlFromSourceData } from 'util/getInfoFromSourceData';

export default (state, action) => {
  const link = setLink(state, action);

  const images = action.withImg ? getImageFromSourceData(state.sourceType, action.option) : [];

  return {
    ...state,
    link,
    sourceItem: action.option,
    dataForSourceItemsSelect: {
      ...state.dataForSourceItemsSelect,
      value: action.option
    },
    images,
    videos: []
  };
};

const setLink = (state, action) => {
  const { sourceType, link } = state;

  if (link.length) {
    return getUrlFromSourceData(sourceType, action.option);
  }

  return link;
};
