import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import spriteSvg from 'assets/images/sprite.svg';
import closeGray from 'assets/images/close-gray.svg';

export default class ModalComponent extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    children: PropTypes.element.isRequired,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    closeOnOverlayClick: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    modalPadding: PropTypes.string,
    className: PropTypes.string
  };

  static defaultProps = {
    closeOnOverlayClick: true
  };

  getOverlayBackground() {
    return 'rgba(56, 65, 68, 0.8)';
  }

  render() {
    const {
      isOpen,
      children,
      closeModal,
      center = true,
      width,
      closeOnOverlayClick,
      hideCloseButton = false,
      dark = false,
      withPadding = false,
      modalPadding,
      className
    } = this.props;

    const modalStyle = {
      overlay: {
        backgroundColor: this.getOverlayBackground()
      },
      modal: {
        display: 'block',
        maxWidth: '100%',
        width: width ? `${width}px` : '940px',
        padding: hideCloseButton
          ? modalPadding || '40px 0 30px'
          : withPadding
          ? '60px 30px 50px'
          : modalPadding || '60px 0 50px',
        margin: center ? 'auto' : '30px auto',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 24px 0 rgba(56, 65, 68, 0.17)'
      },
      closeButton: {
        position: 'absolute',
        display: hideCloseButton ? 'none' : 'block',
        top: '30px',
        right: '30px',
        background: dark
          ? `url(${closeGray}) no-repeat -20px -21px`
          : `url(${spriteSvg}) no-repeat -156px -96px`,
        width: '19px',
        height: '18px',
        border: '0',
        outline: 'none',
        cursor: 'pointer'
      }
    };

    return (
      <Modal
        open={isOpen}
        classNames={{ modal: className }}
        styles={modalStyle}
        onClose={closeModal}
        closeIconSvgPath={<path />}
        center={center}
        closeOnOverlayClick={closeOnOverlayClick}
      >
        {children}
      </Modal>
    );
  }
}
