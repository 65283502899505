import axios from 'axios';

import { MAX_TEMPLATES_COUNT } from 'constants/template';

export const autopilotAPI = {
  getList() {
    return axios.get('/api/autopilot/campaigns/all').then(response => response.data);
  },

  saveStart(autopilotObject) {
    return axios
      .post('/api/autopilot/campaigns/run', autopilotObject)
      .then(response => response.data);
  },
  saveDraft(autopilotObject) {
    return axios
      .post('/api/autopilot/campaigns/saveDraft', autopilotObject)
      .then(response => response.data);
  },

  edit(autopilotId, autopilotObject) {
    return axios
      .put(`/api/autopilot/campaigns/${autopilotId}/run`, autopilotObject)
      .then(response => response.data);
  },

  editDraft(autopilotId, autopilotObject) {
    return axios
      .put(`/api/autopilot/campaigns/${autopilotId}/saveDraft`, autopilotObject)
      .then(response => response.data);
  },

  active(autopilotId) {
    return axios
      .put(`/api/autopilot/campaigns/${autopilotId}/active`)
      .then(response => response.data);
  },

  inactive(autopilotId) {
    return axios
      .put(`/api/autopilot/campaigns/${autopilotId}/inactive`)
      .then(response => response.data);
  },

  delete(autopilotId) {
    return axios.delete(`/api/autopilot/campaigns/${autopilotId}`).then(response => response.data);
  },

  getRandomObject(options, objectType) {
    //objectType - profuct, video, etc
    return axios
      .post(`/api/autopilot/${objectType}/random`, options)
      .then(response => response.data);
  }
};

export const templateAPI = {
  getList() {
    return axios
      .get(`/api/templates?size=${MAX_TEMPLATES_COUNT}&offset=0`)
      .then(response => response.data);
  },

  save(templateObject) {
    return axios.post('/api/templates', templateObject).then(response => response.data);
  },

  edit(templateObject) {
    return axios.put(`/api/templates`, templateObject).then(response => response.data);
  },

  active(templateId) {
    return axios.put(`/api/templates/${templateId}/active`).then(response => response.data);
  },

  inactive(templateId) {
    return axios.put(`/api/templates/${templateId}/inactive`).then(response => response.data);
  },

  delete(templateId) {
    return axios.delete(`/api/templates/${templateId}`).then(response => response.data);
  }
};

export const postAPI = {
  getPreviewContent(contentObject) {
    return axios
      .post('/api/posts/plain-text-content', contentObject)
      .then(response => `${response.data}`);
  }
};

export const accountAPI = {
  checkAccount(accountId) {
    return axios.get(`/api/verify-social-account/${accountId}`).then(response => response.data);
  },
  sync(id) {
    //sync video channels
    return axios.put(`/api/social-network-accounts/${id}/sync`);
  },
  isSyncing(id) {
    //check sync
    return axios.get(`/api/social-network-accounts/${id}/is-syncing`);
  }
};

export const productAPI = {
  search(showLimit, offset = 0, cursor = null, pageNumber, query, shopId) {
    return axios.get('/api/productsV2', {
      params: {
        query,
        offset,
        cursor,
        pageNumber,
        size: showLimit,
        shopId
      }
    });
  },
  getByExternalId(shopType, shopId, externalId) {
    return axios.get('/api/productV2', {
      params: {
        shopType: shopType,
        shopExternalId: shopId,
        externalId
      }
    });
  },
  getBrands(shopId) {
    return axios.get(`/api/products/brands`, {
      params: {
        shopId
      }
    });
  },
  getFilterOptions(size = 20, offset = 0, cursor = null, pageNumber, shopId, query = null) {
    return axios.get(`/api/product-categoriesV2`, {
      params: {
        size,
        offset,
        pageNumber,
        cursor,
        searchQuery: query?.length ? query : undefined,
        shopId
      }
    });
  },
  setFilter(id, type, section = 'publications') {
    return axios.put(`/api/product-categories/${id}/${section}/${type}`);
  },
  setFilterAll(type, shopType, section) {
    return axios
      .put(`/api/product-categories/all/${section}${shopType ? `/${shopType}` : ''}/${type}`)
      .then(response => response.data);
  }
};

export const analyticsAPI = {
  totalStats() {
    return axios.get('/api/analytics/total-stats').then(response => response.data);
  },
  appPurchasesStats() {
    return axios.get('/api/analytics/app-purchases-stats');
  }
};

export const videoAPI = {
  getList(size = 20, offset = 0) {
    return axios.get(`/api/videos?size=${size}&offset=${offset}`); //&eagerLoad=true with channel
  },
  search(size = 20, offset = 0, query) {
    return axios.get(`/api/videos/search?query=${query}&size=${size}&offset=${offset}`);
  },
  getVideo(id) {
    return axios.get(`/api/videos/${id}`).then(response => response.data);
  },
  getTotalCount(query) {
    if (query && query.length) {
      return axios.get(`/api/videos/search/count?query=${query}`).then(response => response.data);
    }
    return axios.get('/api/videos/count').then(response => response.data);
  },
  getFilterOptions(size = 10000, offset = 0) {
    //return response for async select
    return axios.get(`/api/video-playlists?size=${size}&offset=${offset}`);
  },
  getPlaylist(id) {
    return axios.get(`/api/video-playlists/${id}`).then(response => response.data);
  },
  getChannels(size = 50, offset = 0) {
    return axios
      .get(`/api/video-channels?size=${size}&offset=${offset}`)
      .then(response => response.data);
  },
  getChannel(id) {
    return axios.get(`/api/video-channels/${id}`).then(response => response.data);
  },
  setFilterOptions({ id, selectedInPublications }, section) {
    return axios
      .put(
        `/api/video-playlists/${id}/${section}/${selectedInPublications ? 'unselect' : 'select'}`
      )
      .then(response => response.data);
  },
  setFilterAll(type, section) {
    return axios.put(`/api/video-playlists/all/${section}/${type}`).then(response => response.data);
  }
};

export const shopAPI = {
  magentoAuth(successCallBack, oauthConsumerKey) {
    return axios.post('/api/magento/authorization', { successCallBack, oauthConsumerKey });
  },
  wooCommerceAuth(shopUrl, oauthConsumerKey, oauthConsumerSecret) {
    return axios.post('/api/woocommerce/authorization', {
      shopUrl,
      oauthConsumerKey,
      oauthConsumerSecret
    });
  }
};

export const profileAPI = {
  toggleSubscription(userId, currentStatus) {
    return axios.put(`/api/users/${userId}/${currentStatus ? 'unsubscribe' : 'subscribe'}`);
  }
};

export const contentCloudAPI = {
  getList(type, section, own, size, offset = 0) {
    return axios.get(
      '/api/content/all', {
        params: {
          size,
          offset,
          type,
          section,
          own
        }
      }
    );
  },
  searchTags(size, offset = 0, search, sort = 'ALPHABET', reverse = false, myCompany = true) {
    return axios.get(
      '/api/content/tags', {
        params: {
          search,
          sort,
          reverse,
          size,
          offset,
          myCompany
        }
      }
    );
  }
};

export const teamAPI = {
  getTeam(size, offset, state = 'APPROVED', status = 'ACTIVE') {
    return axios.get(
      '/api/team', {
        params: {
          size,
          offset,
          status,
          state
        }
      }
    );
  },
};
