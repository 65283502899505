export function prepareToRemovePost(state, action) {
  return Object.assign({}, state, {
    payload: state.payload.map(day => {
      if(day.day === action.sourceDay){
        return {
          ...day,
          postsList: day.postsList.map(post => {
            if(post.id === action.id){
              return {
                ...post,
                startRemove: true,
                autoTriggerDataPicker: false
              }
            } else {
              return post
            }
          })
        }
      } else {
        return day;
      }
    })
  });
}

export function cancelPrepareToRemovePost(state, action) {
  return Object.assign({}, state, {
    payload: state.payload.map(day => {
      if(day.day === action.sourceDay){
        return {
          ...day,
          postsList: day.postsList.map(post => {
            if(post.id === action.id){
              return {
                ...post,
                startRemove: false,
              }
            } else {
              return post
            }
          })
        }
      } else {
        return day;
      }
    })
  });
}

export function removePostSuccess(state, action) {
  const newPayload = state.payload.map(day => {
    if(day.day === action.sourceDay){
      return {
        ...day,
        postsList: day.postsList.filter(post => post.id !== action.id),
      }
    } else {
      return day
    }
  });

  return Object.assign({}, state, {
    loading: false,
    error: null,
    payload: newPayload,
  });
}
