export const SET_COMPETITORS = 'SET_COMPETITORS';
export const ADD_COMPETITOR = 'ADD_COMPETITOR';
export const REMOVE_COMPETITOR = 'REMOVE_COMPETITOR';
export const TOGGLE_STATUS_COMPETITOR = 'TOGGLE_STATUS_COMPETITOR';

export function setCompetitors(payload) {
  return dispatch => {
    dispatch({ type: SET_COMPETITORS, payload });
  };
}

export function addCompetitor(payload) {
  return dispatch => {
    dispatch({ type: ADD_COMPETITOR, payload });
  };
}

export function removeCompetitor(copmetitorId) {
  return dispatch => {
    dispatch({ type: REMOVE_COMPETITOR, copmetitorId });
  };
}

export function toggleStatusCompetitor(copmetitorId, status) {
  return dispatch => {
    dispatch({ type: TOGGLE_STATUS_COMPETITOR, copmetitorId, status });
  };
}
