import format from 'date-fns/format';
import {
  INIT_CALENDAR_PENDING,
  INIT_CALENDAR_SUCCESS,
  INIT_CALENDAR_FAILURE,
  PUT_TO_CALENDAR,
  PUT_TO_CALENDAR_SUCCESS,
  PUT_TO_CALENDAR_FAILURE,
  SCHEDULE_POST,
  SCHEDULE_POST_SUCCESS,
  SCHEDULE_POST_FAILURE,
  REMOVE_POST,
  REMOVE_POST_SUCCESS,
  REMOVE_POST_FAILURE,
  PREPARE_TO_REMOVE_POST,
  CANCEL_PREPARE_TO_REMOVE_POST,
  REMOVE_POST_FROM_CALENDAR,
  REMOVE_POST_FROM_CALENDAR_SUCCESS,
  REMOVE_POST_FROM_CALENDAR_FAILURE,
  PUBLISH_POST_NOW,
  PUBLISH_POST_NOW_SUCCESS,
  PUBLISH_POST_NOW_FAILURE,
  FILL_CALENDAR_NEW_POSTS,
  FILL_CALENDAR_NEW_POSTS_FAILURE,
  CLEAR_CALENDAR_ERROR_STATE,
  DELETE_POSTS_FROM_CALENDAR,
  LOAD_MORE_POSTS_TO_DAY_PENDING,
  LOAD_MORE_POSTS_TO_DAY_SUCCESS,
  LOAD_MORE_POSTS_TO_DAY_FAIL,
  CLEAR_ERROR_IN_CALENDAR_DAY
} from '../../actions/calendar/calendar';
import baseReducerHandler from 'util/baseReducerHandler';
import { removePostFromCalendar } from './removePostFromCalendar';
import { putToCalendar, setPostToDay } from './putToCalendar';
import { removePostSuccess, prepareToRemovePost, cancelPrepareToRemovePost } from './removePost';
import { fillCalendarNewPosts } from './fillCalendarNewPosts';
import { deletePostsFromCalendar } from './deletePostsFromCalendar';
import { getStateWithUpdatedDay } from './helpers/getStateWithUpdatedDay';
import { initCalendar } from './helpers/initCalendar';

const initialState = {
  payload: [],
  startDate: format(new Date(), 'y-MM-dd'),
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  const { type, payload } = action || {};

  switch (type) {
    case INIT_CALENDAR_PENDING:
      return { ...state, loading: true };

    case INIT_CALENDAR_SUCCESS:
      return initCalendar(state, action);

    case INIT_CALENDAR_FAILURE:
      return { ...state, loading: false, error: payload.error };

    case REMOVE_POST_FROM_CALENDAR:
      return removePostFromCalendar(state, action);
    case REMOVE_POST_FROM_CALENDAR_SUCCESS:
    case REMOVE_POST_FROM_CALENDAR_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case PUT_TO_CALENDAR:
      return putToCalendar(state, action);
    case PUT_TO_CALENDAR_SUCCESS:
    case PUT_TO_CALENDAR_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SCHEDULE_POST:
    case SCHEDULE_POST_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SCHEDULE_POST_SUCCESS:
      return {
        ...state,
        payload: setPostToDay(state.payload, action.day, action.post),
        loading: false,
        error: null
      };

    case PREPARE_TO_REMOVE_POST:
      return prepareToRemovePost(state, action);
    case CANCEL_PREPARE_TO_REMOVE_POST:
      return cancelPrepareToRemovePost(state, action);

    case REMOVE_POST:
      return baseReducerHandler(state, true, null);
    case REMOVE_POST_SUCCESS:
      return removePostSuccess(state, action);
    case REMOVE_POST_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case PUBLISH_POST_NOW:
    case PUBLISH_POST_NOW_SUCCESS:
    case PUBLISH_POST_NOW_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case FILL_CALENDAR_NEW_POSTS:
      return fillCalendarNewPosts(state, action);
    case FILL_CALENDAR_NEW_POSTS_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case CLEAR_CALENDAR_ERROR_STATE:
      return baseReducerHandler(state, false, null);

    case DELETE_POSTS_FROM_CALENDAR:
      return deletePostsFromCalendar(state, action);

    case LOAD_MORE_POSTS_TO_DAY_PENDING:
      return getStateWithUpdatedDay(state, payload.date, { loading: true });

    case LOAD_MORE_POSTS_TO_DAY_FAIL:
      return getStateWithUpdatedDay(state, payload.date, {
        loading: false,
        error: payload.error
      });

    case LOAD_MORE_POSTS_TO_DAY_SUCCESS:
      return getStateWithUpdatedDay(state, payload.date, {
        postsList: [
          ...state.payload[state.payload.findIndex(item => item.day === payload.date)].postsList,
          ...payload.posts
        ],
        count: payload.count,
        loading: false
      });

    case CLEAR_ERROR_IN_CALENDAR_DAY:
      return getStateWithUpdatedDay(state, payload.date, { error: '' });

    default:
      return state;
  }
};
