import {validate} from "./validate";

export function handleField(state, action) {
  const name = action.name;
  const value = action.value;

  return Object.assign({}, state, {
    [name]: value
  }, validate(state, name, value));
}
