import axios from 'axios';
import sortBy from 'lodash/sortBy';

//Constants
import { VIDEO_ACCOUNT_TYPES } from 'constants/accounts';
import { getChannelsAction } from 'store/actions/video/video';

import { accountAPI } from 'dal/api.js';

import { SOCIAL_TYPE_WITHOUT_PAGE } from 'constants/socialTypeWithoutPage';

export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const ADD_ACCOUNT_SUCCESS = 'ADD_ACCOUNT_SUCCESS';
export const ADD_ACCOUNT_FAILURE = 'ADD_ACCOUNT_FAILURE';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

export const TOGGLE_ACCOUNT_IN_PUBLICATION = 'TOGGLE_ACCOUNT_IN_PUBLICATION';
export const TOGGLE_ACCOUNT_IN_PUBLICATION_SUCCESS = 'TOGGLE_ACCOUNT_IN_PUBLICATION_SUCCESS';
export const TOGGLE_ACCOUNT_IN_PUBLICATION_FAILURE = 'TOGGLE_ACCOUNT_IN_PUBLICATION_FAILURE';

export const TOGGLE_ACCOUNT_IN_ANALYTICS = 'TOGGLE_ACCOUNT_IN_ANALYTICS';
export const TOGGLE_ACCOUNT_IN_ANALYTICS_SUCCESS = 'TOGGLE_ACCOUNT_IN_ANALYTICS_SUCCESS';
export const TOGGLE_ACCOUNT_IN_ANALYTICS_FAILURE = 'TOGGLE_ACCOUNT_IN_ANALYTICS_FAILURE';

export const TOGGLE_PAGE_IN_PUBLICATION = 'TOGGLE_PAGE_IN_PUBLICATION';
export const TOGGLE_PAGE_IN_PUBLICATION_SUCCESS = 'TOGGLE_PAGE_IN_PUBLICATION_SUCCESS';
export const TOGGLE_PAGE_IN_PUBLICATION_FAILURE = 'TOGGLE_PAGE_IN_PUBLICATION_FAILURE';

export const ACTIVATE_ALL_PAGES_IN_PUBLICATION = 'ACTIVATE_ALL_PAGES_IN_PUBLICATION';
export const ACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS =
  'ACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS';
export const ACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE =
  'ACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE';

export const DEACTIVATE_ALL_PAGES_IN_PUBLICATION = 'DEACTIVATE_ALL_PAGES_IN_PUBLICATION';
export const DEACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS =
  'DEACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS';
export const DEACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE =
  'DEACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE';

export const ACTIVATE_ALL_PAGES_IN_ANALYTICS = 'ACTIVATE_ALL_PAGES_IN_ANALYTICS';
export const ACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS = 'ACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS';
export const ACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE = 'ACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE';

export const DEACTIVATE_ALL_PAGES_IN_ANALYTICS = 'DEACTIVATE_ALL_PAGES_IN_ANALYTICS';
export const DEACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS =
  'DEACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS';
export const DEACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE =
  'DEACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE';

export const TOGGLE_PAGE_IN_ANALYTICS = 'TOGGLE_PAGE_IN_ANALYTICS';
export const TOGGLE_PAGE_IN_ANALYTICS_SUCCESS = 'TOGGLE_PAGE_IN_ANALYTICS_SUCCESS';
export const TOGGLE_PAGE_IN_ANALYTICS_FAILURE = 'TOGGLE_PAGE_IN_ANALYTICS_FAILURE';

export const TOGGLE_PAGE_STATUS = 'TOGGLE_PAGE_STATUS';
export const TOGGLE_PAGE_STATUS_FAILURE = 'TOGGLE_PAGE_STATUS_FAILURE';

export const TOGGLE_ACCOUNT_STATUS = 'TOGGLE_ACCOUNT_STATUS';
export const TOGGLE_ACCOUNT_STATUS_FAILURE = 'TOGGLE_ACCOUNT_STATUS_FAILURE';

export const SET_ACTIVE_SOCIAL_TYPE = 'SET_ACTIVE_SOCIAL_TYPE';

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const REMOVE_ACCOUNT = 'REMOVE_ACCOUNT';
export const REMOVE_ACCOUNT_FAILURE = 'REMOVE_ACCOUNT_FAILURE';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const REMOVE_PAGE = 'REMOVE_PAGE';

export const CHECK_PAGE_FOR_SCHEDULE_POSTS = 'CHECK_PAGE_FOR_SCHEDULE_POSTS';

export const SHOW_ACCOUNT_ERROR_CONNECT = 'ADD_ACCOUNTS_ERROR_STATE';

export const SET_ERROR_ACCOUNTS_STATE = 'SET_ERROR_ACCOUNTS_STATE';
export const CLEAR_ACCOUNTS_ERROR_STATE = 'CLEAR_ACCOUNTS_ERROR_STATE';
export const CLEAR_ACCOUNTS_REDUX_STATE = 'CLEAR_ACCOUNTS_REDUX_STATE';

export const SYNC_ACCOUNT = 'SYNC_ACCOUNT';
export const SYNC_ACCOUNT_SUCCESS = 'SYNC_ACCOUNT_SUCCESS';
export const SYNC_ACCOUNT_FAILURE = 'SYNC_ACCOUNT_FAILURE';

export function addAccountAction(data) {
  return dispatch => {
    dispatch({ type: ADD_ACCOUNT });

    return axios
      .post(`/api/social-network-accounts`, data)
      .then(res => {
        dispatch({ type: ADD_ACCOUNT_SUCCESS });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: ADD_ACCOUNT_FAILURE,
          error
        });
        return Promise.resolve(error);
      });
  };
}

export function getAccountsAction() {
  return dispatch => {
    dispatch({ type: GET_ACCOUNTS });

    return axios
      .get(`/api/social-network-accounts`)
      .then(res => {
        dispatch({
          type: GET_ACCOUNTS_SUCCESS,
          payload: res.data
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: GET_ACCOUNTS_FAILURE,
          error
        });
        return Promise.resolve(error);
      });
  };
}

export function updateAccountAction(account) {
  return dispatch => {
    dispatch({
      type: UPDATE_ACCOUNT,
      account
    });

    //For video
    if (account.type && VIDEO_ACCOUNT_TYPES.includes(account.type)) {
      dispatch(getChannelsAction());
    }
  };
}

export function removeAccountAction(id) {
  return dispatch => {
    return axios
      .delete(`/api/social-network-accounts/${id}`)
      .then(res => {
        dispatch({
          type: REMOVE_ACCOUNT,
          id
        });
        //For video
        dispatch(getChannelsAction());

        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: REMOVE_ACCOUNT_FAILURE,
          error
        });
        return Promise.reject(error);
      });
  };
}

export function checkAccount() {
  return (dispatch, getState) => {
    const state = getState();
    const payload = state.accountsList.payload;

    Promise.all(
      payload.map(account => {
        return accountAPI.checkAccount(account.id).then(res => {
          if (res === false) {
            dispatch({
              type: REMOVE_ACCOUNT,
              id: account.id
            });
          }
        });
      })
    );
  };
}

export function updatePageAction(object) {
  return dispatch => {
    dispatch({
      type: UPDATE_PAGE,
      object
    });
  };
}

export function removePageAction(id) {
  return dispatch => {
    dispatch({
      type: REMOVE_PAGE,
      id
    });
  };
}

export function toggleAccountInPublicationAction(id, nowSelectedStatus, socialType) {
  return (dispatch, getState) => {
    dispatch({ type: TOGGLE_ACCOUNT_IN_PUBLICATION });

    // In some cases not transmitted SocialType
    if (!socialType) {
      const { accountsList } = getState();
      const findedAccount = accountsList.payload.find(acc => acc.id === id);
      if (findedAccount) {
        nowSelectedStatus = findedAccount.selectedInPublications;
        socialType = findedAccount.type;
      }
    }

    const requestPath = nowSelectedStatus
      ? `/api/social-network-accounts/${id}/publications/unselect`
      : `/api/social-network-accounts/${id}/publications/select`;

    return axios
      .put(requestPath)
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: TOGGLE_ACCOUNT_IN_PUBLICATION_SUCCESS,
            id,
            nowSelectedStatus,
            socialType
          });
          return Promise.resolve(res);
        }
      })
      .catch(error => {
        dispatch({
          type: TOGGLE_ACCOUNT_IN_PUBLICATION_FAILURE,
          error
        });
        return Promise.resolve(error);
      });
  };
}

export function toggleAccountInAnalyticsAction(id, nowSelectedStatus) {
  return dispatch => {
    dispatch({ type: TOGGLE_ACCOUNT_IN_ANALYTICS });

    const requestPath = nowSelectedStatus
      ? `/api/social-network-accounts/${id}/analytics/unselect`
      : `/api/social-network-accounts/${id}/analytics/select`;

    return axios
      .put(requestPath)
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: TOGGLE_ACCOUNT_IN_ANALYTICS_SUCCESS,
            id,
            nowSelectedStatus
          });
          return Promise.resolve(res);
        } else {
          dispatch({
            type: TOGGLE_ACCOUNT_IN_ANALYTICS_FAILURE,
            error: res
          });
          return Promise.resolve(res);
        }
      })
      .catch(error => {
        dispatch({
          type: TOGGLE_ACCOUNT_IN_ANALYTICS_FAILURE,
          error
        });
        return Promise.resolve(error);
      });
  };
}

export function activateAllPagesInPublicationAction(pages) {
  return dispatch => {
    dispatch({ type: ACTIVATE_ALL_PAGES_IN_PUBLICATION });

    const promises = pages.map(page =>
      axios.put(`/api/social-network-objects/${page.id}/publications/select`)
    );

    return axios
      .all(promises)
      .then(
        axios.spread(res => {
          dispatch({ type: ACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS });
          return Promise.resolve(res);
        })
      )
      .catch(err => {
        dispatch({ type: ACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE });
        return Promise.resolve(err);
      });
  };
}

export function deactivateAllPagesInPublicationAction(pages) {
  return dispatch => {
    dispatch({ type: DEACTIVATE_ALL_PAGES_IN_PUBLICATION });

    const promises = pages.map(page => {
      return axios.put(`/api/social-network-objects/${page.id}/publications/unselect`);
    });

    return axios
      .all(promises)
      .then(
        axios.spread(res => {
          dispatch({ type: DEACTIVATE_ALL_PAGES_IN_PUBLICATION_SUCCESS });
          return Promise.resolve(res);
        })
      )
      .catch(err => {
        dispatch({ type: DEACTIVATE_ALL_PAGES_IN_PUBLICATION_FAILURE });
        return Promise.resolve(err);
      });
  };
}

export function activateAllPagesInAnalyticsAction(pages) {
  return dispatch => {
    dispatch({ type: ACTIVATE_ALL_PAGES_IN_ANALYTICS });

    const promises = pages.map(page => {
      return axios.put(`/api/social-network-objects/${page.id}/analytics/select`);
    });

    return axios
      .all(promises)
      .then(
        axios.spread(res => {
          dispatch({ type: ACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS });
          return Promise.resolve(res);
        })
      )
      .catch(err => {
        dispatch({ type: ACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE });
        return Promise.resolve(err);
      });
  };
}

export function deactivateAllPagesInAnalyticsAction(pages) {
  return dispatch => {
    dispatch({ type: DEACTIVATE_ALL_PAGES_IN_ANALYTICS });

    const promises = pages.map(page => {
      return axios.put(`/api/social-network-objects/${page.id}/analytics/unselect`);
    });

    return axios
      .all(promises)
      .then(
        axios.spread(res => {
          dispatch({ type: DEACTIVATE_ALL_PAGES_IN_ANALYTICS_SUCCESS });
          return Promise.resolve(res);
        })
      )
      .catch(err => {
        dispatch({ type: DEACTIVATE_ALL_PAGES_IN_ANALYTICS_FAILURE });
        return Promise.resolve(err);
      });
  };
}

export function togglePageInPublicationAction(id, isActive, socialType) {
  return (dispatch, getState) => {
    // In some cases not transmitted SocialType
    if (!socialType) {
      const { accountsList } = getState();
      const findedPage = accountsList.activePayload.find(
        page => page.socialNetworkType && page.id === id
      );
      if (findedPage) {
        isActive = findedPage.selectedInPublications;
        socialType = findedPage.socialNetworkType;
      }
    }

    const requestPath = `/api/social-network-objects/${id}/publications/${
      isActive ? 'unselect' : 'select'
    }`;

    return axios
      .put(requestPath)
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: TOGGLE_PAGE_IN_PUBLICATION_SUCCESS,
            id,
            isActive,
            socialType
          });

          return Promise.resolve(res);
        }
        dispatch({
          type: TOGGLE_PAGE_IN_PUBLICATION_FAILURE,
          error: res
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: TOGGLE_PAGE_IN_PUBLICATION_FAILURE,
          error
        });
        return Promise.resolve(error);
      });
  };
}

export function togglePageInAnalyticsAction(id, nowSelectedStatus) {
  return dispatch => {
    dispatch({ type: TOGGLE_PAGE_IN_ANALYTICS });

    const requestPath = nowSelectedStatus
      ? `/api/social-network-objects/${id}/analytics/unselect`
      : `/api/social-network-objects/${id}/analytics/select`;

    return axios
      .put(requestPath)
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: TOGGLE_PAGE_IN_ANALYTICS_SUCCESS,
            id,
            nowSelectedStatus
          });
          return Promise.resolve(res);
        }
        dispatch({
          type: TOGGLE_PAGE_IN_ANALYTICS_FAILURE,
          error: res
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: TOGGLE_PAGE_IN_ANALYTICS_FAILURE,
          error
        });
        return Promise.resolve(error);
      });
  };
}

export function toggleAccountStatusAction(
  id,
  nowStatus,
  socialType,
  pageId = null,
  propagateInPages = true
) {
  return dispatch => {
    const requestPath =
      nowStatus === 'ACTIVE'
        ? `/api/social-network-accounts/${id}/status/inactive`
        : `/api/social-network-accounts/${id}/status/active`;
    const newStatus = nowStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

    //Get channels for video source
    if (socialType && VIDEO_ACCOUNT_TYPES.includes(socialType)) {
      dispatch(getChannelsAction());
    }

    return axios
      .put(requestPath, { propagateInPages })
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: TOGGLE_ACCOUNT_STATUS,
            id,
            newStatus,
            pageId
          });
        } else {
          dispatch({
            type: TOGGLE_ACCOUNT_STATUS_FAILURE,
            error: res
          });
        }
      })
      .catch(error => {
        dispatch({
          type: TOGGLE_ACCOUNT_STATUS_FAILURE,
          error
        });
      })
      .finally(res => Promise.resolve(res));
  };
}

export function togglePageStatusAction(id, accountId, nowStatus) {
  return dispatch => {
    const requestPath =
      nowStatus === 'ACTIVE'
        ? `/api/social-network-objects/${id}/status/inactive`
        : `/api/social-network-objects/${id}/status/active`;
    const newStatus = nowStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

    return axios
      .put(requestPath)
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: TOGGLE_PAGE_STATUS,
            id,
            accountId,
            newStatus,
            page: res.data
          });
        } else {
          dispatch({
            type: TOGGLE_PAGE_STATUS_FAILURE,
            error: res
          });
        }
      })
      .catch(error => {
        dispatch({
          type: TOGGLE_PAGE_STATUS_FAILURE,
          error
        });
      })
      .finally(res => Promise.resolve(res));
  };
}

export function checkPageForSchedulePosts() {
  return dispatch => {
    axios.get('/api/posts/scheduled/count').then(res => {
      const schedulePostsCount = res.data;

      dispatch({
        type: CHECK_PAGE_FOR_SCHEDULE_POSTS,
        schedulePostsCount
      });
    });
  };
}

export function setActiveSocialType(name) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_ACTIVE_SOCIAL_TYPE,
      name
    });

    const {
      accountsList: { activePayload }
    } = getState();

    //If the social network does not have an active account (page)
    const activePageInSocialType = activePayload.some(
      acc =>
        (acc.type === name || (acc.socialNetworkType && acc.socialNetworkType === name)) &&
        acc.selectedInPublications
    );
    if (!activePageInSocialType) {
      const pageInSocialType = activePayload.filter(
        page => page.socialNetworkType && page.socialNetworkType === name
      );
      if (pageInSocialType.length) {
        dispatch(
          togglePageInPublicationAction(sortBy(pageInSocialType, ['name'])[0].id, false, name)
        );
      } else if (SOCIAL_TYPE_WITHOUT_PAGE.indexOf(name) + 1) {
        const accountInSocialType = activePayload.filter(acc => acc.type === name);
        dispatch(
          toggleAccountInPublicationAction(sortBy(accountInSocialType, ['name'])[0].id, false, name)
        );
      }
    }
  };
}

export function showAccountErrorConnect(msg) {
  return {
    type: SHOW_ACCOUNT_ERROR_CONNECT,
    error: {
      message: msg
    }
  };
}

export function syncAccountAction(id) {
  return dispatch => {
    dispatch({ type: SYNC_ACCOUNT });

    return axios
      .put(`/api/social-network-accounts/${id}/synchronize`)
      .then(res => {
        dispatch({
          type: SYNC_ACCOUNT_SUCCESS
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: SYNC_ACCOUNT_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function setErrorAccountsState(message) {
  return { type: SET_ERROR_ACCOUNTS_STATE, error: { message } };
}

export function clearAccountsErrorState() {
  return { type: CLEAR_ACCOUNTS_ERROR_STATE };
}

export function clearAccountsReduxState() {
  return { type: CLEAR_ACCOUNTS_REDUX_STATE };
}
