export function deletePostsFromCalendar(state, action) {
  const nextState = { ...state };
  const delOptions = action.payload.options;

  nextState.payload.forEach(({ postsList }, ind) => {
    nextState.payload[ind].postsList = [...postsList].filter(post => {
      const isAutopilotPost = post.autopilot;
      const isManualPost = !isAutopilotPost;

      if (
        (
          (delOptions.autopilotPosts && isAutopilotPost) ||
          (delOptions.manualPosts && isManualPost)
        ) &&
        (
          (delOptions.publishedPosts && post.status === 'PUBLISH') ||
          (delOptions.scheduledPosts && post.status === 'SCHEDULE') ||
          (delOptions.errorPosts && post.status === 'ERROR'))
      ) {
        return false;
      } // prettier-ignore

      return true;
    });
  });

  return nextState;
}
