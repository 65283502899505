export const SET_TOUR = 'SET_TOUR';
export const CLEAR_TOUR = 'CLEAR_TOUR';


export function setTourAction(tourName) {
  return {
    type: SET_TOUR,
    tourName,
  };
}

export function clearTourAction() {
  return { 
    type: CLEAR_TOUR 
  }
}