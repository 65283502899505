import axios from 'axios';

export const ADD_TOPIC = 'ADD_TOPIC';
export const ADD_TOPIC_SUCCESS = 'ADD_TOPIC_SUCCESS';
export const ADD_TOPIC_FAILURE = 'ADD_TOPIC_FAILURE';

export const GET_TOPICS = 'GET_TOPICS';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAILURE = 'GET_TOPICS_FAILURE';

export const EDIT_TOPIC = 'EDIT_TOPIC';
export const EDIT_TOPIC_SUCCESS = 'EDIT_TOPIC_SUCCESS';
export const EDIT_TOPIC_FAILURE = 'EDIT_TOPIC_FAILURE';

export const REMOVE_TOPIC = 'REMOVE_TOPIC';
export const REMOVE_TOPIC_SUCCESS = 'REMOVE_TOPIC_SUCCESS';
export const REMOVE_TOPIC_FAILURE = 'REMOVE_TOPIC_FAILURE';

export const CLEAR_TOPIC_ERROR_STATE = 'CLEAR_TOPIC_ERROR_STATE';

export function addTopicAction(name) {
  return dispatch => {
    dispatch({ type: ADD_TOPIC });

    return axios
      .post(`/api/topics`, { name: name })
      .then(res => {
        dispatch({ type: ADD_TOPIC_SUCCESS });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: ADD_TOPIC_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function getTopicsAction() {
  return dispatch => {
    dispatch({ type: GET_TOPICS });

    return axios
      .get(`/api/topics`)
      .then(res => {
        dispatch({
          type: GET_TOPICS_SUCCESS,
          payload: res.data,
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: GET_TOPICS_FAILURE,
          error,
        });
        return Promise.resolve(error);
      });
  };
}

export function removeTopicAction(id) {
  return dispatch => {
    dispatch({
      type: REMOVE_TOPIC,
    });

    return axios
      .delete(`/api/topics/${id}`)
      .then(res => {
        dispatch({
          type: REMOVE_TOPIC_SUCCESS,
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: REMOVE_TOPIC_FAILURE,
          error,
        });
        return Promise.resolve(error);
      });
  };
}

export function clearTopicsErrorState() {
  return { type: CLEAR_TOPIC_ERROR_STATE };
}
