import { _formatCloudMediaForPost } from '../_formatCloudMediaForPost/_formatCloudMediaForPost';

export default function setPostMedias(state, action) {
  const { mediaKey, files } = action.payload || {};

  const nextState = { ...state, [mediaKey]: [...state[mediaKey]] };
  const formatedMedia = files.map(file => _formatCloudMediaForPost(mediaKey, file));

  nextState[mediaKey] = [...formatedMedia, ...nextState[mediaKey]];

  return nextState;
}
