export function searchProducts(state, action) {

  //Remove later
  let searchQueryShopType = action.query;
  if (typeof state.search !== 'string' && action.shopType) {
    searchQueryShopType = {...state.searchQuery, [action.shopType]: action.query}
  }

  return Object.assign({}, state, {
    loading: false,
    error: null,
    searchQuery: searchQueryShopType
  });
}


export function searchProductsSuccess(state, action) {
  const payload = state.searchQuery === action.query ? action.payload : state.payload;

  return Object.assign({}, state, {
    payload,
    loading: false,
    error: null
  });
}
