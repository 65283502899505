import { deepCopyObject } from 'util/deepCopyObject';

export function updateAclItem(state, listName, elementInd, nextProps) {
  const nextState = deepCopyObject(state);

  nextState[listName].list[elementInd] = {
    ...nextState[listName].list[elementInd],
    ...nextProps
  };

  return nextState;
}
