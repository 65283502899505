import moment from 'moment';
// Actions constants
import {
  PREPARE_POST_MODAL_EDIT,
  PREPARE_POST_MODAL_NEW,
  POST_SET_SOCIAL_PAGES,
  CHOOSE_SOCIAL_TYPE,
  CHOOSE_SOCIAL_PAGE,
  CHOOSE_SOURCE_TYPE,
  CHOOSE_SOURCE_ITEM,
  SET_EXTRA_PUBLISHING_PARAMS,
  CHANGE_DESC,
  TEXT_FIELD,
  PUT_IMAGES,
  UPLOAD_IMAGES,
  REORDER_IMAGES,
  REMOVE_IMAGES_FROM_SOURCE_ITEM,
  CHOOSE_DATE,
  CHANGE_LINK,
  LOADING,
  SHOW_ERROR,
  HIDE_ERROR,
  RESET_DATA,
  TOGGLE_SHORT_CONTENT_LINKS,
  SELECT_SHORTENER_DOMAIN_POSTMODAL,
  UPLOAD_POST_MEDIA,
  UPDATE_POST_MEDIA,
  UPDATE_POST_MEDIAS,
  DELETE_POST_MEDIA,
  SET_POST_MEDIAS
} from 'store/actions/postModal/postModal';
// reducers
import preparePostModalEdit from './helpers/preparePostModalEdit/preparePostModalEdit';
import preparePostModalNew from './helpers/preparePostModalNew/preparePostModalNew';
import setSocialPages from './helpers/setSocialPages/setSocialPages';
import chooseSocialType from './helpers/chooseSocialType/chooseSocialType';
import setExtraPublishingParams from './helpers/setExtraPublishingParams/setExtraPublishingParams';
import chooseSocialPage from './helpers/chooseSocialPage/chooseSocialPage';
import chooseSourceType from './helpers/chooseSourceType/chooseSourceType';
import chooseSourceItem from './helpers/chooseSourceItem/chooseSourceItem';
import putImages from './helpers/putImages/putImages';
import uploadImages from './helpers/uploadImages/uploadImages';
import removeImageFromSourceItem from './helpers/removeImageFromSourceItem/removeImageFromSourceItem';
import reorderImages from './helpers/reorderImages';
import textFiled from './helpers/textFiled/textFiled';
import validatePostModal from './helpers/validatePostModal';
import uploadPostMedia from './helpers/uploadPostMedia/uploadPostMedia';
import updatePostMedia from './helpers/updatePostMedia/updatePostMedia';
import updatePostMedias from './helpers/updatePostMedias/updatePostMedias';
import deletePostMedia from './helpers/deletePostMedia/deletePostMedia';
import setPostMedias from './helpers/setPostMedias/setPostMedias';

const initialState = {
  postId: NaN,
  autopilot: null,
  typeModal: '',

  socialType: '',
  socialPage: {},
  socialPages: [
    {
      socialType: '',
      socialObjects: [],
      default: []
    }
  ],

  sourceType: '',
  sourceBrand: '',
  sourceItem: {},
  link: '',
  content: '',
  contentHtml: '',
  albumName: '',
  images: [],
  videos: [],
  extraPublishingParams: {},
  momentPostDate: moment(),

  dataForSocialPagesSelect: {
    placeholder: 'Social pages',
    value: null,
    defaultOptions: []
  },
  optionsForSourceTypeSelect: [],
  dataForSourceItemsSelect: {
    placeholder: 'No source',
    value: null,
    defaultOptions: [],
    disabled: true
  },

  urlShortenerParams: {
    domainId: null
  },

  formValid: false,
  shortContentLinks: false,
  loading: false,
  error: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PREPARE_POST_MODAL_EDIT:
      return preparePostModalEdit(state, action);

    case PREPARE_POST_MODAL_NEW:
      return validatePostModal(preparePostModalNew(state, action));

    case POST_SET_SOCIAL_PAGES:
      return validatePostModal(setSocialPages(state, action));

    case CHOOSE_SOCIAL_TYPE:
      return validatePostModal(chooseSocialType(state, action));

    case CHOOSE_SOCIAL_PAGE:
      return validatePostModal(chooseSocialPage(state, action));

    case CHOOSE_SOURCE_TYPE:
      return chooseSourceType(state, action);

    case CHOOSE_SOURCE_ITEM:
      return validatePostModal(chooseSourceItem(state, action));

    case SET_EXTRA_PUBLISHING_PARAMS:
      return validatePostModal(setExtraPublishingParams(state, action));

    case CHANGE_DESC:
      return validatePostModal({
        ...state,
        ...{ content: action.value, contentHtml: action.htmlValue }
      });

    case TEXT_FIELD:
      return validatePostModal(textFiled(state, action));

    case CHANGE_LINK:
      return validatePostModal({ ...state, link: action.val });

    case PUT_IMAGES:
      return validatePostModal(putImages(state, action));

    case UPLOAD_IMAGES:
      return validatePostModal(uploadImages(state, action));

    case REORDER_IMAGES:
      return reorderImages(state, action);

    case REMOVE_IMAGES_FROM_SOURCE_ITEM:
      return validatePostModal(removeImageFromSourceItem(state, action));

    case CHOOSE_DATE:
      return { ...state, momentPostDate: action.chooseDate };

    case LOADING:
      return { ...state, loading: action.boolValue };

    case SHOW_ERROR:
      return { ...state, error: action.errorMessage };

    case HIDE_ERROR:
      return { ...state, error: '' };

    case TOGGLE_SHORT_CONTENT_LINKS:
      return {
        ...state,
        shortContentLinks: !state.shortContentLinks,
        urlShortenerParams: {
          domainId: !state.shortContentLinks === false ? null : state.urlShortenerParams.domainId
        }
      };

    case SELECT_SHORTENER_DOMAIN_POSTMODAL:
      return { ...state, urlShortenerParams: { domainId: action.payload.id } };

    case RESET_DATA:
      return initialState;

    case UPLOAD_POST_MEDIA:
      return validatePostModal(uploadPostMedia(state, action));

    case UPDATE_POST_MEDIA:
      return validatePostModal(updatePostMedia(state, action));

    case UPDATE_POST_MEDIAS:
      return validatePostModal(updatePostMedias(state, action));

    case DELETE_POST_MEDIA:
      return validatePostModal(deletePostMedia(state, action));

    case SET_POST_MEDIAS:
      return validatePostModal(setPostMedias(state, action));

    default:
      return state;
  }
};
