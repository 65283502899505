import baseReducerHandler from 'util/baseReducerHandler';
import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    HANDLE_PASSWORD_FIELD,
    CANCEL_CHANGE_PASSWORD,
    CLEAR_CHANGE_PASSWORD_ERROR_STATE
} from '../../actions/password/changePassword';
import { validate } from './validate';

const initialState = {
    loading: false,
    success: false,
    error: false,

    currentPassword: '',
    newPassword: '',
    confirmPassword: '',

    formValid: false,
    currentPasswordValid: false,
    newPasswordValid: false,
    confirmPasswordValid: false,

    formErrors: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD:
            return baseReducerHandler(state, true, null);

        case CHANGE_PASSWORD_SUCCESS:
            return baseReducerHandler(state, false, null);

        case CHANGE_PASSWORD_FAILURE:
            if (action.incorrectPassword) {
                return Object.assign({}, state, {
                    loading: false,
                    error: null,
                    formValid: false,
                    currentPasswordValid: false,
                    //confirmPasswordValid: false,
                    formErrors: {
                        currentPassword: 'Current password is wrong'
                    }
                });
            }
            return baseReducerHandler(state, false, null);

        case HANDLE_PASSWORD_FIELD:
            return Object.assign(
                {},
                state,
                {
                    [action.name]: action.value,
                    error: false,
                    currentPasswordValid: false,
                    confirmPasswordValid: false,
                    formErrors: {
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: ''
                    }
                },
                validate(state, action.name, action.value)
            );

        case CANCEL_CHANGE_PASSWORD:
            return Object.assign({}, initialState);

        case CLEAR_CHANGE_PASSWORD_ERROR_STATE:
            return baseReducerHandler(state, false, null);

        default:
            return state;
    }
};
