import setSocialTypes from './utils/setSocialTypes';


export function removeAccount(state, action) {
  const newState = { ...state };

  const filter = (items) => {
    return items.filter(({ id, accounts }) => {
      if (
        (id !== action.id && !accounts) ||
        (Array.isArray(accounts) && accounts.every(account => account.id !== action.id))
      ) {
        return true;
      }
      return false;
    });
  };

  const newActiveSocialTypes = (activeSocialType, socialTypes) => {
    if (socialTypes.find(socialType => socialType.name === activeSocialType)) {
      return activeSocialType;
    } else {
      return socialTypes[0] ? socialTypes[0].name : '';
    }
  };

  newState.payload = filter(newState.payload);
  newState.activePayload = filter(newState.activePayload);
  newState.socialTypes = setSocialTypes(newState.activePayload);
  newState.activeSocialType = newActiveSocialTypes(newState.activeSocialType, newState.socialTypes);

  return newState;
}
