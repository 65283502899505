import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ModalComponent from 'components/common/modal/modal';
import { useActions } from 'hooks';
import { hideModal, modalTypes } from 'store/actions/modal/modal';
import { addCompetitor } from 'store/actions/competitors';
import Button from 'components/common/button/button';
import getModalData from 'util/getModalData';
import classes from './upgradePlanModal.module.css';

export const UpgradePlanModal = () => {
  const actions = useActions({ hideModal, addCompetitor });
  const modalData = useSelector(state => getModalData(state, modalTypes.UPGRADE_PLAN));

  function handleCloseModal() {
    actions.hideModal();
    modalData.data?.onClose && modalData.data.onClose();
  }

  return (
    <ModalComponent
      center
      isOpen={modalData.isOpen}
      closeModal={handleCloseModal}
      hideCloseButton={true}
      modalPadding="0"
      width={700}
    >
      <div className={classes['upgrade-plan']}>
        <div className="modal__content modal__content_theme_dark">
          <h2 className="h2">
            {modalData.data?.title
              ? modalData.data?.title
              : 'You need to select a plan.'}
          </h2>

          <p className={classes['upgrade-plan__text']}>
            {modalData.data?.text
              ? modalData.data.text
              : `Dear customer, you reached the limit for your current plan.`}
          </p>

          <div className={classes['upgrade-plan__actions']}>
            <Link to="/upgrade" onClick={handleCloseModal}>
              <Button
                yellow
                label={modalData.data?.actionLabel ? modalData.data?.actionLabel : 'Select a plan'}
              />
            </Link>
            <Button
              transparentWithoutBorder={true}
              label={modalData.data?.cancelLabel ? modalData.data?.cancelLabel : 'Cancel'}
              onClick={handleCloseModal}
            />
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};
