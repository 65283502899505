import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ModalComponent from 'components/common/modal/modal';
import { Timer } from '../timer/timer';
import { useActions } from 'hooks';
import { hideModal, modalTypes } from 'store/actions/modal/modal';
import Button from 'components/common/button/button';
import getModalData from 'util/getModalData';
import classes from './freePlanExtinctionModal.module.css';
import { FREE_PLAN_EXTINCTION_DATE } from 'constants/freePlanExtinctionDate';

export const FreePlanExtinctionModal = () => {
  const actions = useActions({ hideModal });
  const modalData = useSelector(state => getModalData(state, modalTypes.FREE_PLAN_EXTINCTION));

  function handleCloseModal() {
    actions.hideModal();
    modalData.data?.onClose && modalData.data.onClose();
  }

  return (
    <ModalComponent
      center
      isOpen={modalData.isOpen}
      closeModal={handleCloseModal}
      hideCloseButton={true}
      modalPadding="0"
      width={700}
    >
      <div className={classes['upgrade-plan']}>
        <div className="modal__content modal__content_theme_dark">
          <h2 className={cn('h2', classes['header'])}>
            <span className={classes['free-plan__accent']}>Free</span>&nbsp;plan comes to the extinction
          </h2>


          <Timer endDate={FREE_PLAN_EXTINCTION_DATE} />
          <p className={cn('h2', classes['header'])}>
            Get&nbsp;<span className={classes['starter-button__accent']}>Starter</span>&nbsp;plan just for $3
          </p>

          <div className={classes['upgrade-plan__actions']}>
            <Link to="/upgrade" onClick={handleCloseModal}>
              <Button
                lightGreen
                label={modalData.data?.actionLabel ? modalData.data?.actionLabel : 'Get Starter'}
              />
            </Link>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};
