import React, { useEffect, useRef, memo } from 'react';
import ReactTooltip from 'react-tooltip';
import { UpgradePlanPopover } from 'components/common/upgradePlanPopover/upgradePlanPopover';
import './upgradePlanPopoverGlobal.css';

export const UpgradePlanPopoverGlobal = memo(() => {
  const tooltip = useRef(null);

  useEffect(() => {
    setTimeout(() => ReactTooltip.rebuild(), 1000);
  });

  function hideTooltip() {
    if (!tooltip.current.tooltipRef) return;

    tooltip.current.tooltipRef = null;
    ReactTooltip.hide();
  }

  return (
    <ReactTooltip
      ref={tooltip}
      id="upgrade-plan-popover"
      className="upgrade-plan-popover"
      clickable={true}
      effect="solid"
      place="bottom"
      scrollHide={true}
      resizeHide={true}
      overridePosition={pos => ({
        left: Math.abs(pos.left) !== pos.left ? 0 : pos.left,
        top: pos.top
      })}
    >
      <div onWheel={() => hideTooltip()} onTouchMove={() => hideTooltip()}>
        <UpgradePlanPopover onClose={() => hideTooltip()} />
      </div>
    </ReactTooltip>
  );
});
