import axios from 'axios';
import { routerWS } from 'util/routerWS';
import { CONTENT_PAGE_SIZE } from 'constants/limits';
import getMediaListName from 'util/getMediaListName';

import { MEDIA_SECTION } from 'constants/mediaSections';

export const GET_UPLOADS_PENDING = 'GET_UPLOADS_PENDING';
export const GET_UPLOADS_SUCCESS = 'GET_UPLOADS_SUCCESS';
export const GET_UPLOADS_FAIL = 'GET_UPLOADS_FAIL';

export const UPLOAD_MEDIA_TO_CLOUD = 'UPLOAD_MEDIA_TO_CLOUD';
export const CLEAR_MY_UPLOADS_ERROR = 'CLEAR_MY_UPLOADS_ERROR';
export const ADD_UPLOAD_TO_UPLOADS = 'ADD_UPLOAD_TO_UPLOADS';
export const DELETE_UPLOADED_FILE = 'DELETE_UPLOADED_FILE';
export const UPDATE_UPLOADED_FILE = 'UPDATE_UPLOADED_FILE';

export function getMediaAction(type, mediaSection, offset, size = CONTENT_PAGE_SIZE) {
  const mediaListType = getMediaListName(mediaSection);
  return async (dispatch, getState) => {
    const { list } = getState().cloud[mediaListType][type] || {};

    if (list?.length >= offset + size) {
      return;
    }

    dispatch({
      type: GET_UPLOADS_PENDING,
      payload: { mediaType: type, mediaSection }
    });

    try {
      const res = await axios.get('/api/content/all', {
        params: {
          type,
          size,
          offset,
          section: 'MY_UPLOADS',
          own: mediaSection
        }
      });

      dispatch({
        type: GET_UPLOADS_SUCCESS,
        payload: {
          mediaType: type,
          mediaSection,
          list: res.data,
          count: +res.headers?.['x-total-count'] || 0
        }
      });
    } catch (error) {
      dispatch({
        type: GET_UPLOADS_FAIL,
        payload: {
          mediaType: type,
          mediaSection,
          error: error.response?.data?.message || 'Something went wrong on get uploads'
        }
      });
    }
  };
}

export function addUploadToUploadsAction(entity, uploadType) {
  return {
    type: ADD_UPLOAD_TO_UPLOADS,
    payload: { entity, uploadType }
  }
}

export function uploadMediaToCloudAction(mediaType, formData) {
  return async dispatch => {
    try {
      const res = await axios.post('/api/content/upload', formData);
      const uploadedFile = res.data;

      dispatch({
        type: UPLOAD_MEDIA_TO_CLOUD,
        payload: { file: uploadedFile, mediaType }
      });

      routerWS(`/topic/file/${uploadedFile.id}/uploading`, data => {
        const { totalBytes, bytesTransferred, content } = data;
        const nextProps = {
          ...content,
          uploadingFilePercent: data.status === 'UPLOADED' ? undefined : ((bytesTransferred * 100) / totalBytes).toFixed() // prettier-ignore
        };

        dispatch(updateUploadedFileAction(uploadedFile.id, mediaType, MEDIA_SECTION.MY, nextProps));
      });
    } catch (error) {
      dispatch({
        type: GET_UPLOADS_FAIL,
        payload: {
          mediaType,
          error: error.response?.data?.detail || 'Something went wrong on upload a file'
        }
      });
    }
  };
}

export function deleteUploadedFileAction(id, mediaType, mediaSection) {
  return async dispatch => {
    dispatch(
      updateUploadedFileAction(id, mediaType, mediaSection, {
        isDeleting: true
      })
    );

    try {
      await axios.delete(`/api/content/${id}`);

      dispatch({
        type: DELETE_UPLOADED_FILE,
        payload: { id, mediaType, mediaSection }
      });
    } catch (error) {
      dispatch(
        updateUploadedFileAction(id, mediaType, mediaSection,{
          isDeleting: false,
          deleteError: error.response?.data?.message || 'Someting went wrong'
        })
      );
    }
  };
}

export function editTagsToUploadedFilesAction(mediaIds, newTags, tagsToRemove, mediaType, mediaSection) {
  return async dispatch => {
    await axios.put('/api/content/tags', {
      mediaIds: mediaIds,
      newTags: newTags,
      tagsToRemove: tagsToRemove
    }).then((res) => {
      if (res?.data) {
        res.data.forEach(m => {
          dispatch(updateUploadedFileAction(m.id, mediaType, mediaSection, {
            tags: m.tags
          }));
        });
      }
    });
  };
}

export function updateUploadedFileAction(id, mediaType, mediaSection, newProps) {
  return {
    type: UPDATE_UPLOADED_FILE,
    payload: {
      id,
      mediaType,
      mediaSection,
      newProps
    }
  };
}

export function clearMediaUploadsErrorAction(mediaType, mediaSection) {
  return {
    type: CLEAR_MY_UPLOADS_ERROR,
    payload: { mediaType, mediaSection }
  };
}
