import axios from 'axios';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const REMOVE_CATEGORY = 'REMOVE_CATEGORY';
export const REMOVE_CATEGORY_SUCCESS = 'REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_FAILURE = 'REMOVE_CATEGORY_FAILURE';

export function addCategoryAction(name) {
  return dispatch => {
    dispatch({ type: ADD_CATEGORY });

    return axios
      .post(`/api/product-categories`, { name: name })
      .then(res => {
        dispatch({ type: ADD_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: ADD_CATEGORY_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function getCategoriesAction() {
  return dispatch => {
    dispatch({ type: GET_CATEGORIES });

    return axios
      .get(`/api/product-categories`)
      .then(res => {
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: res.data,
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: GET_CATEGORIES_FAILURE,
          error,
        });
        return Promise.resolve(error);
      });
  };
}

export function removeCategoryAction(id) {
  return dispatch => {
    dispatch({ type: REMOVE_CATEGORY });

    return axios
      .delete(`/api/product-categories/${id}`)
      .then(res => {
        dispatch({ type: REMOVE_CATEGORY_SUCCESS });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: REMOVE_CATEGORY_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}
