import axios from 'axios';
import AuthService from 'util/authService';

export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES';
export const GET_ALL_COMPANIES_SUCCESS = 'GET_ALL_COMPANIES_SUCCESS';
export const GET_ALL_COMPANIES_FAILURE = 'GET_ALL_COMPANIES_FAILURE';
export const SET_ACCOUNT_COMPANY_DATA = 'SET_ACCOUNT_COMPANY_DATA';
export const SET_ACCOUNT_COMPANY_DATA_SUCCESS = 'SET_ACCOUNT_COMPANY_DATA_SUCCESS';
export const SET_ACCOUNT_COMPANY_DATA_FAILURE = 'SET_ACCOUNT_COMPANY_DATA_FAILURE';
export const SET_NEW_ACCOUNT_COMPANY = 'SET_NEW_ACCOUNT_COMPANY';
export const SET_NEW_ACCOUNT_COMPANY_SUCCESS = 'SET_NEW_ACCOUNT_COMPANY_SUCCESS';
export const SET_NEW_ACCOUNT_COMPANY_FAILURE = 'SET_NEW_ACCOUNT_COMPANY_FAILURE';
export const CREATE_DEFAULT_COMPANY = 'CREATE_DEFAULT_COMPANY';
export const CREATE_DEFAULT_COMPANY_SUCCESS = 'CREATE_DEFAULT_COMPANY_SUCCESS';

const Auth = new AuthService();

export function getAllCompanies() {
  return dispatch => {
    dispatch({ type: GET_ALL_COMPANIES });

    return axios
      .get(`/api/companies/all`)
      .then(res => {
        dispatch({
          type: GET_ALL_COMPANIES_SUCCESS,
          payload: res.data
        });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: GET_ALL_COMPANIES_FAILURE,
          error
        });
        return Promise.resolve(error);
      });
  };
}

export function getInitialAccountCompanyData() {
  return dispatch => {
    dispatch({ type: SET_ACCOUNT_COMPANY_DATA });
    return axios
      .get(`/api/account`)
      .then(res => {
        dispatch({ type: SET_ACCOUNT_COMPANY_DATA_SUCCESS, payload: res.data.company });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({ type: SET_ACCOUNT_COMPANY_DATA_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function setNewAccountCompany(id) {
  return dispatch => {
    dispatch({ type: SET_NEW_ACCOUNT_COMPANY });
    return axios
      .post(`/api/account/company/${id}`, {})
      .then(res => {
        const dataForCopmpanySuccess = res.data;
        return Auth.changeToken().then(res => {
          dispatch({ type: SET_NEW_ACCOUNT_COMPANY_SUCCESS, payload: dataForCopmpanySuccess});
          return Promise.resolve(res);
        });
      })
      .catch(error => {
        dispatch({ type: SET_NEW_ACCOUNT_COMPANY_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function createDefaultCompany(companyName) {
  return dispatch => {
    dispatch({ type: CREATE_DEFAULT_COMPANY });

    return axios
      .post('/api/companies', {
          name: companyName
      })
      .then(res => {
        dispatch({ type: CREATE_DEFAULT_COMPANY_SUCCESS, payload: res.data});
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.resolve(error);
      });
  };
}

export function unsetFirstLogin() {
  return dispatch => {
    return axios
      .put(`/api/companies/unsetFirstLogin`)
      .then(res => {
        return Promise.resolve(res.data);
      })
      .catch(error => {
        return Promise.resolve(error);
      });
  }
}