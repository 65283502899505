import axios from 'axios';
import AuthService from 'util/authService';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const CHECK_EMAIL_EXIST = 'CHECK_EMAIL_EXIST';
export const CHECK_EMAIL_EXIST_SUCCESS = 'CHECK_EMAIL_EXIST_SUCCESS';
export const CHECK_EMAIL_EXIST_FAILURE = 'CHECK_EMAIL_EXIST_FAILURE';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_AUTH_ERROR_STATE = 'CLEAR_AUTH_ERROR_STATE';
export const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';

const Auth = new AuthService();

export function loginAction(data) {
  return dispatch => {
    dispatch({ type: LOGIN });

    return Auth.login(data)
      .then(res => {
        dispatch({ type: LOGIN_SUCCESS });

        dispatch(setCurrentUser());

        return Promise.resolve(res);
      })
      .catch(err => {
        dispatch({
          type: LOGIN_FAILURE,
          error: err
        });

        return Promise.resolve(err);
      });
  };
}

export function setCurrentUser() {
  return {
    type: SET_CURRENT_USER,
    user: Auth.getProfile()
  };
}

export function logoutAction() {
  return dispatch => {
    return Auth.logout().then(() => {
      dispatch(logout());
    });
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}

export function checkEmailExist(email) {
  return dispatch => {
    dispatch({ type: CHECK_EMAIL_EXIST });

    return axios
      .get(`/api/check-email?email=${email.split('+').join('%2B')}`)
      .then(res => {
        dispatch({ type: CHECK_EMAIL_EXIST_SUCCESS });
        return Promise.resolve(res.data);
      })
      .catch(error => {
        dispatch({ type: CHECK_EMAIL_EXIST_FAILURE, error });
        return Promise.resolve(error);
      });
  };
}

export function checkEmailExistFunc(email) {
  return axios
    .get(`/api/check-email?email=${email}`)
    .then(res => {
      return Promise.resolve(res.data);
    })
    .catch(error => {
      return Promise.resolve(error);
    });
}

export function checkFirstLogin() {
  return dispatch => {
    return axios
      .get(`/api/companies/firstLogin`)
      .then(res => {
        dispatch({
          type: SET_FIRST_LOGIN,
          payload: res.data.firstLogin,
        });
        return Promise.resolve(res.data);
      })
      .catch(error => {
        return Promise.resolve(error);
      });
  };
}

export function restorePasswordInit(email) {
  return dispatch => {
    return axios
      .post(`/api/account/reset-password/init`, { email })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.resolve(error);
      });
  };
}

export function restorePasswordFinish(key, newPassword) {
  return dispatch => {
    return axios
      .post(`/api/account/reset-password/finish`, { key, newPassword })
      .then(res => {
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function clearAuthErrorState() {
  return { type: CLEAR_AUTH_ERROR_STATE };
}
