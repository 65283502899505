import { store } from 'store';
import { setTourAction } from 'store/actions/tour';


function _showTourByModalIfNeed() {
  let didShow = {};

  return (modalType) => {
    const { auth: { isFirstLogin } } = store.getState();

    if (modalType === 'POST_MODAL' && isFirstLogin && !didShow.POST_MODAL) {
      store.dispatch( 
        setTourAction('TOUR_BY_POST_MODAL')
      );
      didShow.POST_MODAL = true;
    }
  }
}

export default _showTourByModalIfNeed();