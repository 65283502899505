import setSocialTypes from './utils/setSocialTypes';

export function updatePage(state, action) {
  const copyState = { ...state };

  let page = action.object;

  // find and replace page in payload
  for (let i = 0; i < copyState.payload.length; i++) {
    const acc = copyState.payload[i];
    if (acc.type === page.socialNetworkType && acc.objects && acc.objects.length) {
      const pageFindIndex = acc.objects.findIndex(obj => obj.id === page.id);
      if (pageFindIndex > -1) {
        acc.objects[pageFindIndex] = {...acc.objects[pageFindIndex], ...page};
        if (acc.status === 'ACTIVE') {
          copyState.activePayload.forEach((accountOrObject, index) => {
            if (accountOrObject.id === acc.id && accountOrObject.type === acc.type) {
              copyState.activePayload[index] = {...accountOrObject, ...acc}
            }
          })
        }
        break;
      }
    }
  }

  const pageFindIndexActivePayload = copyState.activePayload
    .findIndex(accountOrObject => accountOrObject.id === page.id
      && accountOrObject.socialNetworkType
      && accountOrObject.socialNetworkType === page.socialNetworkType);
  if (page.status === 'ACTIVE') {
    if (pageFindIndexActivePayload > -1) {
      copyState.activePayload[pageFindIndexActivePayload] = {...copyState.activePayload[pageFindIndexActivePayload], ...page}
    } else {
      copyState.activePayload = [...copyState.activePayload , page]
    }
  } else {
    if (pageFindIndexActivePayload !== -1) {
      copyState.activePayload = [...copyState.activePayload].splice(pageFindIndexActivePayload, 1);
    }
  }

  copyState.socialTypes = setSocialTypes(copyState.activePayload);


  return copyState;
}
