import {
  ADD_TEMPLATE,
  ADD_TEMPLATE_SUCCESS,
  ADD_TEMPLATE_FAILURE,
  GET_TEMPLATES,
  GET_TEMPLATES_SUCCESS,
  GET_TEMPLATES_FAILURE,
  REMOVE_TEMPLATE,
  REMOVE_TEMPLATE_SUCCESS,
  REMOVE_TEMPLATE_FAILURE,
  CLEAR_TEMPLATE_ERROR_STATE,
} from '../../actions/template';
import baseReducerHandler from 'util/baseReducerHandler';

import { getTemplatesSuccess } from './getTemplates';

const initialState = {
  payload: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEMPLATE:
      return baseReducerHandler(state, true, null);
    case ADD_TEMPLATE_SUCCESS:
    case ADD_TEMPLATE_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case GET_TEMPLATES:
      return baseReducerHandler(state, true, null);
    case GET_TEMPLATES_SUCCESS:
      return getTemplatesSuccess(state, action);
    case GET_TEMPLATES_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case REMOVE_TEMPLATE:
      return baseReducerHandler(state, true, null);
    case REMOVE_TEMPLATE_SUCCESS:
    case REMOVE_TEMPLATE_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case CLEAR_TEMPLATE_ERROR_STATE:
      return baseReducerHandler(state, true, null);

    default:
      return state;
  }
};
