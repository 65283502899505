export const AUTOPILOT_TYPES = ['Random', 'Newesr', 'Bestsellers', 'Top rate'];
export const MAX_TEMPLATES_COUNT = 20;
export const MAX_ROW_DATE_TIME_COUNT = 10;
export const WEEK = {
  Mo: 'MONDAY',
  Tu: 'TUESDAY',
  We: 'WEDNESDAY',
  Th: 'THURSDAY',
  Fr: 'FRIDAY',
  Sa: 'SATURDAY',
  Su: 'SUNDAY'};

export const WEEK_NUMBER = {
  Mo: 0,
  Tu: 1,
  We: 2,
  Th: 3,
  Fr: 4,
  Sa: 5,
  Su: 6};

export const WEEK_REVERSE = {
  MONDAY: 'Mo',
  TUESDAY: 'Tu',
  WEDNESDAY: 'We',
  THURSDAY: 'Th',
  FRIDAY: 'Fr',
  SATURDAY: 'Sa',
  SUNDAY: 'Su'};

export const ANTI_SPAM_TIME = 15; //Minuets
