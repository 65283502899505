export default (state, action) => {
  const images = getNewImages(state, action);

  return { ...state, images };
}

const getNewImages = (state, action) => {
  const filteredImages = state.images.filter(stateImage => {
    const hasImageFromAction = action.images.some(actionImage => actionImage.parentId === stateImage.parentId);

    return !hasImageFromAction;
  });
  return filteredImages;
}