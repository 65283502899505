import {
  AP_CHANGE_NAME,
  AP_SET_SOCIAL_PAGES,
  AP_SET_COLLECTIONS_FORM,
  AP_SET_BRANDS_FORM,
  AP_SET_STOCK_LEVELS_FORM,
  AP_SET_FEATURES_FORM,
  AP_SET_TYPES_FORM,
  AP_SET_PLAYLISTS,
  AP_SET_SCHEDULES,
  AP_SET_TEMPLATES,
  AP_SET_MODE,
  AP_SET_TYPE_POST,
  AP_SET_SOURCE_TYPE,
  AP_LOADING,
  AP_CLEAR_ERROR,
  AP_RESET_FORM,
  AP_SET_FORM_FROM_SETTINGS,
  AP_SET_AUTOPILOT_ID,
  AP_SET_ACTIVE_TEMPLATE,
  AP_SET_FROM,
  AP_SET_IS_SHOW,
  AP_SET_IN_STOCK,
  AP_SET_TYPES,
  AP_SET_BRANDS,
  AP_SET_COLLECTIONS,
  AP_SET_MEDIA_TAGS,
  AP_SET_ADD_MEDIA_PARTICIPANTS,
  AP_SET_FAVORITE_MEDIA_PARTICIPANTS,
  AP_SET_OBJECT_SAMPLE,
  AP_SET_LOADING_SAMPLE,
  AP_SET_ADVANCED_OPTIONS,
  AP_RESET_SETTINGS,
  AP_ADD_AUTOPILOT,
  AP_DELETE_AUTOPILOT,
  AP_CHANGE_ACTIVE_AUTOPILOT,
  AP_EDIT_AUTOPILOT,
  AP_SET_AUTOPILOT_LIST,
  AP_SELECT_STORE,
  AP_UNSELECT_STORE,
  AP_DESELECT_ALL_STORES,
  AP_SET_STORES,
  AP_TOGGLE_SHORT_CONTENT_LINKS,
  AP_SELECT_SHORTENER_DOMAIN
} from 'store/actions/autopilot/autopilot';

//Utils & Helpers
import validateAutopilotForm from './helpers/validateAutopilotForm';
import setAutopilotModal from './helpers/setAutopilotModal';
import removeOrAdd from 'util/removeOrAdd';
import { setSchedules } from './helpers/setSchedules';

const initialState = {
  autopilotList: [],
  form: {
    autopilotId: null,
    name: 'My autopilot',
    socialPages: [
      {
        socialType: '',
        socialObjects: [],
        default: []
      }
    ],
    types: [], //news, bestsellers, etc
    collections: [],
    brands: [],
    playlists: [], //array ID playlists - for Source VIDEO
    schedules: [
      {
        dayOfWeek: ['We', 'Tu', 'Th', 'Fr', 'Sa', 'Su', 'Mo'],
        time: '12:00'
      }
    ],
    mode: 'CUSTOM_TIME', //CUSTOM_TIME - with schedules,  AUTO_TIME - without schedules
    typePost: 'PRODUCT_ALBUM', //
    productFeatures: [],
    mediaTags: [],
    addParticipants: [],
    stockLevels: [],
    sourceType: '', //PRODUCT, VIDEO, NEWS
    sourceBrand: '', //MAGENTO, SHOPIFY, etc
    templates: [
      {
        albumNameTemplate: '',
        contentTemplate: '',
        contentHtmlTemplate: '',
        imageContentTemplate: ''
      }
    ],
    validate: {
      nameValid: false,
      sourceTypeValid: false,
      socialPageValid: false,
      albumNameValid: false,
      customTimeValid: false,
      formValid: false,
      error: []
    },
    advancedOptions: [],
    selectedStoresIds: [],
    shortContentLinks: false,
    urlShortenerParams: {
      domainId: null
    }
  },
  view: {
    activeTemplate: 0,
    objectSample: null, //sample product, sample video
    isLoadingSample: false
  },
  settings: {
    from: '',
    isShowModal: true,
    inStock: false,
    brands: [],
    collections: [],
    types: []
  },
  loading: false,
  error: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AP_SET_IN_STOCK:
      return {
        ...state,
        settings: {
          ...state.settings,
          inStock: action.inStock
        }
      };

    case AP_SET_BRANDS:
      return {
        ...state,
        settings: {
          ...state.settings,
          brands: removeOrAdd(state.settings.brands, action.brand)
        }
      };

    case AP_SET_COLLECTIONS:
      return {
        ...state,
        settings: {
          ...state.settings,
          collections: removeOrAdd(state.settings.collections, action.collectionId)
        }
      };

    case AP_SET_TYPES:
      return {
        ...state,
        settings: {
          ...state.settings,
          types: removeOrAdd(state.settings.types, action.type)
        }
      };

    case AP_SET_IS_SHOW:
      return {
        ...state,
        settings: {
          ...state.settings,
          isShowModal: action.isShow
        }
      };

    case AP_SET_FROM:
      return {
        ...state,
        settings: {
          ...state.settings,
          from: action.from
        }
      };

    case AP_SET_MEDIA_TAGS:
      return {
        ...state,
        form: {
          ...state.form,
          mediaTags: action.values
        }
      };

    case AP_SET_ADD_MEDIA_PARTICIPANTS:
      return {
        ...state,
        form: {
          ...state.form,
          addParticipants: action.values
        }
      };

    case AP_SET_FAVORITE_MEDIA_PARTICIPANTS:
      return {
        ...state,
        form: {
          ...state.form,
          favoriteMediaParticipants: action.values
        }
      };

    case AP_SET_AUTOPILOT_ID:
      return validateAutopilotForm(setAutopilotModal(action.id, state, initialState));

    case AP_RESET_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          from: '',
          inStock: false,
          brands: [],
          collections: [],
          types: []
        }
      };

    case AP_CHANGE_NAME:
      return validateAutopilotForm({
        ...state,
        form: {
          ...state.form,
          name: action.name
        }
      });

    case AP_SET_MODE:
      return validateAutopilotForm({
        ...state,
        form: {
          ...state.form,
          mode: action.mode
        }
      });

    case AP_SET_TYPE_POST:
      return validateAutopilotForm({
        ...state,
        form: {
          ...state.form,
          typePost: action.typePost
        }
      });

    case AP_SET_SOURCE_TYPE:
      return validateAutopilotForm({
        ...state,
        form: {
          ...state.form,
          sourceType: action.sourceType,
          sourceBrand: action.sourceBrand,
          typePost: action.sourceType === 'VIDEO' ? 'PRODUCT_LINK' : 'PRODUCT_ALBUM',
          collections: [],
          brands: [],
          types: [],
          playlists: []
        }
      });

    case AP_SET_TEMPLATES:
      return validateAutopilotForm({
        ...state,
        form: {
          ...state.form,
          templates: action.templatesList
        }
      });

    case AP_SET_SOCIAL_PAGES:
      return validateAutopilotForm({
        ...state,
        form: {
          ...state.form,
          socialPages: action.socialPages
        }
      });

    case AP_SET_ACTIVE_TEMPLATE:
      return {
        ...state,
        view: {
          ...state.view,
          activeTemplate: action.num
        }
      };

    case AP_SET_FORM_FROM_SETTINGS:
      return validateAutopilotForm({
        ...state,
        form: {
          ...initialState.form,
          types: state.settings.types,
          collections: state.settings.collections,
          brands: state.settings.brands
        },
        view: initialState.view
      });

    case AP_SET_COLLECTIONS_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          collections: action.values
        }
      };

    case AP_SET_BRANDS_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          brands: action.values
        }
      };

    case AP_SET_STOCK_LEVELS_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          stockLevels: action.values
        }
      };

    case AP_SET_FEATURES_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          productFeatures: action.values
        }
      };

    case AP_SET_TYPES_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          types: action.values
        }
      };

    case AP_SET_PLAYLISTS:
      return {
        ...state,
        form: {
          ...state.form,
          playlists: action.playlists
        }
      };

    case AP_SET_SCHEDULES:
      return validateAutopilotForm(setSchedules(state, action));

    case AP_SET_OBJECT_SAMPLE:
      return {
        ...state,
        view: {
          ...state.view,
          objectSample: action.objectSample,
          isLoadingSample: false
        }
      };
    case AP_SET_LOADING_SAMPLE:
      return {
        ...state,
        view: {
          ...state.view,
          isLoadingSample: action.isLoadingSample
        }
      };

    case AP_SET_ADVANCED_OPTIONS:
      return {
        ...state,
        form: {
          ...state.form,
          advancedOptions: action.payload
        }
      };

    case AP_RESET_FORM:
      return {
        ...state,
        form: {
          ...initialState.form
        },
        view: initialState.view,
        settings: initialState.settings
      };

    case AP_SET_AUTOPILOT_LIST:
      return {
        ...state,
        autopilotList: action.autopilotList
      };

    case AP_ADD_AUTOPILOT:
      return {
        ...state,
        autopilotList: [...state.autopilotList, action.autopilot]
      };

    case AP_DELETE_AUTOPILOT:
      return {
        ...state,
        autopilotList: state.autopilotList.filter(autopilot => autopilot.id !== action.id)
      };

    case AP_CHANGE_ACTIVE_AUTOPILOT:
      return {
        ...state,
        autopilotList: state.autopilotList.map(autopilot =>
          autopilot.id !== action.id
            ? autopilot
            : { ...autopilot, status: action.status, error: null }
        )
      };

    case AP_EDIT_AUTOPILOT:
      return {
        ...state,
        autopilotList: state.autopilotList.map(autopilot =>
          autopilot.id !== action.id ? autopilot : action.autopilot
        )
      };

    case AP_LOADING:
      return {
        ...state,
        loading: action.isLoading,
        error: action.error ? action.error : null
      };

    case AP_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case AP_SELECT_STORE:
      return {
        ...state,
        form: {
          ...state.form,
          selectedStoresIds: [...state.form.selectedStoresIds, action.payload.storeId]
        }
      };

    case AP_UNSELECT_STORE:
      return {
        ...state,
        form: {
          ...state.form,
          selectedStoresIds: state.form.selectedStoresIds.filter(
            id => id !== action.payload.storeId
          )
        }
      };

    case AP_DESELECT_ALL_STORES:
      return {
        ...state,
        form: {
          ...state.form,
          selectedStoresIds: []
        }
      };

    case AP_SET_STORES:
      return {
        ...state,
        form: {
          ...state.form,
          selectedStoresIds: action.payload.storesIds
        }
      };

    case AP_TOGGLE_SHORT_CONTENT_LINKS:
      return {
        ...state,
        form: {
          ...state.form,
          shortContentLinks: !state.form.shortContentLinks,
          urlShortenerParams: {
            domainId:
              !state.form.shortContentLinks === false ? null : state.form.shortContentLinks.domainId
          }
        }
      };

    case AP_SELECT_SHORTENER_DOMAIN:
      return {
        ...state,
        form: {
          ...state.form,
          urlShortenerParams: {
            domainId: action.payload.id
          }
        }
      };

    default:
      return state;
  }
};
