import uuid from "uuid";

const dates = generateArrLastMonth();

export default [
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 100,
    transactionDate: dates[0].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 115,
    transactionDate: dates[1].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 350,
    transactionDate: dates[2].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 700,
    transactionDate: dates[3].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 9999,
    transactionDate: dates[4].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 18300,
    transactionDate: dates[5].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 140,
    transactionDate: dates[6].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 87,
    transactionDate: dates[7].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 960,
    transactionDate: dates[8].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 191,
    transactionDate: dates[9].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 1747,
    transactionDate: dates[10].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 1100,
    transactionDate: dates[13].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 1490,
    transactionDate: dates[17].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 222,
    transactionDate: dates[18].day
  },
  {
    id: uuid(),
    title: "You pay only the subscription and nothing else",
    amount: 430,
    transactionDate: dates[19].day
  }
];

function generateArrLastMonth() {
  const today = new Date();

  let year = today.getFullYear();
  let month = today.getMonth();
  let date = today.getDate();
  let daysArr = [];

  for (let i = 30; i > 0; i--) {
    let day = new Date(year, month, date - i);
    daysArr.push({ day: day.getTime().toString() });
  }

  return daysArr;
}
