import { SHOPIFY } from '../constants/stores';
import { SOURCE_TYPE_WITH_BRANDS } from '../constants/sources';

export const getSourceBrandFromObject = (object) => { //object - autopilot, template
  if (object && object.sourceBrand) {
    return object.sourceBrand === 'NONE' ? '' : object.sourceBrand;
  } else if (object && object.resourceType) {
    if (object.resourceType === 'PRODUCT') {
      return SHOPIFY;
    }
    if (!SOURCE_TYPE_WITH_BRANDS.includes(object.resourceType)) {
      return object.resourceType;
    }
  }
};
