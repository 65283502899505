export default media => {
  return {
    ...media,
    id: media.id,
    label: media.title
  }
};

export const returnObjForOptionMediaTag = media => {
  return {
    ...media,
    value: media.id,
    label: media.tag
  }
};
