import {
  PROFILE_GET_PENDING,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAIL,
  UPDATE_COMPANY_SUCCESS
} from 'store/actions/profile/profile';
import { profileGetSuccess } from './helpers/profileGetSuccess';

const initialState = {
  isLoading: false,
  error: null,
  activated: null,
  appSubscription: {},
  appFeatures: {},
  authorities: [],
  autopilotOn: null,
  company: {},
  companyName: null,
  countryCode: null,
  createdBy: null,
  createdDate: null,
  email: null,
  firstName: null,
  hasMoreThan1ActiveAutopilots: null,
  hasMoreThan1CreatedPosts: null,
  id: null,
  imageUrl: null,
  langKey: null,
  language: null,
  lastModifiedBy: null,
  lastModifiedDate: null,
  lastName: null,
  login: null,
  numberOfProducts: null,
  numberOfPublishedPosts: null,
  numberOfScheduledPosts: null,
  numberOfShops: null,
  numberOfSocialNetworkAccounts: null,
  phone: null,
  platforms: [],
  registrationType: null,
  shopUrls: null,
  socialNetworksUrls: null,
  status: null,
  subscribed: null,
  tariff: null,
  timezoneOffset: null,
  website: null,
  mediaCloudData: {},
  storeData: {},
  availableTrialSubscription: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_GET_PENDING:
      return { ...state, isLoading: true };

    case PROFILE_GET_SUCCESS:
      return profileGetSuccess(state, action);

    case PROFILE_GET_FAIL:
      return { ...state, isLoading: false, error: action.error };

    case UPDATE_COMPANY_SUCCESS:
      return { ...state,
        company: {
          ...state.company,
          name: action.payload.name,
          status: action.payload.status
        },
        companyName: action.payload.name
      };

    default:
      return state;
  }
};
