import isEmpty from "lodash/isEmpty";

export function validate(state, fieldName, value) {
  let fieldValidationErrors = state.formErrors;
  let nameValid = state.nameValid;
  let statusValid = state.statusValid;
  let typeValid = state.typeValid;
  let typeAdditionalValid = state.typeAdditionalValid;
  let stockValid = state.stockValid;
  let featuresValid = state.featuresValid;
  let socialTypeValid = state.socialTypeValid;
  let socialAccountsValid = state.socialAccountsValid;
  let socialPagesValid = state.socialPagesValid;
  let templateValid = state.templateValid;

  switch (fieldName) {
    case "name":
      if (value.length >= 3) {
        nameValid = true;
        fieldValidationErrors.name = "";
      } else {
        nameValid = false;
        fieldValidationErrors.name = "Name less than 3 symbols";
      }
      break;

    case "status":
      if (value) {
        statusValid = true;
        fieldValidationErrors.status = "";
      } else {
        statusValid = false;
        fieldValidationErrors.status = "Must choice status for you template";
      }
      break;

    case "type":
      if (!isEmpty(value)) {
        typeValid = true;
        fieldValidationErrors.type = "";
      } else {
        typeValid = false;
        fieldValidationErrors.type = "Must choice type for you template";
      }
      break;

    case "typeAdditional":
      if (!isEmpty(value)) {
        typeAdditionalValid = true;
        fieldValidationErrors.typeAdditional = "";
      } else {
        if (state.type === "ARTICLE" || state.type === "NEWS") {
          fieldValidationErrors.typeAdditional = "Must contain minimum one topic";
        }
        if (state.type === "PRODUCT_LINK" || state.type === "PRODUCT_ALBUM") {
          fieldValidationErrors.typeAdditional = "Must contain minimum one category";
        }
        typeAdditionalValid = false;
      }
      break;

    case "stock":
      if (!isEmpty(value)) {
        stockValid = true;
        fieldValidationErrors.stock = "";
      } else {
        stockValid = false;
        fieldValidationErrors.typeAdditional = "Must contain minimum one";
      }
      break;

    case "features":
      if (!isEmpty(value)) {
        featuresValid = true;
        fieldValidationErrors.features = "";
      } else {
        featuresValid = false;
        fieldValidationErrors.typeAdditional = "Must choice type of social page";
      }
      break;

    case "socialType":
      if (value.value === "FACEBOOK" || "INSTAGRAM" || "PINTEREST" || "TWITTER" || "INSTAGRAM") {
        socialTypeValid = true;
        fieldValidationErrors.socialType = "";
      } else {
        socialTypeValid = false;
        fieldValidationErrors.socialType = "Must contain social type";
      }
      break;

    case "socialAccounts":
      if (!isEmpty(value)) {
        socialAccountsValid = true;
        fieldValidationErrors.socialAccounts = "";
      } else {
        socialAccountsValid = false;
        fieldValidationErrors.socialAccounts = "Must contain minimum one account";
      }
      break;

    case "socialPages":
      if (!isEmpty(value)) {
        socialPagesValid = true;
        fieldValidationErrors.socialPages = "";
      } else {
        socialPagesValid = false;
        fieldValidationErrors.socialPages = "Must contain minimum one page";
      }
      break;

    case "template":
      if (value.length >= 1) {
        templateValid = true;
        fieldValidationErrors.template = "";
      } else {
        templateValid = false;
        fieldValidationErrors.template = "Must contain some text";
      }
      break;

    default:
      break;
  }

  let validationData = {
    formErrors: fieldValidationErrors,
    nameValid: nameValid,
    statusValid: statusValid,
    typeValid: typeValid,
    typeAdditionalValid: typeAdditionalValid,
    stockValid: stockValid,
    featuresValid: featuresValid,
    socialTypeValid: socialTypeValid,
    socialAccountsValid: socialAccountsValid,
    socialPagesValid: socialPagesValid,
    templateValid: templateValid,
  };

  return {
    ...validationData,
    formValid: validateForm(validationData, state)
  };
}

function validateForm(validationData, state) {
  return validationData.nameValid &&
    validationData.statusValid &&
    validationData.typeValid &&
    validationData.typeAdditionalValid &&
    validateStockAndFeatures(validationData, state) &&
    validationData.socialTypeValid &&
    validationData.socialAccountsValid &&
    validateSocialPages(validationData, state) &&
    validationData.templateValid;
}

function validateStockAndFeatures(validationData, state) {
  return state.type === "PRODUCT_LINK" || state.type === "PRODUCT_ALBUM" ?  validationData.stockValid && validationData.featuresValid : true;
}

function validateSocialPages(validationData, state) {
  return state.socialType.value === "FACEBOOK"|| state.socialType.value === "PINTEREST"  ? validationData.socialPagesValid : true;
}
