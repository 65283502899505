import { autopilotAPI } from 'dal/api.js';

// Actions
// import { initCalendarAction } from 'store/actions/calendar/calendar';

/*Prefix 'AP_'*/

/*Form*/
export const AP_CHANGE_NAME = 'AP_CHANGE_NAME';
export const AP_SET_SOCIAL_PAGES = 'AP_SET_SOCIAL_PAGES';
export const AP_SET_COLLECTIONS_FORM = 'AP_SET_COLLECTIONS_FORM';
export const AP_SET_BRANDS_FORM = 'AP_SET_BRANDS_FORM';
export const AP_SET_STOCK_LEVELS_FORM = 'AP_SET_STOCK_LEVELS_FORM';
export const AP_SET_FEATURES_FORM = 'AP_SET_FEATURES_FORM';
export const AP_SET_TYPES_FORM = 'AP_SET_TYPES_FORM';
export const AP_SET_PLAYLISTS = 'AP_SET_PLAYLISTS';
export const AP_SET_SCHEDULES = 'AP_SET_SCHEDULES';
export const AP_SET_TEMPLATES = 'AP_SET_TEMPLATES';
export const AP_SET_MODE = 'AP_SET_MODE';
export const AP_SET_TYPE_POST = 'AP_SET_TYPE_POST';
export const AP_SET_SOURCE_TYPE = 'AP_SET_SOURCE_TYPE';
export const AP_CHANGE_ADD_LOGO = 'AP_CHANGE_ADD_LOGO';
export const AP_SET_FORM_FROM_SETTINGS = 'AP_SET_FORM_FROM_SETTINGS';
export const AP_RESET_FORM = 'AP_RESET_FORM';
export const AP_SET_ADVANCED_OPTIONS = 'AP_SET_ADVANCED_OPTIONS';
export const AP_TOGGLE_SHORT_CONTENT_LINKS = 'AP_TOGGLE_SHORT_CONTENT_LINKS';
export const AP_SELECT_SHORTENER_DOMAIN = 'AP_SELECT_SHORTENER_DOMAIN';

/*Common*/
export const AP_LOADING = 'AP_LOADING';
export const AP_CLEAR_ERROR = 'AP_CLEAR_ERROR';

/*Modal*/
export const AP_SET_AUTOPILOT_ID = 'AP_SET_AUTOPILOT_ID';

/*View*/
export const AP_SET_ACTIVE_TEMPLATE = 'AP_SET_ACTIVE_TEMPLATE';
export const AP_SET_OBJECT_SAMPLE = 'AP_SET_OBJECT_SAMPLE';
export const AP_SET_LOADING_SAMPLE = 'AP_SET_LOADING_SAMPLE';

/*Settings*/
export const AP_SET_FROM = 'AP_SET_FROM';
export const AP_SET_IS_SHOW = 'AP_SET_IS_SHOW';
export const AP_SET_IN_STOCK = 'AP_SET_IN_STOCK';
export const AP_SET_TYPES = 'AP_SET_TYPES'; //Later
export const AP_SET_BRANDS = 'AP_SET_BRANDS';
export const AP_SET_COLLECTIONS = 'AP_SET_COLLECTIONS';
export const AP_RESET_SETTINGS = 'AP_RESET_SETTINGS';

/*Autopilot*/
export const AP_SET_AUTOPILOT_LIST = 'AP_SET_AUTOPILOT_LIST';
export const AP_ADD_AUTOPILOT = 'AP_ADD_AUTOPILOT';
export const AP_DELETE_AUTOPILOT = 'AP_DELETE_AUTOPILOT';
export const AP_CHANGE_ACTIVE_AUTOPILOT = 'AP_CHANGE_ACTIVE_AUTOPILOT';
export const AP_EDIT_AUTOPILOT = 'AP_EDIT_AUTOPILOT';
export const AP_SELECT_STORE = 'AP_SELECT_STORE';
export const AP_UNSELECT_STORE = 'AP_UNSELECT_STORE';
export const AP_SET_STORES = 'AP_SET_STORES';
export const AP_SET_MEDIA_TAGS = 'AP_SET_MEDIA_TAGS';
export const AP_SET_ADD_MEDIA_PARTICIPANTS = 'AP_SET_ADD_MEDIA_PARTICIPANTS';
export const AP_SET_FAVORITE_MEDIA_PARTICIPANTS = 'AP_SET_FAVORITE_MEDIA_PARTICIPANTS';
export const AP_DESELECT_ALL_STORES = 'AP_DESELECT_ALL_STORES';

export function setInStockSettings(inStock) {
  return {
    type: AP_SET_IN_STOCK,
    inStock
  };
}

export function setAutopilotModal(id) {
  return {
    type: AP_SET_AUTOPILOT_ID,
    id
  };
}

export function changeBrandsSettings(brand) {
  return {
    type: AP_SET_BRANDS,
    brand
  };
}

export function changeCollectionsSettings(collectionId) {
  return {
    type: AP_SET_COLLECTIONS,
    collectionId
  };
}

export function changeShowModalSettings(isShow) {
  return {
    type: AP_SET_IS_SHOW,
    isShow
  };
}

export function setFromSettings(from) {
  return {
    type: AP_SET_FROM,
    from
  };
}

export function resetSettings() {
  return {
    type: AP_RESET_SETTINGS
  };
}

export function resetForm() {
  return {
    type: AP_RESET_FORM
  };
}

export function setFormFromSettings() {
  return {
    type: AP_SET_FORM_FROM_SETTINGS
  };
}

export function setAdvancedOptions(options) {
  return {
    type: AP_SET_ADVANCED_OPTIONS,
    payload: options
  };
}

export function changeCampaignName(name) {
  return {
    type: AP_CHANGE_NAME,
    name
  };
}

export function changeMode(mode) {
  return {
    type: AP_SET_MODE,
    mode
  };
}

export function changeTypePost(typePost) {
  return {
    type: AP_SET_TYPE_POST,
    typePost
  };
}

export function changeSourceType(sourceType, sourceBrand = '') {
  return {
    type: AP_SET_SOURCE_TYPE,
    sourceType,
    sourceBrand
  };
}

export function clearError() {
  return {
    type: AP_CLEAR_ERROR
  };
}

export function setTemplates(templatesList) {
  return {
    type: AP_SET_TEMPLATES,
    templatesList
  };
}

export function setSocialPages(socialPages) {
  return {
    type: AP_SET_SOCIAL_PAGES,
    socialPages
  };
}

export function setActiveTemplate(num) {
  return {
    type: AP_SET_ACTIVE_TEMPLATE,
    num
  };
}

export function setSchedules(schedules) {
  return {
    type: AP_SET_SCHEDULES,
    schedules
  };
}

export function setPlaylists(playlists) {
  return {
    type: AP_SET_PLAYLISTS,
    playlists
  };
}

export function setCollectionsForm(values) {
  return {
    type: AP_SET_COLLECTIONS_FORM,
    values
  };
}

export function setBrandsForm(values) {
  return {
    type: AP_SET_BRANDS_FORM,
    values
  };
}

export function setStockLevelsForm(values) {
  return {
    type: AP_SET_STOCK_LEVELS_FORM,
    values
  };
}

export function setProductFeaturesForm(values) {
  return {
    type: AP_SET_FEATURES_FORM,
    values
  };
}

export function setMediaTags(values) {
  return {
    type: AP_SET_MEDIA_TAGS,
    values
  };
}

export function setAddMediaParticipants(values) {
  return {
    type: AP_SET_ADD_MEDIA_PARTICIPANTS,
    values
  };
}

export function setFavoriteMediaParticipants(values) {
  return {
    type: AP_SET_FAVORITE_MEDIA_PARTICIPANTS,
    values
  };
}

export function setTypesForm(values) {
  return {
    type: AP_SET_TYPES_FORM,
    values
  };
}

export function getObjectSample(options, objectType) {
  return dispatch => {
    dispatch({ type: AP_SET_LOADING_SAMPLE, isLoadingSample: true });
    dispatch({ type: AP_LOADING, isLoading: false });

    return autopilotAPI
      .getRandomObject(options, objectType)
      .then(res => {
        dispatch({
          type: AP_SET_OBJECT_SAMPLE,
          objectSample: res
        });

        return Promise.resolve(res);
      })
      .catch(error => {
        const errorMessage =
          error && error?.response?.data?.detail ||
            error?.response?.data?.title
            || error?.message || "Cannot receive a sample.";
        dispatch({ type: AP_SET_LOADING_SAMPLE, isLoadingSample: false });
        dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
        return Promise.reject(error);
      });
  };
}

export function getAutopilotList() {
  return dispatch => {
    dispatch({ type: AP_LOADING, isLoading: true });

    return autopilotAPI
      .getList()
      .then(autopilotList => {
        dispatch({ type: AP_SET_AUTOPILOT_LIST, autopilotList });
        dispatch({ type: AP_LOADING, isLoading: false });
      })
      .catch(error => {
        const errorMessage =
          error && error.response && error.response.data
            ? error.response.data.title
            : error.message;
        dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
        return Promise.reject(error);
      });
  };
}

export function startAutopilot(autopilotObject, id = null) {
  return dispatch => {
    dispatch({ type: AP_LOADING, isLoading: true });

    if (id) {
      return autopilotAPI
        .edit(id, autopilotObject)
        .then(res => {
          dispatch({ type: AP_LOADING, isLoading: false });
          dispatch({
            type: AP_EDIT_AUTOPILOT,
            id,
            autopilot: res
          });
          return Promise.resolve(res);
        })
        .catch(error => {
          let errorMessage = error?.response?.data?.detail || error?.response?.data?.title || error.message;

          if (error.code === 'ECONNABORTED') {
            errorMessage = 'Posts will be generated later Please wait...';
          }

          dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
          return Promise.reject(error);
        });
    } else {
      return autopilotAPI
        .saveStart(autopilotObject)
        .then(res => {
          dispatch({ type: AP_LOADING, isLoading: false });
          dispatch({
            type: AP_ADD_AUTOPILOT,
            autopilot: res
          });

          return Promise.resolve(res);
        })
        .catch(error => {
          let errorMessage = error?.response?.data?.detail || error?.response?.data?.title || error.message;

          if (error.code === 'ECONNABORTED') {
            errorMessage = 'Posts will be generated later Please wait...';
          }

          dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
          return Promise.reject(error);
        });
    }
  };
}

export function saveAutopilot(autopilotObject, id = null) {
  return dispatch => {
    dispatch({ type: AP_LOADING, isLoading: true });

    if (id) {
      return autopilotAPI
        .editDraft(id, autopilotObject)
        .then(res => {
          dispatch({ type: AP_LOADING, isLoading: false });
          dispatch({
            type: AP_EDIT_AUTOPILOT,
            id,
            autopilot: res
          });

          return Promise.resolve(res);
        })
        .catch(error => {
          const errorMessage =
            error && error.response && error.response.data
              ? error.response.data.title
              : error.message;
          dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
          return Promise.reject(error);
        });
    } else {
      return autopilotAPI
        .saveDraft(autopilotObject)
        .then(res => {
          dispatch({ type: AP_LOADING, isLoading: false });
          dispatch({
            type: AP_ADD_AUTOPILOT,
            autopilot: res
          });

          return Promise.resolve(res);
        })
        .catch(error => {
          const errorMessage =
            error && error.response && error.response.data
              ? error.response.data.title
              : error.message;
          dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
          return Promise.reject(error);
        });
    }
  };
}

export function setActiveAutopilot(id) {
  return dispatch => {
    dispatch({ type: AP_LOADING, isLoading: true });

    return autopilotAPI
      .active(id)
      .then(res => {
        dispatch({ type: AP_LOADING, isLoading: false });
        dispatch({
          type: AP_CHANGE_ACTIVE_AUTOPILOT,
          id,
          status: 'ACTIVE'
        });

        return Promise.resolve(res);
      })
      .catch(error => {
        const errorMessage =
          error && error.response && error.response.data
            ? error.response.data.title
            : error.message;
        dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
        return Promise.reject(error);
      });
  };
}

export function setInActiveAutopilot(id) {
  return dispatch => {
    dispatch({ type: AP_LOADING, isLoading: true });

    return autopilotAPI
      .inactive(id)
      .then(res => {
        dispatch({ type: AP_LOADING, isLoading: false });
        dispatch({
          type: AP_CHANGE_ACTIVE_AUTOPILOT,
          id,
          status: 'INACTIVE'
        });

        return Promise.resolve(res);
      })
      .catch(error => {
        const errorMessage =
          error && error.response && error.response.data
            ? error.response.data.title
            : error.message;
        dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
        return Promise.reject(error);
      });
  };
}

export function deleteAutopilot(id) {
  return dispatch => {
    dispatch({ type: AP_LOADING, isLoading: true });

    return autopilotAPI
      .delete(id)
      .then(res => {
        dispatch({ type: AP_LOADING, isLoading: false });
        dispatch({
          type: AP_DELETE_AUTOPILOT,
          id
        });

        return Promise.resolve(res);
      })
      .catch(error => {
        const errorMessage =
          error && error.response && error.response.data
            ? error.response.data.title
            : error.message;
        dispatch({ type: AP_LOADING, isLoading: false, error: errorMessage });
        return Promise.reject(error);
      });
  };
}

export function selectStoreInAutopilotAction(storeId) {
  return {
    type: AP_SELECT_STORE,
    payload: { storeId }
  };
}

export function deselectAllStoresInAutopilotAction() {
  return {
    type: AP_DESELECT_ALL_STORES,
    payload: {}
  };
}

export function unselectStoreInAutopilotAction(storeId) {
  return {
    type: AP_UNSELECT_STORE,
    payload: { storeId }
  };
}

export function setStoresInAutopilotAction(storesIds) {
  return {
    type: AP_SET_STORES,
    payload: { storesIds }
  };
}

export function toggleShortContentLinksAutopilotAction() {
  return {
    type: AP_TOGGLE_SHORT_CONTENT_LINKS
  };
}

export function selectUrlShortenerDomainAutopilotAction(id) {
  return {
    type: AP_SELECT_SHORTENER_DOMAIN,
    payload: { id }
  };
}
