import { VIDEO_ACCOUNT_TYPES } from 'constants/accounts';

const getModalSourceType = state => state.postModal.sourceType;
const getModalSourceBrand = state => state.postModal.sourceBrand;
const getStoresPayload = state => state.storesList.payload;

export default (storesList, accountsList, withoutNone = false) => {
  const storesListCleanRepeatNames = [...new Set(storesList.map(store => store.type))];

  let optionsForSourceTypeSelect = [];

  storesListCleanRepeatNames.forEach(storeType => {
    optionsForSourceTypeSelect.push({
      label: 'PRODUCT',
      value: storeType,
      icon: storeType
    });
  });

  optionsForSourceTypeSelect.push({
    label: 'MEDIA_IMAGES',
    value: 'MEDIA_IMAGES',
    icon: 'MEDIA_IMAGES'
  });

  optionsForSourceTypeSelect.push({
    label: 'MEDIA_VIDEOS',
    value: 'MEDIA_VIDEOS',
    icon: 'MEDIA_VIDEOS'
  });

  //If exist video source
  const videoAccounts = [...new Set(accountsList
    .filter(account => account.status === 'ACTIVE' && VIDEO_ACCOUNT_TYPES.includes(account.type))
    .map(account => account.type))];

  videoAccounts.forEach(accountType => optionsForSourceTypeSelect.push(
    {
      label: 'VIDEO',
      value: withoutNone ? 'VIDEO' : accountType, //fix later
      icon: accountType
    }));


  if (!withoutNone) optionsForSourceTypeSelect.push({ label: 'NONE', value: 'NONE', icon: 'NONE' });

  return optionsForSourceTypeSelect;
}

export const getValueForSourceTypeSelect = (sourceType, sourceBrand, withDefault = true) => {
  if(!withDefault && !Boolean(sourceType)) {
    return {};
  }
  return {
    label: sourceType ? sourceType : 'NONE',
    value: sourceBrand ? sourceBrand : '' ,
    icon: sourceBrand ? sourceBrand  : !withDefault ? sourceType : 'NONE' //fix later
  };
};

export const getSourceBrandFromItemAndType = (sourceItem, resourceType) => {
  if (resourceType === 'PRODUCT') {
    return sourceItem && sourceItem.shop ? sourceItem.shop.type : '';
  } else if (resourceType === 'VIDEO') {
    return sourceItem.channel && sourceItem.channel.socialAccount ? sourceItem.channel.socialAccount.type : '';
  } else if (['MEDIA_IMAGES', 'MEDIA_VIDEOS'].includes(resourceType)) {
    return resourceType;
  } else {
    return 'NONE';
  }
};
