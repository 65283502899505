import axios from 'axios';

export const SPY_FEED_GET_PENDING = 'SPY_FEED_GET_PENDING';
export const SPY_FEED_GET_SUCCESS = 'SPY_FEED_GET_SUCCESS';
export const SPY_FEED_GET_FAIL = 'SPY_FEED_GET_FAIL';

export const SPY_FEED_SET_SOCIAL_TYPE = 'SPY_FEED_SET_SOCIAL_TYPE';
export const SPY_FEED_SET_COMPETITOR = 'SPY_FEED_SET_COMPETITOR';
export const SPY_FEED_RESET_ERROR = 'SPY_FEED_RESET_ERROR';
export const SPY_FEED_RESET_STATE = 'SPY_FEED_RESET_STATE';

export function spyFeedGetAction() {
  return async (dispatch, getState) => {
    const { feedPagination, socialType, competitor } = getState().spyFeed;

    function getApiUri() {
      let uri = `/api/spy/posts`;

      if (socialType) {
        uri += `?type=${socialType}`;
      }
      if (competitor.value !== null) {
        uri += `&socialNetworkAccountId=${competitor.value}`;
      }

      return uri;
    }

    try {
      dispatch({ type: SPY_FEED_GET_PENDING });

      const res = await axios.get(getApiUri(), {
        params: { page: feedPagination.page, size: feedPagination.size }
      });

      dispatch({
        type: SPY_FEED_GET_SUCCESS,
        payload: { feed: res.data, feedTotalCount: +res.headers['x-total-count'] }
      });
    } catch (err) {
      dispatch({
        type: SPY_FEED_GET_FAIL,
        error: err.response?.data?.message || 'Someting went wrong on get feed'
      });
    }
  };
}

export function spyFeedSetSocialTypeAction(socialType) {
  return {
    type: SPY_FEED_SET_SOCIAL_TYPE,
    payload: socialType
  };
}

export function spyFeedSetCompetitorAction(competitor) {
  return {
    type: SPY_FEED_SET_COMPETITOR,
    payload: competitor
  };
}

export function spyFeedResetErrorAction() {
  return {
    type: SPY_FEED_RESET_ERROR
  };
}

export function spyFeedResetStateAction() {
  return {
    type: SPY_FEED_RESET_STATE
  };
}
