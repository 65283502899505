export function getWebsocketUrl() {
  return `${process.env.REACT_APP_API_URL}/websocket?access_token=${localStorage.getItem('id_token')}`;
}

export const destination = {
  product: '/user/queue/products',
  account: '/user/queue/social-network-accounts',
  object: '/user/queue/social-network-objects',
  video: '/user/queue/videos',
}
