import { SHOW_MODAL, HIDE_MODAL, modalTypes } from '../../actions/modal/modal';

const initialModalsState = () => {
  let state = [];
  for (const modal in modalTypes) {
    state.push({
      modalType: modalTypes[modal],
      isOpen: false,
      data: null
    });
  }
  return state;
};

export default (state = initialModalsState(), action) => {
  switch (action.type) {
    case SHOW_MODAL:
      const nextModals = state.map(modal => {
        if (modal.modalType === action.modalType) {
          return {
            ...modal,
            isOpen: true,
            data: action.data
          };
        } else if (action.withIgnoreModals) {
          return { ...modal };
        } else {
          return {
            ...modal,
            isOpen: false,
            data: action.data ? action.data : modal.data
          };
        }
      });

      return nextModals;

    case HIDE_MODAL:
      return state.map(modal => {
        if (modal.modalType === action.modalType) {
          return {
            ...modal,
            isOpen: false,
            data: action.data ? action.data : modal.data
          };
        } else if (action.withIgnoreModals) {
          return { ...modal };
        } else {
          return {
            ...modal,
            isOpen: false,
            data: action.data ? action.data : modal.data
          };
        }
      });
    default:
      return state;
  }
};
