import axios from 'axios';

import {
  DND_STATUS_DISABLED_OFF
} from '../dndStatus'

export const POST_NOW = 'POST_NOW';
export const POST_NOW_SUCCESS = 'POST_NOW_SUCCESS';
export const POST_NOW_FAILURE = 'POST_NOW_FAILURE';

export const SCHEDULE_NEW_POST = 'SCHEDULE_NEW_POST';
export const SCHEDULE_NEW_POST_SUCCESS = 'SCHEDULE_NEW_POST_SUCCESS';
export const SCHEDULE_NEW_POST_FAILURE = 'SCHEDULE_NEW_POST_FAILURE';

export const PUT_POST = 'PUT_POST';
export const PUT_POST_SUCCESS = 'PUT_POST_SUCCESS';
export const PUT_POST_FAILURE = 'PUT_POST_FAILURE';

export const CLEAR_NEW_POST_ERROR_STATE = 'CLEAR_NEW_POST_ERROR_STATE';


export function postNowAction(data) {
  return dispatch => {
    dispatch({ type: POST_NOW });

    return axios
      // .post(`/api/posts/publish`, data)
      .post(`/api/posts/publish/multiple`, data)
      .then(res => {
        dispatch({ type: POST_NOW_SUCCESS });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: POST_NOW_FAILURE,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function scheduleNewPostAction(data) {
  return dispatch => {
    dispatch({ type: SCHEDULE_NEW_POST });

    return axios
      // .post(`/api/posts/scheduled`, data)
      .post(`/api/posts/scheduled/multiple`, data)
      .then(res => {
        dispatch({ type: SCHEDULE_NEW_POST_SUCCESS });
        return Promise.resolve(res);
      })
      .catch(error => {
        dispatch({
          type: SCHEDULE_NEW_POST_FAILURE,
          error,
        });
        return Promise.reject(error);
      });
  };
}

export function putPostAction(data) {
  return dispatch => {
    dispatch({ type: PUT_POST });

    const url = (data.status === 'SCHEDULE' || data.status === 'ERROR') ? `/api/posts/` : `/api/posts/${data.id}/${data.status.toLowerCase()}`;

    return axios
      .put(url, data)
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          dispatch({ type: DND_STATUS_DISABLED_OFF });
          return Promise.resolve(res);
        }
      })
      .catch(error => {
        dispatch({ type: DND_STATUS_DISABLED_OFF });
        dispatch({
          type: PUT_POST,
          error
        });
        return Promise.reject(error);
      });
  };
}

export function clearNewPostErrorState() {
  return dispatch => {
    dispatch({ type: CLEAR_NEW_POST_ERROR_STATE });
  };
}
