import { SHOPIFY } from './stores';

export const excludeSourceType = (type) => {
  switch(type) {
    case 'PRODUCT':
      return {
        content:
          ['id', 'value', 'url', 'label', 'name', 'description', 'descriptionHtml',
        'images', 'priceRegularWithCurrency', 'priceSale', 'priceSaleWithCurrency'],
        other:
          ['id', 'value', 'url', 'label', 'images', 'descriptionHtml',
          'priceRegularWithCurrency', 'priceSale', 'priceSaleWithCurrency']
      };
    case 'VIDEO':
      return {
        content:
          ['id', 'externalId', 'title', 'createdAt', 'updateDatetime', 'publishedAt',
        'externalUpdateDatetime', 'description', 'likesCount', 'dislikesCount', 'commentsCount', 'sharesCount',
        'viewsCount', 'duration', 'externalUrl', 'definition', 'posts', 'value', 'label', 'channel', 'thumbnails'],
        other:
          ['id', 'externalId', 'createdAt', 'updateDatetime', 'publishedAt',
          'externalUpdateDatetime', 'description', 'likesCount', 'dislikesCount', 'commentsCount', 'sharesCount',
          'viewsCount', 'duration', 'externalUrl', 'definition', 'posts', 'value', 'label', 'channel', 'thumbnails']};
    case 'MEDIA_IMAGES':
    case 'MEDIA_VIDEOS':
      return {
        content:
          ['id', 'url', 'name', 'description', 'descriptionHtml', 'descriptionDraftJsRaw', 'title', 'createdAt', 'status', 'createdAt',
          'updatedAt', 'externalUrl', 'source', 'type', 'mimeType', 'contentLength',
          'downloadUrl', 'label'],
        other:
          ['id', 'url', 'name', 'description', 'descriptionHtml', 'descriptionDraftJsRaw', 'title', 'createdAt', 'status', 'createdAt',
          'updatedAt', 'externalUrl', 'source', 'type', 'mimeType', 'contentLength',
          'downloadUrl', 'label']}
      default:
        return {content: [], other:[]}
  }
}

export const nameButtonTextarea = (type) => {

  switch(type) {
    case 'VIDEO':
      return { url: 'video', description: 'description', images: 'preview', name: 'title' };
    case 'MEDIA_IMAGES':
    case 'MEDIA_VIDEOS':
        return { description: 'description', 'added_by': 'added_by', hashtags: 'hashtags' };
    default:
      return { url: 'url', images: 'images', name: 'name', description: 'description'};
  }
}

export const DEFAULT_SELECT_SOURCE = SHOPIFY;

export const SOURCE_TYPE_WITH_BRANDS = ['PRODUCT'];
