import {
  GET_ACL_COMPANIES_PENDING,
  GET_ACL_COMPANIES_SUCCESS,
  GET_ACL_COMPANIES_FAIL,
  CLEAR_ACL_COMPANIES_ERROR,
  GET_ACL_TEAM_PENDING,
  GET_ACL_TEAM_SUCCESS,
  GET_ACL_TEAM_FAIL,
  CLEAR_ACL_TEAM_ERROR,
  UPDATE_ACL_ITEM,
  DELETE_ACL_ITEM,
  UPDATE_ACL_ROLES,
  GET_ACL_ROLES_PENDING,
  GET_ACL_ROLES_SUCCESS,
  GET_ACL_ROLES_FAIL,
  PROCESS_ACL_INVITE_PENDING,
  PROCESS_ACL_INVITE_SUCCESS,
  PROCESS_ACL_INVITE_FAIL,
  CHANGE_ACL_ROLE_PENDING,
  CHANGE_ACL_ROLE_SUCCESS,
  CHANGE_ACL_ROLE_FAIL,
  SET_NEW_ACL_SUCCESS
} from 'store/actions/acl/acl';
import { updateAclItem } from './helpers/updateAclItem';
import { deleteAclItem } from './helpers/deleteAclItem';
import { updateAclRoles } from './helpers/updateAclRoles';
import { changeAclRole } from './helpers/changeAclRole';
import { setCurrentAcl } from './helpers/setCurrentAcl';

const initialState = {
  roles: {
    ADD_USER: {
      list: [],
      loading: false,
      didFirstLoad: false,
      error: ''
    }
  },
  companies: {
    list: [],
    loading: false,
    didFirstLoad: false,
    count: 0,
    currentCompany: null,
    error: ''
  },
  team: {
    list: [],
    loading: false,
    didFirstLoad: false,
    count: 0,
    error: ''
  }
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ACL_COMPANIES_PENDING:
      return { ...state, companies: { ...state.companies, loading: true } };

    case GET_ACL_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: false,
          didFirstLoad: true,
          count: payload.count,
          list: payload.data
        }
      };

    case GET_ACL_COMPANIES_FAIL:
      return { ...state, companies: { ...state.companies, error: payload.error, loading: false } };

    case CLEAR_ACL_COMPANIES_ERROR:
      return { ...state, companies: { ...state.companies, error: '' } };

    case GET_ACL_TEAM_PENDING:
      return { ...state, team: { ...state.team, loading: true } };

    case GET_ACL_TEAM_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          loading: false,
          didFirstLoad: true,
          count: payload.count,
          list: payload.data
        }
      };

    case GET_ACL_TEAM_FAIL:
      return { ...state, team: { ...state.team, error: payload.error, loading: false } };

    case CLEAR_ACL_TEAM_ERROR:
      return { ...state, team: { ...state.team, error: '' } };

    case UPDATE_ACL_ITEM:
      return updateAclItem(state, payload.listName, payload.elementInd, payload.nextProps);

    case DELETE_ACL_ITEM:
      return deleteAclItem(state, action);

    case UPDATE_ACL_ROLES:
      return updateAclRoles(state, action);

    case GET_ACL_ROLES_PENDING:
    case GET_ACL_ROLES_SUCCESS:
    case GET_ACL_ROLES_FAIL:
      return updateAclRoles(state, payload.type, payload.nextProps);

    case PROCESS_ACL_INVITE_PENDING:
    case PROCESS_ACL_INVITE_SUCCESS:
    case PROCESS_ACL_INVITE_FAIL:
      return updateAclItem(state, 'companies', payload.elementInd, payload.nextProps);

    case CHANGE_ACL_ROLE_PENDING:
    case CHANGE_ACL_ROLE_FAIL:
      return updateAclItem(state, 'team', payload.elementInd, payload.nextProps);

    case CHANGE_ACL_ROLE_SUCCESS:
      return changeAclRole(state, action);

    case SET_NEW_ACL_SUCCESS:
      return setCurrentAcl(state, payload.currentCompany);

    default:
      return state;
  }
};
