import sortBy from "lodash/sortBy";

export function getTopicsSuccess(state, action) {
  const newPayload = sortBy(action.payload, ["name"]);

  return Object.assign({}, state, {
    payload: newPayload,
    loading: false,
    error: null,
    maxAmount: newPayload.length >= 10,
  });
}
