import moment from 'moment';
import { toast } from 'react-toastify';

import returnObjForOptionProduct from 'util/returnObjForOptionProduct';
import returnObjForOptionVideo from 'util/returnObjForOptionVideo';
import returnObjForOptionMedia from 'util/returnObjForOptionMedia';
import prepareOptionsForSourceTypeSelect from 'util/prepareOptionsForSourceTypeSelect';
import { getValueForSocialPagesSelect } from 'util/asyncSelectHelper';

export default function preparePostModalEdit(state, action) {
  const {
    id,
    autopilot,
    type,
    resourceType,
    socialType,
    socialAccount,
    socialObject,
    link,
    content,
    contentHtml,
    images,
    videos,
    scheduledPublicationDate,
    name,
    urlShortenerParams
  } = action.data;

  const socialPage = _getSocialPage(socialObject, socialAccount, socialType);
  const sourceItem = _getSourceItem(resourceType, action.data);
  const momentPostDate = _getScheduledDate(state, scheduledPublicationDate);
  // Selects
  const dataForSocialPagesSelect = _prepareDataForSocialPagesSelect(socialPage);
  const optionsForSourceTypeSelect = prepareOptionsForSourceTypeSelect(
    action.storesList,
    action.accountPayload
  );
  const dataForSourceItemsSelect = _prepareDataForSourceItemsSelect(
    resourceType,
    sourceItem,
    state
  );

  if (resourceType === 'PRODUCT' && dataForSourceItemsSelect.value === null) {
    toast.error(`The product associated with the post has been removed or moved to draft.
      Change product or delete this post.`);
  }
  const sourceBrand = _getSourceBrand(resourceType, action.data);

  return {
    ...state,
    postId: id,
    autopilot: autopilot,
    postType: type,
    socialType,
    socialPages: [
      {
        default: [],
        socialObjects: [socialPage],
        socialType
      }
    ],
    sourceType: resourceType,
    sourceBrand,
    typeModal: 'EDIT_POST',
    sourceItem,
    content,
    link: link || '',
    albumName: name || '',
    contentHtml,
    images,
    videos,
    extraPublishingParams: (socialType && type) ? {
      [socialType]: {
        postType: type
      }
    } : {},
    momentPostDate,
    urlShortenerParams: {
      domainId: urlShortenerParams?.domain?.id || state.urlShortenerParams.domainId
    },
    shortContentLinks: !!urlShortenerParams?.domain?.id,
    dataForSocialPagesSelect,
    optionsForSourceTypeSelect,
    dataForSourceItemsSelect,
    formValid: true
  };
}

function _getSocialPage(socialObject, socialAccounts, socialType) {
  if (socialType === 'FACEBOOK' || socialType === 'LINKEDIN' || socialType === 'PINTEREST') {
    return {
      ...socialObject,
      label: socialObject.name,
      accounts: [socialAccounts]
    };
  }

  return {
    ...socialAccounts,
    label: socialAccounts.name
  };
}

function _getSourceItem(resourceType, data) {
  if (resourceType === 'PRODUCT') {
    return data.product;
  } else if (resourceType === 'VIDEO') {
    return data.video;
  } else if (resourceType === 'MEDIA_IMAGES') {
    return data.images[0]?.mediaContent || null;
  } else if (resourceType === 'MEDIA_VIDEOS') {
    return data.videos[0]?.mediaContent || null;
  }
}

function _getSourceBrand(resourceType, data) {
  if (resourceType === 'PRODUCT') {
    return data.product?.shop?.type || data.productMain?.shop?.type || '';
  } else if (resourceType === 'VIDEO') {
    return data.video && data.video.channel && data.video.channel.socialAccount
      ? data.video.channel.socialAccount.type
      : '';
  } else if (resourceType === 'MEDIA_IMAGES') {
    return resourceType;
  } else if (resourceType === 'MEDIA_VIDEOS') {
    return resourceType;
  } else {
    return '';
  }
}

function _prepareDataForSocialPagesSelect(socialPage) {
  return {
    value: { ...getValueForSocialPagesSelect(socialPage) },
    defaultOptions: []
  };
}

function _prepareDataForSourceItemsSelect(resourceType, sourceItem, state) {
  if (resourceType === 'PRODUCT') {
    const product = sourceItem;
    const currentProductForSelect = returnObjForOptionProduct(product);

    return {
      placeholder: 'Source items',
      value: currentProductForSelect,
      defaultOptions: [],
      disabled: false
    };
  } else if (resourceType === 'VIDEO') {
    const video = sourceItem;
    const currentVideoForSelect = video ? returnObjForOptionVideo(video) : null;

    return {
      placeholder: 'Source items',
      value: currentVideoForSelect,
      defaultOptions: [],
      disabled: false
    };
  } else if (['MEDIA_IMAGES', 'MEDIA_VIDEOS'].includes(resourceType)) {
    const media = sourceItem;
    const currentMediaForSelect = media ? returnObjForOptionMedia(media) : null;

    return {
      placeholder: 'Source items',
      value: currentMediaForSelect,
      defaultOptions: [],
      disabled: false
    };
  } else {
    return state.dataForSourceItemsSelect;
  }
}

function _getScheduledDate(state, scheduledPublicationDate) {
  if (scheduledPublicationDate) {
    return moment(scheduledPublicationDate, 'YYYY-MM-DDTHH:mm:ss');
  } else {
    return state.momentPostDate;
  }
}
