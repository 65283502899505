import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';

export default function utcToLocal(utcString, format = DATE_TIME_FORMAT) {
  return utcString
    ? moment
        .utc(utcString, format)
        .local()
        .format(format)
    : null;
}
