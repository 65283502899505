import {
  ADD_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAILURE,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  REMOVE_CATEGORY,
  REMOVE_CATEGORY_SUCCESS,
  REMOVE_CATEGORY_FAILURE,
} from '../../actions/category';
import baseReducerHandler from 'util/baseReducerHandler';
import { getCategoriesSuccess } from './getCategories';

const initialState = {
  payload: [],
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return baseReducerHandler(state, true, null);
    case ADD_CATEGORY_SUCCESS:
    case ADD_CATEGORY_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case GET_CATEGORIES:
      return baseReducerHandler(state, true, null);
    case GET_CATEGORIES_SUCCESS:
      return getCategoriesSuccess(state, action);
    case GET_CATEGORIES_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case REMOVE_CATEGORY:
      return baseReducerHandler(state, true, null);
    case REMOVE_CATEGORY_SUCCESS:
    case REMOVE_CATEGORY_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    default:
      return state;
  }
};
