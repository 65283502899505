export function getProductListSuccess(state, action) {
  let result = {
    loading: false,
    error: null,
    payload: state.payload,
    cursor: action.cursor,
    pageNumber: action.pageNumber,
    shopId: action.shopId,
    hasNext: action.hasNext
  };

  result.payload = action.shopType ? [...state.payload.filter( product =>
    !product.shop
    || !product.shop.type
    || product.shop.type !== action.shopType), ...action.payload] : action.payload;
  return Object.assign({}, state, result);
}
