import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useMutation } from 'react-query';
import axios from 'axios';
import { useActions } from 'hooks';
import { showModal, hideModal, modalTypes } from 'store/actions/modal/modal';
import { getAccountsAction } from 'store/actions/account';
import { getStoresAction, clearStoreErrorState } from 'store/actions/store/store';
import WithErrorHandling from 'components/common/withErrorHandling/withErrorHandling';
import Button from 'components/common/button/button';
import Loader from 'components/common/loader/loader';
import ModalComponent from 'components/common/modal/modal';
import getModalData from 'util/getModalData';
import classes from './upgradePlanSelectStoreModal.module.css';

export const UpgradePlanSelectStoreModal = memo(() => {
  const [selectedStoreId, setSelectedStoreId] = useState();
  const [error, setError] = useState(null);
  const actions = useActions({
    showModal,
    hideModal,
    getStoresAction,
    clearStoreErrorState,
    getAccountsAction
  });
  const modalData = useSelector(state => getModalData(state, modalTypes.UPGRADE_PLAN_SELECT_STORE));
  const storesList = useSelector(state => state.storesList);
  const billingSubMutation = useMutation(
    () =>
      axios.post(
        `/api/billing/subscription/${modalData.data.planId}?shopId=${selectedStoreId || ''}`
      ),
    {
      onSuccess: async res => {
        if (res.data?.confirmationUrl) {
          window.location.replace(res.data.confirmationUrl);
        } else {
          // for free subscription
          window.location.replace('/upgrade?success-get-free=true');
        }
      },
      onError: err => {
        setError(err.response.data?.detail || 'Something went wrong on start billing');
      }
    }
  );

  useEffect(() => {
    if (!storesList.playload?.length && modalData.isOpen) {
      actions.getStoresAction();
    }
  }, [modalData.isOpen]);

  useEffect(() => {
    setError(storesList.error);
  }, [storesList.error]);

  return (
    <WithErrorHandling
      showError={error}
      errorMessage={error}
      clearErrorHandler={actions.clearStoreErrorState}
    >
      <ModalComponent
        center
        isOpen={modalData.isOpen}
        closeModal={actions.hideModal}
        hideCloseButton={true}
        modalPadding="0"
        width={516}
      >
        <div className={classes['select-stores']}>
          <div className="modal__content modal__content_theme_dark">
            <h2 className="h2">{modalData?.data?.planType !== 'FREE' ? 'Select store for payment' : 'Are you sure?'}</h2>
            {modalData?.data?.planType !== 'FREE' ? (
              <>
                <p className={classes['select-stores__text']}>
                  Which store for payment would you like to use?
                </p>

                <div className={classes['select-stores__list']}>
                  {storesList.loading ? (
                    <Loader
                      img={{ width: 75, height: 75 }}
                      className={classes['select-stores__loader']}
                    />
                  ) : (
                    storesList.payload
                      .filter(s => s.type === 'SHOPIFY')
                      .map(store => (
                        <button
                          key={store.id}
                          onClick={() => setSelectedStoreId(store.id)}
                          className={cn(classes['select-stores__item'], {
                            [classes['select-stores__item_selected']]: store.id === selectedStoreId
                          })}
                        >
                          {store.name}
                        </button>
                      ))
                  )}
                </div>
              </>
            ) : (
              <p className={classes['select-stores__text']}>
                If you continue, your plan will be downgraded.
              </p>
            )}

            <div className="modal__actions">
              <Button
                black
                label="Continue"
                disabled={
                  modalData?.data?.planType !== 'FREE' &&
                  (!selectedStoreId || billingSubMutation.isLoading)
                }
                loading={billingSubMutation.isLoading}
                onClick={() => billingSubMutation.mutate()}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
    </WithErrorHandling>
  );
});
