export default function datesToBlackList(state, action) {
  const { accountId, socialObjId, dates } = action;
  const idItemFromBlackList = `${accountId}${socialObjId}`;
  const item = state.blackList[idItemFromBlackList];

  return {
    ...state,
    blackList: {
      ...state.blackList,
      [idItemFromBlackList]: item ? [...item, ...dates] : [...dates]
    }
  };
}


export function datesToBlackListFromObject(state, objectBlackList) {

  const blackList = { ...state.blackList };

  for (const [idItemFromBlackList, value] of Object.entries(objectBlackList)) {
    if (blackList[idItemFromBlackList]) {
      blackList[idItemFromBlackList] = [...new Set([...blackList[idItemFromBlackList], ...value])];
    } else {
      blackList[idItemFromBlackList] = value;
    }
  }

  return {
    ...state,
    // blackList LO-376
    objectBlackList
  };
}
