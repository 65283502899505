function findInCountArray(page, countArray) {
  const socialType = page.socialNetworkType ? page.socialNetworkType : page.type
  const countFinded =  countArray.find(countEl => countEl.id === page.id && countEl.type === socialType)
  return countFinded  ? countFinded.count : false
}

export default function toggleHasScheduledPosts(state, action) {
  const copyActivePayload = state.activePayload.slice();

  copyActivePayload.forEach((item, ind) => {
    const count = findInCountArray(item, action.schedulePostsCount)
    if ( count ) {
      copyActivePayload[ind].scheduledPosts = count;
      copyActivePayload[ind].hasScheduledPosts = true;
    } else {
      copyActivePayload[ind].scheduledPosts = 0;
      copyActivePayload[ind].hasScheduledPosts = false;
    }
  });

  return Object.assign({}, state, {
    activePayload: copyActivePayload
  });
}
