import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { getWebsocketUrl } from 'constants/webSocket';
import { clientWS } from 'index';

/** Router for WS connection
 * @param {string} route - path to ws topic
 * @param {function(data: unknown, client: Stomp): void} callback - callback after success connect to the topic
 * @param {boolean} withNewConnection - should create a new connection
 * @returns {void}
 */
export function routerWS(route, callback, withNewConnection) {
  if (withNewConnection) {
    const client = Stomp.over(() => new SockJS(getWebsocketUrl()));

    client.reconnect_delay = 100;
    client.debug = () => {};
    client.connect({}, () => {
      client.subscribe(route, data => {
        callback(JSON.parse(data.body), client);
      });
    });
  } else {
    clientWS.subscribe(route, data => {
      callback(JSON.parse(data.body), clientWS);
    });
  }
}
