import { _formatCloudMediaForPost } from '../_formatCloudMediaForPost/_formatCloudMediaForPost';

export default function uploadPostMedia(state, action) {
  const { mediaKey, file } = action.payload || {};
  const nextState = { ...state, [mediaKey]: [...state[mediaKey]] };

  nextState[mediaKey].unshift(_formatCloudMediaForPost(mediaKey, file, true));

  return nextState;
}
