import {
  GET_ALL_COMPANIES,
  GET_ALL_COMPANIES_SUCCESS,
  GET_ALL_COMPANIES_FAILURE,
  SET_ACCOUNT_COMPANY_DATA,
  SET_ACCOUNT_COMPANY_DATA_SUCCESS,
  SET_ACCOUNT_COMPANY_DATA_FAILURE,
  SET_NEW_ACCOUNT_COMPANY,
  SET_NEW_ACCOUNT_COMPANY_SUCCESS,
  SET_NEW_ACCOUNT_COMPANY_FAILURE,
  CREATE_DEFAULT_COMPANY,
  CREATE_DEFAULT_COMPANY_SUCCESS
} from '../../actions/companies';

import baseReducerHandler from 'util/baseReducerHandler';

const initialState = {
  loading: false,
  error: null,
  payload: null,
  userCompany: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COMPANIES:
      return baseReducerHandler(state, true, null);
    case GET_ALL_COMPANIES_SUCCESS:
    case GET_ALL_COMPANIES_FAILURE:
      return baseReducerHandler(
        {
          ...state,
          payload: action.payload
            ? action.payload.map(company => {
                return {
                  value: company.id,
                  label: company.name
                };
              })
            : null
        },
        false,
        action.error ? action.error : null
      );

    case SET_ACCOUNT_COMPANY_DATA:
      return baseReducerHandler(state, true, null);
    case SET_ACCOUNT_COMPANY_DATA_SUCCESS:
      return baseReducerHandler(
        {
          ...state,
          userCompany: {
            value: action.payload.id,
            label: action.payload.name
          }
        },
        false,
        action.error ? action.error : null
      );
    case SET_ACCOUNT_COMPANY_DATA_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);

    case SET_NEW_ACCOUNT_COMPANY:
      return baseReducerHandler(state, true, null);
    case SET_NEW_ACCOUNT_COMPANY_SUCCESS:
    case SET_NEW_ACCOUNT_COMPANY_FAILURE:
      return baseReducerHandler(state, false, action.error ? action.error : null);
    case CREATE_DEFAULT_COMPANY:
      return baseReducerHandler(state, true, null);
    case CREATE_DEFAULT_COMPANY_SUCCESS:
      return baseReducerHandler(
        {
          ...state,
          company: {
            id: action.payload.id,
            name: action.payload.name
          }
        }
        , true, null);
    default:
      return state;
  }
};
