import { typePostFromApi } from 'util/definePostType';
import { getSourceBrandFromObject } from '../../../../util/getInfoFromApiObjects';
import { removeExcessBlankLines } from 'util/postTemplateUtils';

export default (id, state, initialState) => {

  const editTemplate = state.templateList.find(template => template.id === id);

  let form = { ...state.form };
  if (editTemplate) {
    form.templateId = editTemplate.id;
    form.name = editTemplate.name;
    form.socialPages = _getSocialPages(editTemplate.socialTypes, editTemplate.socialAccounts, editTemplate.socialObjects);

    form.collections = editTemplate.productCategories ? editTemplate.productCategories : [];
    form.types = editTemplate.productFeatures || [];
    form.brands = editTemplate.brands ? editTemplate.brands.filter(brand => !!brand) : [];
    form.productFeatures = editTemplate.productFeatures ? editTemplate.productFeatures : [];
    form.stockLevels = editTemplate.stockLevels ? editTemplate.stockLevels : [];
    form.mediaTags = editTemplate.mediaTags ? editTemplate.mediaTags : [];

    form.typePost = typePostFromApi(editTemplate.type);
    form.sourceType = _getSourceType(editTemplate);
    form.sourceBrand = getSourceBrandFromObject(editTemplate);

    form.playlists = editTemplate.videoPlaylists ? [...editTemplate.videoPlaylists] : [];
    form.template = {
      albumNameTemplate: editTemplate.albumNameTemplate || '',
      contentTemplate: editTemplate.template || '',
      contentHtmlTemplate: removeExcessBlankLines(editTemplate.contentHtmlTemplate),
      imageContentTemplate: editTemplate.imageContentTemplate || ''
    };
  }

  return {
    ...state,
    form: form,
    view: initialState.view
  };
}

const _getSocialPages = (socialTypes, accounts, objects) => {

  let socialPages = socialTypes
    ? socialTypes.map(socialType => ({ socialType, socialObjects: [], default: [] }))
    : [];

  [...accounts, ...objects].forEach(object => {
    const socialType = object.socialNetworkType || object.type;
    const findedIndexSocialType = socialPages.findIndex(socialPage => socialPage.socialType === socialType);
    if (findedIndexSocialType + 1) {
      socialPages[findedIndexSocialType].socialObjects.push(object);
    } else {
      socialPages.push({
        socialType: socialType,
        socialObjects: [object],
        default: []
      });
    }
  });


  return socialPages.length ? socialPages
    : [{
      socialType: '',
      socialObjects: [],
      default: []
    }];

};

const _getSourceType = (template) => {
  if (template && template.resourceType) {
    return template.resourceType === 'NONE' ? '' : template.resourceType;
  } else if ((template.productFeatures && template.productFeatures.length)
    || (template.productCategories && template.productCategories.length)
    || (template.brands && template.brands.length)) {
    return 'PRODUCT';
  } else if (template.videoPlaylists && template.videoPlaylists.length) {
    return 'VIDEO';
  } else if (template.mediaTags && template.mediaTags.length) {
    // TODO
    return 'MEDIA_IMAGES';
  } else {
    return '';
  }
};
