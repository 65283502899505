import { validate } from "./validate";

export function handleField(state, action) {
  const name = action.name;
  const value = action.value;

  return Object.assign(
    {},
    state,
    {
      [name]: value,
      loading: false,
      formErrors: {
        ...state.formErrors,
        [name]: "",
      }
    },
    validate(state, name, value),
  );
}

export function handleSelect(state, action) {
  const name = action.name;
  const value = action.value;

  return Object.assign(
    {},
    state,
    {
      loading: false,
      [name]: value,
    },
    validate(state, name, value),
  );
}
