import { productAPI } from 'dal/api.js';

export const REORDER_PRODUCT = 'REORDER_PRODUCT';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const LOAD_MORE_PRODUCTS = 'LOAD_MORE_PRODUCTS';
export const LOAD_MORE_PRODUCTS_SUCCESS = 'LOAD_MORE_PRODUCTS_SUCCESS';
export const LOAD_MORE_PRODUCTS_FAILURE = 'LOAD_MORE_PRODUCTS_FAILURE';

export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_FAILURE = 'SEARCH_PRODUCTS_FAILURE';

export const CLEAR_PRODUCT_LIST_REDUX_STATE = 'CLEAR_PRODUCT_LIST_REDUX_STATE';

export const SET_BRANDS_LIST = 'SET_BRANDS_LIST';

export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCTS = 'DELETE_PRODUCTS';
export const CLEAR_PRODUCT_LIST_ERROR_STATE = 'CLEAR_PRODUCT_LIST_ERROR_STATE';
export const SET_SHOW_LIMIT_TO_PRODUCTS = 'SET_SHOW_LIMIT_TO_PRODUCTS';

export const GET_FILTER_OPTIONS_FOR_PRODUCTS = 'GET_FILTER_OPTIONS_FOR_PRODUCTS';
export const GET_FILTER_OPTIONS_FOR_PRODUCTS_FAILURE = 'GET_FILTER_OPTIONS_FOR_PRODUCTS_FAILURE';
export const REMOVE_FILTER_OPTIONS_FOR_PRODUCTS = 'REMOVE_FILTER_OPTIONS_FOR_PRODUCTS';

export const TOGGLE_CHECK_FILTER_OPTION_FOR_PRODUCTS = 'TOGGLE_CHECK_FILTER_OPTION_FOR_PRODUCTS';
export const SET_FILTER_OPTION_FOR_PRODUCTS_ALL = 'SET_FILTER_OPTION_FOR_PRODUCTS_ALL';
export const SET_FILTER_OPTION_FOR_PRODUCTS_ALL_SUCCESS =
  'SET_FILTER_OPTION_FOR_PRODUCTS_ALL_SUCCESS';
export const SET_FILTER_OPTION_FOR_PRODUCTS_ALL_FAILURE =
  'SET_FILTER_OPTION_FOR_PRODUCTS_ALL_FAILURE';

export function getProductsAction(limit, shop) {
  return (dispatch, getState) => {
    dispatch({ type: GET_PRODUCTS });

    const {
      productList: { searchQuery, showLimit }
    } = getState();
    const searchQueryShopType = _getSearchShopType(searchQuery, shop.type);

    if (!limit) limit = showLimit;

    return productAPI.search(limit, 0, null, 0, searchQueryShopType, shop.id)
      .then(res => {
        dispatch({
          type: GET_PRODUCTS_SUCCESS,
          payload: res.data,
          cursor: res.headers['x-next-page-cursor'],
          pageNumber: res.headers['x-next-page'],
          hasNext: res.headers['x-has-next'] === 'true',
          shopType: shop.type
        });
        return Promise.resolve(res.data.length);
      })
      .catch(err => {
        dispatch({
          type: GET_PRODUCTS_FAILURE,
          error: err
        });
        return Promise.reject(err);
      });
  };
}

export function loadMoreProductsAction(showLimit, shopId, offset, cursor, pageNumber) {
  return (dispatch, getState) => {
    dispatch({ type: LOAD_MORE_PRODUCTS });

    const {
      productList: { searchQuery }
    } = getState();
    const searchQueryShopType = _getSearchShopType(searchQuery, shopId);

    return productAPI.search(showLimit, offset, cursor, pageNumber, searchQueryShopType, shopId)
      .then(res => {
        dispatch({
          type: LOAD_MORE_PRODUCTS_SUCCESS,
          payload: res.data,
          cursor: res.headers['x-next-page-cursor'],
          pageNumber: res.headers['x-next-page'],
          hasNext: res.headers['x-has-next'] === 'true'
        });

        return Promise.resolve(res);
      })
      .catch(err => {
        dispatch({
          type: LOAD_MORE_PRODUCTS_FAILURE,
          error: err.response ? err.response.message : err.message
        });

        return Promise.reject();
      });
  };
}

//Remove later
function _getSearchShopType(searchQueryFromState, shopType) {
  return typeof searchQueryFromState === 'string'
    ? searchQueryFromState
    : shopType && searchQueryFromState[shopType]
    ? searchQueryFromState[shopType]
    : '';
}

export function setSearchProductText(query, shopType) {
  return {
    type: SEARCH_PRODUCTS,
    query,
    shopType
  };
}

export function reorderProductAction(oldId, newId, productId) {
  return dispatch => {
    dispatch({
      type: REORDER_PRODUCT,
      oldId,
      newId,
      productId
    });
  };
}

export function deleteProductListAction(storeId) {
  return dispatch => {
    dispatch({
      type: DELETE_PRODUCTS,
      storeId
    });
  };
}

export function addProductAction(data) {
  return {
    type: ADD_PRODUCT,
    payload: data
  };
}

export function clearProductListErrorState() {
  return dispatch => {
    dispatch({ type: CLEAR_PRODUCT_LIST_ERROR_STATE });
  };
}

export function clearProductListReduxState() {
  return { type: CLEAR_PRODUCT_LIST_REDUX_STATE };
}

export function setShowLimitToProducts(val) {
  return {
    type: SET_SHOW_LIMIT_TO_PRODUCTS,
    val
  };
}

// TODO if needs in the future
export function getFilterOptionsForProducts(storeType) {
  return dispatch => {
    return productAPI
      .getFilterOptions(20, 0, null, 0, storeType, null, [])
      .then(res => {
        dispatch({
          type: GET_FILTER_OPTIONS_FOR_PRODUCTS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_FILTER_OPTIONS_FOR_PRODUCTS_FAILURE,
          error
        });
      });
  };
}

export function removeStoreCategories() {
  return {
    type: REMOVE_FILTER_OPTIONS_FOR_PRODUCTS
  };
}

export function filterProducts({ id, selectedInPublications }) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      productAPI
        .setFilter(id, selectedInPublications ? 'unselect' : 'select')
        .then(res => {
          dispatch({
            type: TOGGLE_CHECK_FILTER_OPTION_FOR_PRODUCTS,
            id,
            selectedInPublications
          });

          resolve(res);
        })
        .catch(error => reject(error));
    });
  };
}

export function setFilterAll(select, shopType, section = 'publications') {
  //select - select/unselect
  return dispatch => {
    dispatch({ type: SET_FILTER_OPTION_FOR_PRODUCTS_ALL });

    productAPI
      .setFilterAll(select, shopType, section)
      .then(res => {
        dispatch({
          type: SET_FILTER_OPTION_FOR_PRODUCTS_ALL_SUCCESS,
          select: select === 'select'
        });
        
        dispatch(getProductsAction(null, shopType));
      })
      .catch(err => {
        dispatch({ type: SET_FILTER_OPTION_FOR_PRODUCTS_ALL_FAILURE, error: err.message });
      });
  };
}

export function getBrands() {
  return dispatch => {
    productAPI
      .getBrands()
      .then(res => {
        dispatch({
          type: SET_BRANDS_LIST,
          payload: res.data
        });
      })
      .catch(error => {});
  };
}
