export function removePostFromCalendar(state, action) {
  const payload = state.payload.map(day => {
    if (day.day === action.listId) {
      return {
        ...day,
        postsList: day.postsList.filter(post => post.id !== action.postId),
      };
    } else {
      return day;
    }
  });

  return Object.assign({}, state, {
    loading: true,
    error: null,
    payload: payload,
  });
}
